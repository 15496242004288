import React, { Component, ErrorInfo } from "react";
import { ErrorFallback } from "./ErrorFallback";
import { initializeStyles } from "./styles";
import { DetailedAuthError } from "../../types/auth";

// Initialize bounce animation styles
initializeStyles();

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorFallback
          error={this.state.error || null}
          resetErrorBoundary={() => this.setState({ hasError: false })}
        />
      );
    }

    return this.props.children;
  }
}

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof DetailedAuthError) {
    switch (error.type) {
      case 'SESSION':
        return 'Your session has expired. Please login again.';
      case 'AUTH':
        return 'There was a problem with authentication. Please try again.';
      case 'PROFILE':
        return 'There was a problem loading your profile. Please refresh the page.';
      default:
        return error.message;
    }
  }
  
  if (error instanceof Error) {
    return error.message;
  }
  
  return 'An unexpected error occurred.';
};
