export const MATTER_QUERY_OPTIONS = {
  retry: 1,
  retryDelay: 1000,
};

export const MATTER_SELECT_QUERY = `
  *,
  client_org:client_org_id(
    id,
    name,
    logo_url
  ),
  lead_attorney:lead_attorney_id(
    id,
    full_name,
    avatar_url,
    initials,
    role
  ),
  activities!matter_id(
    *,
    assigned_to:assigned_to_id(
      id,
      full_name,
      avatar_url,
      initials,
      role
    )
  ),
  team_members:matter_team_members!matter_id(
    *,
    profile:profile_id(
      id,
      full_name,
      avatar_url,
      initials,
      role
    )
  )
`; 