import { AuthBindings, AuthProvider } from "@refinedev/core";
import { supabaseClient } from "./utility";
import { Profiles } from "./types/schema";
import { AuthError, DetailedAuthError } from "./types/auth";

interface AuthUser {
  id: string;
  email?: string;
  profile: Profiles;
  profileId: string;
  organizationId: string;
  full_name: string;
  avatar_url: string;
  role: string;
}

const createAuthUser = (authData: any, profileData: any): AuthUser => ({
  id: authData.user.id,
  email: authData.user.email,
  profile: profileData,
  profileId: profileData.id,
  organizationId: profileData.organization_id,
  // Map convenience accessors
  full_name: profileData.full_name,
  avatar_url: profileData.avatar_url,
  role: profileData.role,
});

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    try {
      const { data: authData, error: authError } = await supabaseClient.auth
        .signInWithPassword({
          email,
          password,
        });

      if (authError) {
        throw new Error(authError.message);
      }

      if (!authData.user?.id) {
        throw new Error("User ID not found");
      }

      // Fetch the profile data immediately after successful authentication
      const { data: profileData, error: profileError } = await supabaseClient
        .from("profiles")
        .select("*")
        .eq("auth_user_id", authData.user.id)
        .single();

      if (profileError) {
        throw new Error("Failed to fetch user profile");
      }

      if (!profileData?.id) {
        throw new Error("Profile not found");
      }

      // Create and store auth user data
      const userData = createAuthUser(authData, profileData);
      localStorage.setItem("user", JSON.stringify(userData));

      return {
        success: true,
        redirectTo: "/",
      };
    } catch (error) {
      return {
        success: false,
        error: {
          message: error instanceof Error ? error.message : "Login failed",
          name: "Login Error",
        },
      };
    }
  },

  logout: async () => {
    try {
      const { error } = await supabaseClient.auth.signOut();
      if (error) throw error;

      localStorage.removeItem("user");

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error) {
      return {
        success: false,
        error: new Error(error instanceof Error ? error.message : "Logout failed"),
      };
    }
  },

  getIdentity: async () => {
    try {
      const userDataStr = localStorage.getItem("user");
      if (!userDataStr) {
        return null;
      }

      const userData = JSON.parse(userDataStr) as AuthUser;
      
      if (!userData?.profile?.id || !userData?.profileId) {
        const { data: { user } } = await supabaseClient.auth.getUser();
        
        if (!user?.id) {
          return null;
        }

        // Fetch fresh profile data
        const { data: profileData, error: profileError } = await supabaseClient
          .from("profiles")
          .select("*")
          .eq("auth_user_id", user.id)
          .single();

        if (profileError || !profileData) {
          throw new Error("Failed to fetch user profile");
        }

        // Create and store fresh auth user data
        const updatedUser = createAuthUser({ user }, profileData);
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return updatedUser;
      }

      return userData;
    } catch (error) {
      console.error("GetIdentity Error:", error);
      return null;
    }
  },

  check: async () => {
    try {
      const { data: { session }, error } = await supabaseClient.auth.getSession();
      
      // Set up session persistence listener if not already set
      supabaseClient.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_OUT') {
          localStorage.removeItem('user');
        } else if (event === 'SIGNED_IN' && session) {
          // Refresh profile data on session change
          supabaseClient
            .from('profiles')
            .select('*')
            .eq('auth_user_id', session.user.id)
            .single()
            .then(({ data: profileData }) => {
              if (profileData) {
                const userData = createAuthUser({ user: session.user }, profileData);
                localStorage.setItem('user', JSON.stringify(userData));
              }
            });
        }
      });
      
      if (error || !session) {
        return {
          authenticated: false,
          redirectTo: "/login",
          error: error || new Error("No session found"),
        };
      }

      const userDataStr = localStorage.getItem("user");
      const userData = userDataStr ? JSON.parse(userDataStr) as AuthUser : null;

      if (!userData?.profile?.id) {
        return {
          authenticated: false,
          redirectTo: "/login",
          error: new Error("Profile not found"),
        };
      }

      return {
        authenticated: true,
      };
    } catch (error) {
      return {
        authenticated: false,
        redirectTo: "/login",
        error: new Error(error instanceof Error ? error.message : "Check failed"),
      };
    }
  },

  onError: async (error) => {
    console.error('Auth Provider Error:', {
      error,
      stack: error instanceof Error ? error.stack : undefined,
    });

    let detailedError: AuthError;

    if (error instanceof DetailedAuthError) {
      detailedError = error;
    } else {
      const message = error instanceof Error ? error.message : String(error);
      
      // Enhanced Supabase error categorization
      if (message.includes('Email not confirmed')) {
        detailedError = new DetailedAuthError(
          'Please verify your email address before logging in.',
          'AUTH',
          'email-verification',
          error
        );
      } else if (message.includes('Invalid login credentials')) {
        detailedError = new DetailedAuthError(
          'Invalid email or password.',
          'AUTH',
          'invalid-credentials',
          error
        );
      } else if (message.includes('Rate limit')) {
        detailedError = new DetailedAuthError(
          'Too many attempts. Please try again later.',
          'AUTH',
          'rate-limit',
          error
        );
      } else if (message.includes('JWT') || message.includes('token')) {
        detailedError = new DetailedAuthError(
          'Your session has expired. Please login again.',
          'SESSION',
          'token-validation',
          error
        );
      } else if (message.includes('session')) {
        detailedError = new DetailedAuthError(
          'Session error occurred. Please login again.',
          'SESSION',
          'session-validation',
          error
        );
      } else if (message.includes('profile')) {
        detailedError = new DetailedAuthError(
          'Profile error occurred. Please try again.',
          'PROFILE',
          'profile-validation',
          error
        );
      } else if (message.includes('auth') || message.includes('password') || message.includes('email')) {
        detailedError = new DetailedAuthError(
          'Authentication error occurred. Please check your credentials.',
          'AUTH',
          'authentication',
          error
        );
      } else {
        detailedError = new DetailedAuthError(
          'An unexpected error occurred.',
          'UNKNOWN',
          'general',
          error
        );
      }
    }

    // Log the detailed error for debugging
    console.error('Detailed Auth Error:', {
      message: detailedError.message,
      type: detailedError.type,
      source: detailedError.source,
      timestamp: detailedError.timestamp,
      originalError: detailedError.originalError,
    });

    // Handle session-related errors by logging out
    if (detailedError.type === 'SESSION') {
      localStorage.removeItem('user');
      return {
        error: detailedError,
        logout: true,
        redirectTo: '/login',
      };
    }

    return {
      error: detailedError,
    };
  },

  forgotPassword: async ({ email }) => {
    try {
      const { error } = await supabaseClient.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth/confirm`,
      });

      if (error) {
        // Don't expose whether the email exists or not
        console.error("Reset password error:", error);
        return {
          success: true, // Always return success for security
          successNotification: {
            message: "Check Your Email",
            description: "If an account exists with this email, you will receive a password reset link shortly.",
          },
        };
      }

      return {
        success: true,
        successNotification: {
          message: "Check Your Email",
          description: "If an account exists with this email, you will receive a password reset link shortly.",
        },
      };
    } catch (error) {
      // Log the error but don't expose it
      console.error("Reset password error:", error);
      return {
        success: true, // Always return success for security
        successNotification: {
          message: "Check Your Email",
          description: "If an account exists with this email, you will receive a password reset link shortly.",
        },
      };
    }
  },

  updatePassword: async ({ password }) => {
    try {
      // First verify we have a valid session
      const { data: { session }, error: sessionError } = await supabaseClient.auth.getSession();
      
      if (sessionError || !session) {
        throw new Error("Your session has expired. Please request a new password reset link.");
      }

      // Update the password
      const { error } = await supabaseClient.auth.updateUser({
        password,
      });

      if (error) {
        throw error;
      }

      // Sign out after password update to ensure clean state
      await supabaseClient.auth.signOut();

      return {
        success: true,
        redirectTo: "/login",
        successNotification: {
          message: "Password Reset Successful",
          description: "Your password has been updated successfully. Please login with your new password.",
        },
      };
    } catch (error) {
      console.error("Password update error:", error);
      
      // Handle specific error cases
      let message = "Failed to update password";
      if (error instanceof Error) {
        if (error.message.includes("stronger password")) {
          message = "Please use a stronger password. It should include uppercase, lowercase, numbers, and be at least 8 characters long.";
        } else if (error.message.includes("session") || error.message.includes("expired")) {
          message = "Your session has expired. Please request a new password reset link.";
        } else if (error.message.includes("network")) {
          message = "Network error occurred. Please check your connection and try again.";
        } else {
          message = error.message;
        }
      }

      return {
        success: false,
        error: new Error(message),
      };
    }
  },
};
