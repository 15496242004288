/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* tslint:disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useNotification,
  useRegister,
} from "@refinedev/core";
import { useStepsForm } from "@refinedev/react-hook-form";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { supabaseClient } from "../../utility/supabaseClient";
import {
  Business as BusinessIcon,
  PersonAdd as PersonAddIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  Person as PersonIcon,
  BusinessCenter as BusinessCenterIcon,
  Extension as ExtensionIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import {
  OrgType,
  UserRole,
  ArgsCreateOrganizationWithAdmin,
  ReturnTypeCreateOrganizationWithAdmin,
} from "../../types/schema";
import { WelcomeScreen } from './WelcomeScreen';

const orgTypes: { value: OrgType; label: string }[] = [
  { value: OrgType.legal_department, label: "Legal Department" },
  { value: OrgType.law_firm, label: "Law Firm" },
  { value: OrgType.external_law_firm, label: "External Law Firm" },
  { value: OrgType.client, label: "Client" },
];

const checkExistingProfile = async (email: string): Promise<boolean> => {
  try {
    const { data, error } = await supabaseClient
      .from("profiles")
      .select("id")
      .eq("email", email)
      .is("auth_user_id", null)
      .single();

    if (error) throw error;
    return !!data;
  } catch (error) {
    console.error("Profile check error:", error);
    return false;
  }
};

interface RegistrationData {
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  phone: string | null;
  orgName: string | null;
  orgType: OrgType | null;
  orgEmail: string | null;
  orgPhone: string | null;
  orgWebsite: string | null;
  orgAddress: string | null;
}

const STEPS = {
  TYPE_SELECT: 0,
  EMAIL_VERIFY: 1,
  USER_DETAILS: 2,
  ORG_DETAILS: 3,
  EXTENSION: 4,
} as const;

const getStepsForType = (type: "admin" | "member" | null) => {
  switch (type) {
    case "admin":
      return [
        { label: "Start", step: STEPS.TYPE_SELECT },
        { label: "User", step: STEPS.USER_DETAILS },
        { label: "Organization", step: STEPS.ORG_DETAILS },
        { label: "Extension", step: STEPS.EXTENSION },
      ];
    case "member":
      return [
        { label: "Start", step: STEPS.TYPE_SELECT },
        { label: "Email", step: STEPS.EMAIL_VERIFY },
        { label: "Details", step: STEPS.USER_DETAILS },
        { label: "Extension", step: STEPS.EXTENSION },
      ];
    default:
      return [{ label: "Start", step: STEPS.TYPE_SELECT }];
  }
};

interface PersistedData extends Partial<RegistrationData> {
  registrationType: "admin" | "member" | null;
}

const useFormPersistence = (key: string, initialData: PersistedData) => {
  const [savedData, setSavedData] = React.useState<typeof initialData>(() => {
    try {
      const saved = sessionStorage.getItem(key);
      return saved ? JSON.parse(saved) : initialData;
    } catch {
      return initialData;
    }
  });

  const updateData = React.useCallback((data: Partial<typeof initialData>) => {
    setSavedData((prev) => ({ ...prev, ...data }));
  }, []);

  const clearData = React.useCallback(() => {
    try {
      sessionStorage.removeItem(key);
      setSavedData(initialData);
    } catch (error) {
      console.error("Error clearing form data:", error);
    }
  }, [key, initialData]);

  React.useEffect(() => {
    try {
      sessionStorage.setItem(key, JSON.stringify(savedData));
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  }, [savedData, key]);

  return { savedData, updateData, clearData };
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #2196f3 0%, #1976d2 50%, #1565c0 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #2196f3 0%, #1976d2 50%, #1565c0 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #2196f3 0%, #1976d2 50%, #1565c0 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #2196f3 0%, #1976d2 50%, #1565c0 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active = false, completed = false, icon } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <CheckCircleIcon />,
    2: <PersonIcon />,
    3: <BusinessCenterIcon />,
    4: <ExtensionIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

interface RegistrationState {
  isCreatingOrg: boolean;
  showSuccess: boolean;
  registrationType: "admin" | "member" | null;
  verificationAttempts: number;
  showWelcome: boolean;
  userData: {
    fullName: string;
  } | null;
}

const MESSAGES = {
  SUCCESS: {
    ADMIN: "Your organization has been created",
    MEMBER: "Welcome to the platform",
    ACCOUNT_CREATED: "Account created successfully",
  },
  ERROR: {
    NO_INVITATION: "No Invitation Found",
    VERIFICATION_FAILED: "Verification Failed",
  },
} as const;

const DESCRIPTIONS = {
  NO_INVITATION:
    "We couldn't find an invitation for this email. Please ask your organization administrator to send you an invitation.",
  VERIFICATION_FAILED:
    "There was an error verifying your email. Please try again.",
  SIGN_IN: "Please sign in with your credentials",
} as const;

// First, define the expected return type interface

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const { open } = useNotification();
  const { isLoading: isRegistering } = useRegister();
  const [state, setState] = React.useState<RegistrationState>({
    isCreatingOrg: false,
    showSuccess: false,
    registrationType: null,
    verificationAttempts: 0,
    showWelcome: false,
    userData: null,
  });

  const { savedData, updateData, clearData } = useFormPersistence(
    "registration-form",
    {
      email: "",
      password: "",
      registrationType: null,
    }
  );

  const [registrationType, setRegistrationType] = React.useState<
    "admin" | "member" | null
  >(savedData.registrationType);

  const {
    register: registerField,
    handleSubmit,
    watch,
    formState: { errors },
    steps: { currentStep, gotoStep },
  } = useStepsForm<RegistrationData>({
    defaultValues: {
      email: savedData.email || "",
      password: "",
      confirmPassword: "",
      fullName: "",
      phone: null,
      orgName: null,
      orgType: OrgType.legal_department,
      orgEmail: null,
      orgPhone: null,
      orgWebsite: null,
      orgAddress: null,
    },
    stepsProps: {
      defaultStep: STEPS.TYPE_SELECT,
    },
  });

  React.useEffect(() => {
    const subscription = watch((value: Partial<RegistrationData>) => {
      const { email, password, fullName, phone } = value;
      updateData({ email, password, fullName, phone });
    });
    return () => subscription.unsubscribe();
  }, [watch, updateData]);

  React.useEffect(() => {
    updateData({ registrationType });
  }, [registrationType, updateData]);

  React.useEffect(() => {
    if (state.showSuccess) {
      clearData();
    }
  }, [state.showSuccess, clearData]);

  const password = watch("password");
  const isLoading = isRegistering || state.isCreatingOrg;

  const handleRegistration = React.useCallback(
    async (data: RegistrationData) => {
      console.log(
        "handleRegistration started with type:",
        state.registrationType
      );

      try {
        setState((prev) => ({ ...prev, isCreatingOrg: true }));

        // Store user data for welcome screen
        setState((prev) => ({
          ...prev,
          userData: {
            fullName: data.fullName,
          },
        }));

        if (state.registrationType === "member") {
          const { data: authData, error: authError } =
            await supabaseClient.auth.signUp({
              email: data.email,
              password: data.password,
              options: {
                data: {
                  role: UserRole.member,
                  full_name: data.fullName,
                  phone: data.phone || "",
                },
                emailRedirectTo: `${window.location.origin}/dashboard`,
              },
            });

          if (authError) {
            throw new Error(`Authentication failed: ${authError.message}`);
          }

          if (!authData.user) {
            throw new Error("User creation failed: No user data returned");
          }

          const { error: profileError } = await supabaseClient
            .from("profiles")
            .update({ auth_user_id: authData.user.id })
            .eq("email", data.email)
            .is("auth_user_id", null);

          if (profileError) {
            throw new Error(`Profile update failed: ${profileError.message}`);
          }

          gotoStep(STEPS.EXTENSION);
          setState((prev) => ({
            ...prev,
            isCreatingOrg: false,
            showSuccess: false,
          }));
          return;
        }

        if (state.registrationType === "admin") {
          const { data: authData, error: authError } =
            await supabaseClient.auth.signUp({
              email: data.email,
              password: data.password,
              options: {
                data: {
                  role: UserRole.admin,
                  full_name: data.fullName,
                  phone: data.phone || "",
                },
                emailRedirectTo: `${window.location.origin}/dashboard`,
              },
            });

          if (authError) {
            throw new Error(`Authentication failed: ${authError.message}`);
          }

          if (!authData.user) {
            throw new Error("User creation failed: No user data returned");
          }

          const rpcParams: ArgsCreateOrganizationWithAdmin = {
            p_org_name: data.orgName!,
            p_org_type: data.orgType!,
            p_email: data.email,
            p_full_name: data.fullName,
            p_auth_user_id: authData.user.id,
            p_org_email: data.orgEmail,
            p_org_phone: data.orgPhone,
            p_org_website: data.orgWebsite,
            p_org_address: data.orgAddress,
            p_phone: data.phone,
          };

          const { error: rpcError } =
            await supabaseClient.rpc<ReturnTypeCreateOrganizationWithAdmin>(
              "create_organization_with_admin",
              rpcParams
            );

          if (rpcError) {
            throw new Error(
              `Organization creation failed: ${rpcError.message}`
            );
          }

          gotoStep(STEPS.EXTENSION);
          setState((prev) => ({
            ...prev,
            isCreatingOrg: false,
            showSuccess: false,
          }));
        }
      } catch (error) {
        setState((prev) => ({ ...prev, isCreatingOrg: false }));
        open?.({
          message: "Registration failed",
          description:
            error instanceof Error
              ? error.message
              : "An unknown error occurred",
          type: "error",
        });
        console.error("Registration failed:", error);
        throw error;
      }
    },
    [state.registrationType, gotoStep, open]
  );

  const handleContinueToDashboard = React.useCallback(async () => {
    try {
      if (!savedData.email || !savedData.password) {
        throw new Error("Session expired. Please login with your credentials.");
      }

      setState((prev) => ({ ...prev, showSuccess: true }));

      // First ensure we're logged in with Supabase
      const { error: authError } = await supabaseClient.auth.signInWithPassword({
        email: savedData.email,
        password: savedData.password,
      });

      if (authError) {
        throw new Error(`Authentication failed: ${authError.message}`);
      }

      // Show welcome screen instead of immediate redirect
      setState((prev) => ({ ...prev, showWelcome: true }));
      
      clearData();
    } catch (error) {
      console.error("Login error:", error);
      clearData();

      // If there's an error, redirect to login with a success message
      navigate("/login");
      open?.({
        message: MESSAGES.SUCCESS.ACCOUNT_CREATED,
        description: "Please sign in with your credentials to continue",
        type: "success",
      });
    }
  }, [savedData, navigate, open, clearData]);

  const renderTypeSelection = () => (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h4"
        gutterBottom
        color="primary"
        sx={{ fontWeight: 600 }}
      >
        Welcome to LegalEase
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Choose how you'd like to get started
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            setRegistrationType("admin");
            setState((prev) => ({ ...prev, registrationType: "admin" }));
            gotoStep(STEPS.USER_DETAILS);
          }}
          startIcon={<BusinessIcon />}
          sx={{
            py: 2,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1.1rem",
          }}
        >
          Create New Organization
        </Button>

        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            setRegistrationType("member");
            setState((prev) => ({ ...prev, registrationType: "member" }));
            gotoStep(STEPS.EMAIL_VERIFY);
          }}
          startIcon={<PersonAddIcon />}
          sx={{
            py: 2,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1.1rem",
          }}
        >
          Join Existing Organization
        </Button>
      </Box>
    </Box>
  );

  const renderUserDetails = () => (
    <Box
      component="form"
      onSubmit={handleSubmit(async (data) => {
        if (registrationType === "member") {
          const hasInvitation = await checkExistingProfile(data.email);
          if (!hasInvitation) {
            open?.({
              message: MESSAGES.ERROR.NO_INVITATION,
              description: DESCRIPTIONS.NO_INVITATION,
              type: "error",
            });
            return;
          }
        }
        gotoStep(
          registrationType === "admin" ? STEPS.ORG_DETAILS : STEPS.EXTENSION
        );
      })}
    >
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => gotoStep(STEPS.TYPE_SELECT)}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h5">Personal Details</Typography>
      </Box>

      <TextField
        {...registerField("email", {
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        })}
        label="Email"
        fullWidth
        error={!!errors.email}
        helperText={errors.email?.message as string}
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("password", {
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must be at least 8 characters",
          },
          validate: (value) => {
            // At least 8 characters
            if (value.length < 8) {
              return "Password must be at least 8 characters";
            }

            // Must contain at least 3 of the following:
            // - uppercase letters
            // - lowercase letters
            // - numbers
            // - special characters
            let criteriaCount = 0;
            if (/[A-Z]/.test(value)) criteriaCount++;
            if (/[a-z]/.test(value)) criteriaCount++;
            if (/[0-9]/.test(value)) criteriaCount++;
            if (/[^A-Za-z0-9]/.test(value)) criteriaCount++;

            return (
              criteriaCount >= 3 ||
              "Password must contain at least 3 of the following: uppercase letters, lowercase letters, numbers, or special characters"
            );
          },
        })}
        type="password"
        label="Password"
        fullWidth
        error={!!errors.password}
        helperText={errors.password?.message as string}
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("confirmPassword", {
          required: "Please confirm your password",
          validate: (value) => value === password || "Passwords do not match",
        })}
        type="password"
        label="Confirm Password"
        fullWidth
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword?.message as string}
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("fullName", {
          required: "Full name is required",
        })}
        label="Full Name"
        fullWidth
        error={!!errors.fullName}
        helperText={errors.fullName?.message as string}
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("phone")}
        label="Phone Number (Optional)"
        fullWidth
        sx={{ mb: 3 }}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading}
        sx={{ height: 48 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Continue"}
      </Button>
    </Box>
  );

  const renderOrgDetails = () => (
    <Box
      component="form"
      onSubmit={handleSubmit(async (formData) => {
        try {
          await handleRegistration(formData);
        } catch (error) {
          console.error("Form submission error:", error);
        }
      })}
    >
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => gotoStep(STEPS.USER_DETAILS)}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h5">Organization Details</Typography>
      </Box>

      <TextField
        {...registerField("orgName", {
          required: "Organization name is required",
        })}
        label="Organization Name"
        fullWidth
        error={!!errors.orgName}
        helperText={errors.orgName?.message as string}
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("orgType")}
        select
        label="Organization Type"
        fullWidth
        defaultValue="legal_department"
        sx={{ mb: 2 }}
      >
        {orgTypes.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        {...registerField("orgEmail")}
        label="Organization Email (Optional)"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("orgPhone")}
        label="Organization Phone (Optional)"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("orgWebsite")}
        label="Website (Optional)"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        {...registerField("orgAddress")}
        label="Address (Optional)"
        fullWidth
        multiline
        rows={3}
        sx={{ mb: 3 }}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading}
        sx={{ height: 48 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Create Organization"}
      </Button>
    </Box>
  );

  const renderExtensionStep = () => (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h5" gutterBottom color="primary" sx={{ fontWeight: 600 }}>
        {state.showSuccess ? "Registration Successful!" : "One Last Step!"}
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        {state.registrationType === "admin"
          ? "Your organization has been created successfully. Install our browser extension to get started."
          : "Your account has been created. Install our browser extension to connect your email."}
      </Typography>

      <Box sx={{ mb: 4, textAlign: 'left', px: 2 }}>
        <Typography variant="h6" gutterBottom>Install Chrome Extension</Typography>
        
        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 600 }}>
          Step 1: Download the Extension
        </Typography>
        <Button
          variant="contained"
          size="large"
          href="/dist_chrome.crx"
          download="legalease_extension.crx"
          sx={{
            py: 1.5,
            px: 3,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1rem",
            mb: 3,
          }}
        >
          Download Extension
        </Button>

        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
          Step 2: Install in Chrome
        </Typography>
        <Box component="ol" sx={{ pl: 2, '& li': { mb: 1 } }}>
          <li>Open Chrome and go to <Box component="code" sx={{ bgcolor: 'grey.100', px: 1, py: 0.5, borderRadius: 1 }}>chrome://extensions</Box></li>
          <li>Enable "Developer mode" using the toggle in the top-right corner</li>
          <li>Click "Load unpacked" in the top-left</li>
          <li>Select the downloaded extension file</li>
        </Box>

        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1, fontWeight: 600 }}>
          Step 3: Pin the Extension
        </Typography>
        <Box component="ol" sx={{ pl: 2, '& li': { mb: 1 } }}>
          <li>Click the puzzle piece icon in Chrome's toolbar</li>
          <li>Find LegalEase in the dropdown</li>
          <li>Click the pin icon to keep it visible</li>
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 3, bgcolor: 'info.50', p: 2, borderRadius: 1 }}>
          Note: Chrome will display a Developer Mode reminder when you restart your browser. 
          This is normal for extensions installed outside the Chrome Web Store.
        </Typography>
      </Box>

      <Button
        variant="outlined"
        onClick={handleContinueToDashboard}
        fullWidth
        disabled={isLoading}
        sx={{
          height: 48,
          borderRadius: 2,
          textTransform: "none",
          fontSize: "1.1rem",
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Continue to Dashboard"}
      </Button>
    </Box>
  );

  const renderSuccess = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 4,
      }}
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CircularProgress size={60} />
      </motion.div>
      <Typography variant="h6" sx={{ mt: 3 }}>
        Setting up your account...
      </Typography>
    </Box>
  );

  const renderEmailVerification = () => (
    <Box
      component="form"
      onSubmit={handleSubmit(async (data) => {
        const hasInvitation = await checkExistingProfile(data.email);

        if (!hasInvitation) {
          open?.({
            message: MESSAGES.ERROR.NO_INVITATION,
            description: DESCRIPTIONS.NO_INVITATION,
            type: "error",
          });
          return;
        }

        gotoStep(STEPS.USER_DETAILS);
      })}
    >
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => gotoStep(STEPS.TYPE_SELECT)}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h5">Verify Your Email</Typography>
      </Box>

      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Please enter your email address to verify your invitation.
      </Typography>

      <TextField
        {...registerField("email", {
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        })}
        label="Email Address"
        fullWidth
        error={!!errors.email}
        helperText={errors.email?.message as string}
        sx={{ mb: 3 }}
      />

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading}
        sx={{ height: 48 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Verify Email"}
      </Button>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 2, textAlign: "center" }}
      >
        Need an invitation? Ask your organization administrator to invite you.
      </Typography>
    </Box>
  );

  if (state.showWelcome) {
    return <WelcomeScreen fullName={state.userData?.fullName} />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        bgcolor: "background.default",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 480,
          width: "100%",
          borderRadius: 2,
        }}
      >
        {!state.showSuccess && currentStep !== STEPS.TYPE_SELECT && (
          <Stepper
            activeStep={currentStep - 1}
            sx={{ mb: 4 }}
            alternativeLabel
            connector={<ColorlibConnector />}
          >
            {getStepsForType(registrationType)
              .slice(1)
              .map(({ label }) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <ColorlibStepIcon
                        active={props.active || false}
                        completed={props.completed || false}
                        icon={props.icon as number}
                      />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
          </Stepper>
        )}

        <AnimatePresence mode="wait">
          <motion.div
            key={state.showSuccess ? "success" : currentStep}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            {state.showSuccess ? (
              renderSuccess()
            ) : (
              <>
                {currentStep === STEPS.TYPE_SELECT && renderTypeSelection()}
                {currentStep === STEPS.EMAIL_VERIFY &&
                  registrationType === "member" &&
                  renderEmailVerification()}
                {currentStep === STEPS.USER_DETAILS && renderUserDetails()}
                {currentStep === STEPS.ORG_DETAILS &&
                  registrationType === "admin" &&
                  renderOrgDetails()}
                {currentStep === STEPS.EXTENSION && renderExtensionStep()}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Paper>
    </Box>
  );
};

export default Registration;
