import { createTheme, Theme } from "@mui/material/styles";
import "@fontsource/barlow/300.css";
import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/600.css";
import "@fontsource/barlow/700.css";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2B4C7E", // Modern navy blue
      light: "#3A65A4",
      dark: "#1D3557",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#586F7C", // Sophisticated slate
      light: "#7B919C",
      dark: "#445760",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#2D6A4F", // Refined emerald
      light: "#40916C",
      dark: "#1B4332",
    },
    error: {
      main: "#9B2C2C", // Professional red
      light: "#BC4B4B",
      dark: "#771D1D",
    },
    warning: {
      main: "#975A16", // Warm amber
      light: "#B7791F",
      dark: "#744210",
    },
    info: {
      main: "#2A4365", // Deep blue
      light: "#3C5490",
      dark: "#1A365D",
    },
    grey: {
      50: "#F8FAFC",
      100: "#F1F5F9",
      200: "#E2E8F0",
      300: "#CBD5E1",
      400: "#94A3B8",
      500: "#64748B",
      600: "#475569",
      700: "#334155",
      800: "#1E293B",
      900: "#0F172A",
    },
    background: {
      default: "#F8FAFC",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#1E293B",
      secondary: "#475569",
    },
    divider: "rgba(203, 213, 225, 0.3)",
  },
  typography: {
    fontFamily: '"Barlow", sans-serif',
    h1: {
      fontSize: "2.5rem",     // 40px
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: "-0.02em",
    },
    h2: {
      fontSize: "2rem",      // 32px
      fontWeight: 600,
      lineHeight: 1.2,
      letterSpacing: "-0.01em",
    },
    h3: {
      fontSize: "1.75rem",   // 28px
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: "-0.01em",
    },
    h4: {
      fontSize: "1.5rem",    // 24px
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h5: {
      fontSize: "1.25rem",   // 20px
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: "1.125rem",  // 18px
      fontWeight: 600,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: "1.125rem",  // 18px
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.01em",
    },
    subtitle2: {
      fontSize: "1rem",      // 16px
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.01em",
    },
    body1: {
      fontSize: "1rem",      // 16px
      fontWeight: 400,
      lineHeight: 1.6,
    },
    body2: {
      fontSize: "0.875rem",  // 14px
      fontWeight: 400,
      lineHeight: 1.6,
    },
    button: {
      fontSize: "0.875rem",  // 14px
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "0.02em",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",   // 12px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.02em",
    },
    overline: {
      fontSize: "0.75rem",   // 12px
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.05em",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: 12,
          border: "1px solid rgba(203, 213, 225, 0.3)",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            transform: "translateY(-1px)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "0.75rem",
          height: 28,
          borderRadius: 8,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-1px)",
          },
        },
        filled: {
          "&.MuiChip-colorSuccess": {
            backgroundColor: "rgba(45, 106, 79, 0.1)",
            color: "#2D6A4F",
          },
          "&.MuiChip-colorError": {
            backgroundColor: "rgba(155, 44, 44, 0.1)",
            color: "#9B2C2C",
          },
          "&.MuiChip-colorWarning": {
            backgroundColor: "rgba(151, 90, 22, 0.1)",
            color: "#975A16",
          },
          "&.MuiChip-colorInfo": {
            backgroundColor: "rgba(42, 67, 101, 0.1)",
            color: "#2A4365",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
          padding: "8px 16px",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-1px)",
          },
        },
        contained: {
          boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          "&:hover": {
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
          },
        },
        outlined: {
          borderColor: "rgba(203, 213, 225, 0.5)",
          "&:hover": {
            backgroundColor: "rgba(203, 213, 225, 0.05)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "rgba(203, 213, 225, 0.3)",
          padding: "16px",
        },
        head: {
          fontWeight: 600,
          backgroundColor: "#F8FAFC",
          color: "#1E293B",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          borderRadius: 12,
        },
        elevation1: {
          boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        },
        elevation2: {
          boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        },
      },
    },
  },
  shape: {
    borderRadius: 12,
  },
  shadows: [
    "none",
    "0px 1px 2px rgba(16, 24, 40, 0.05)",
    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    "0px 6px 12px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)",
    "0px 8px 16px -6px rgba(16, 24, 40, 0.1), 0px 6px 8px -4px rgba(16, 24, 40, 0.05)",
    "0px 12px 24px -8px rgba(16, 24, 40, 0.15), 0px 8px 12px -6px rgba(16, 24, 40, 0.1)",
    "0px 16px 32px -12px rgba(16, 24, 40, 0.15), 0px 12px 16px -8px rgba(16, 24, 40, 0.1)",
    "0px 20px 40px -16px rgba(16, 24, 40, 0.15), 0px 16px 20px -12px rgba(16, 24, 40, 0.1)",
    "0px 24px 48px -20px rgba(16, 24, 40, 0.2), 0px 20px 24px -16px rgba(16, 24, 40, 0.15)",
    "0px 28px 56px -24px rgba(16, 24, 40, 0.2), 0px 24px 28px -20px rgba(16, 24, 40, 0.15)",
    "0px 32px 64px -28px rgba(16, 24, 40, 0.2), 0px 28px 32px -24px rgba(16, 24, 40, 0.15)",
    "0px 36px 72px -32px rgba(16, 24, 40, 0.2), 0px 32px 36px -28px rgba(16, 24, 40, 0.15)",
    "0px 40px 80px -36px rgba(16, 24, 40, 0.2), 0px 36px 40px -32px rgba(16, 24, 40, 0.15)",
    "0px 44px 88px -40px rgba(16, 24, 40, 0.2), 0px 40px 44px -36px rgba(16, 24, 40, 0.15)",
    "0px 48px 96px -44px rgba(16, 24, 40, 0.2), 0px 44px 48px -40px rgba(16, 24, 40, 0.15)",
    "0px 52px 104px -48px rgba(16, 24, 40, 0.2), 0px 48px 52px -44px rgba(16, 24, 40, 0.15)",
    "0px 56px 112px -52px rgba(16, 24, 40, 0.2), 0px 52px 56px -48px rgba(16, 24, 40, 0.15)",
    "0px 60px 120px -56px rgba(16, 24, 40, 0.2), 0px 56px 60px -52px rgba(16, 24, 40, 0.15)",
    "0px 64px 128px -60px rgba(16, 24, 40, 0.2), 0px 60px 64px -56px rgba(16, 24, 40, 0.15)",
    "0px 68px 136px -64px rgba(16, 24, 40, 0.2), 0px 64px 68px -60px rgba(16, 24, 40, 0.15)",
    "0px 72px 144px -68px rgba(16, 24, 40, 0.2), 0px 68px 72px -64px rgba(16, 24, 40, 0.15)",
    "0px 76px 152px -72px rgba(16, 24, 40, 0.2), 0px 72px 76px -68px rgba(16, 24, 40, 0.15)",
    "0px 80px 160px -76px rgba(16, 24, 40, 0.2), 0px 76px 80px -72px rgba(16, 24, 40, 0.15)",
    "0px 84px 168px -80px rgba(16, 24, 40, 0.2), 0px 80px 84px -76px rgba(16, 24, 40, 0.15)",
  ] as Theme["shadows"],
});
