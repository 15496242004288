import { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

interface ConfirmDialogProps {
  title: string;
  content: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

interface UseConfirmDialogProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalProps?: Record<string, any>;
  title?: string;
  content?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const useConfirmDialog = ({
  originalProps = {},
  title: initialTitle = "",
  content: initialContent = null,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
}: UseConfirmDialogProps = {}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState<ConfirmDialogProps>({
    title: initialTitle,
    content: initialContent,
  });

  const show = useCallback(
    ({ title, content, onClick }: ConfirmDialogProps) => {
      setDialogProps({ title, content, onClick });
      setOpen(true);
    },
    []
  );

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOriginalAction = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (originalProps.onClick) {
      await originalProps.onClick(e);
    }
  };

  const saveButtonPropsWithConfirm = {
    ...originalProps,
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      show({
        title: initialTitle,
        content: initialContent,
        onClick: handleOriginalAction,
      });
    },
  };

  const ConfirmDialog = useCallback(() => {
    const handleClose = () => {
      if (!isLoading) {
        hide();
      }
    };

    const handleConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (dialogProps.onClick) {
        try {
          setIsLoading(true);
          await dialogProps.onClick(e);
          hide();
        } catch (error) {
          console.error("Confirm action failed:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{dialogProps.title}</DialogTitle>
        <DialogContent id="confirm-dialog-description">
          {dialogProps.content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" disabled={isLoading}>
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirm}
            color="error"
            variant="contained"
            autoFocus
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
          >
            {isLoading ? "Processing..." : confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [open, dialogProps, hide, isLoading, confirmButtonText, cancelButtonText]);

  return {
    show,
    hide,
    ConfirmDialog,
    saveButtonPropsWithConfirm,
  };
};
