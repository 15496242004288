/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ListInput } from "./list-input";

type MetadataField = {
  key: string;
  value: string | string[];
  type: "text" | "number" | "date" | "select" | "list";
  options?: string[];
};

interface MetadataEditorProps {
  value: Record<string, any>;
  onChange: (value: Record<string, any>) => void;
}

const inferFieldType = (value: any): MetadataField["type"] => {
  if (typeof value === "number") return "number";
  if (value instanceof Date) return "date";
  if (Array.isArray(value)) return "list";
  return "text";
};

const formatFieldValue = (
  value: any,
  type: MetadataField["type"]
): string | string[] => {
  if (value === null || value === undefined) return "";
  if (type === "date" && value instanceof Date) {
    return value.toISOString().split("T")[0];
  }
  if (type === "list" && Array.isArray(value)) {
    return value.map(String);
  }
  return String(value);
};

export const MetadataEditor: React.FC<MetadataEditorProps> = ({
  value,
  onChange,
}) => {
  const [fields, setFields] = React.useState<MetadataField[]>([]);

  // Initialize fields from incoming metadata
  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      const initialFields = Object.entries(value).map(([key, val]) => {
        const type = inferFieldType(val);
        return {
          key,
          value: formatFieldValue(val, type),
          type,
        };
      });
      setFields(initialFields);
    }
  }, [value]);

  const handleAddField = () => {
    setFields([...fields, { key: "", value: "", type: "text" }]);
  };

  const handleRemoveField = (index: number) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    updateMetadata(newFields);
  };

  const handleFieldChange = (
    index: number,
    field: string,
    newValue: string | string[] | MetadataField["type"]
  ) => {
    const newFields = fields.map((f, i) => {
      if (i === index) {
        // Reset value when changing type to ensure format compatibility
        const shouldResetValue = field === "type" && f.type !== newValue;
        return {
          ...f,
          [field]: newValue,
          ...(shouldResetValue ? { value: f.type === "list" ? [] : "" } : {}),
        };
      }
      return f;
    });
    setFields(newFields);
    updateMetadata(newFields);
  };

  const parseFieldValue = (
    value: string | string[],
    type: MetadataField["type"]
  ) => {
    if (!value) return null;

    switch (type) {
      case "number":
        return Number(value);
      case "date":
        return new Date(value as string);
      case "list":
        return Array.isArray(value) ? value : [];
      default:
        return value;
    }
  };

  const updateMetadata = (updatedFields: MetadataField[]) => {
    const metadata = updatedFields.reduce((acc, field) => {
      if (field.key.trim()) {
        acc[field.key] = parseFieldValue(field.value, field.type);
      }
      return acc;
    }, {} as Record<string, any>);

    onChange(metadata);
  };

  const renderFieldInput = (field: MetadataField, index: number) => {
    if (field.type === "list") {
      return (
        <ListInput
          value={Array.isArray(field.value) ? field.value : []}
          onChange={(newValue) => handleFieldChange(index, "value", newValue)}
        />
      );
    }

    if (field.type === "date") {
      return (
        <TextField
          size="small"
          label="Value"
          type="date"
          value={field.value}
          onChange={(e) => handleFieldChange(index, "value", e.target.value)}
          sx={{ flex: 1 }}
          InputLabelProps={{ shrink: true }}
        />
      );
    }

    return (
      <TextField
        size="small"
        label="Value"
        type={field.type === "number" ? "number" : "text"}
        value={field.value}
        onChange={(e) => handleFieldChange(index, "value", e.target.value)}
        sx={{ flex: 1 }}
      />
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Custom Fields
      </Typography>

      {fields.map((field, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            gap: 1,
            mb: 1,
            alignItems: "flex-start",
          }}
        >
          <TextField
            size="small"
            label="Field Name"
            value={field.key}
            onChange={(e) => handleFieldChange(index, "key", e.target.value)}
            sx={{ flexBasis: "30%" }}
          />

          <TextField
            select
            size="small"
            label="Type"
            value={field.type}
            onChange={(e) =>
              handleFieldChange(
                index,
                "type",
                e.target.value as MetadataField["type"]
              )
            }
            sx={{ flexBasis: "20%" }}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="list">List</MenuItem>
          </TextField>

          <Box sx={{ flex: 1 }}>{renderFieldInput(field, index)}</Box>

          <IconButton
            onClick={() => handleRemoveField(index)}
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}

      <Button
        startIcon={<AddIcon />}
        onClick={handleAddField}
        variant="outlined"
        size="small"
        sx={{ mt: 1 }}
      >
        Add Field
      </Button>
    </Box>
  );
};
