import React from "react";
import { useShow, useList, useGo } from "@refinedev/core";
import { Show, TextFieldComponent as TextField } from "@refinedev/mui";
import {
  Typography,
  Stack,
  Chip,
  Grid,
  Card,
  CardContent,
  Avatar,
  Box,
  Divider,
} from "@mui/material";
import { Tables } from "../../types";
import { Business, Event, LocationOn } from "@mui/icons-material";
import { Theme } from "@mui/material/styles";
import { MetadataRenderer } from "../../components/metadata-renderer";

type Organization = Tables<"organizations">;
type Matter = Tables<"matters">;
type Profiles = Tables<"profiles">;
export const OrganizationShow: React.FC = () => {
  const { query: organizationQueryResult } = useShow<Organization>();
  const { data, isLoading } = organizationQueryResult;
  const record = data?.data;
  const go = useGo();
  // Fetch related matters
  const { data: mattersData } = useList<Matter>({
    resource: "matters",
    filters: [
      {
        field: "client_org_id",
        operator: "eq",
        value: record?.id,
      },
    ],
    queryOptions: {
      enabled: !!record?.id,
    },
  });

  // Fetch related attorneys
  const { data: attorneysData } = useList<Profiles>({
    resource: "profiles",
    filters: [
      {
        field: "organizations.id",
        operator: "eq",
        value: record?.id,
      },
    ],
    queryOptions: {
      enabled: !!record?.id,
    },
  });

  return (
    <Show
      isLoading={isLoading}
      headerButtons={[
        <Chip
          icon={<Event />}
          label={`${mattersData?.total || 0} Matters`}
          color="primary"
          variant="outlined"
          sx={{ mr: 1 }}
        />,
        <Chip
          icon={<Business />}
          label={`${attorneysData?.total || 0} Attorneys`}
          color="secondary"
          variant="outlined"
        />,
      ]}
      wrapperProps={{
        sx: {
          paddingX: { xs: 2, lg: 4 },
          paddingY: { xs: 2, lg: 3 },
        },
      }}
    >
      <Card
        elevation={0}
        sx={{
          mb: 3,
          bgcolor: "primary.main",
          color: "primary.contrastText",
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            {record?.logo_url && (
              <Grid item xs={12} sm="auto">
                <Avatar
                  src={record.logo_url}
                  alt={`${record?.name} logo`}
                  sx={{
                    width: { xs: 80, sm: 100 },
                    height: { xs: 80, sm: 100 },
                    bgcolor: "white",
                    p: 1,
                  }}
                  variant="rounded"
                />
              </Grid>
            )}
            <Grid item xs>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                {record?.name}
              </Typography>
              <Stack direction="row" spacing={2} flexWrap="wrap">
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  <Business fontSize="small" />
                  {record?.org_type?.replace(/_/g, " ").toLowerCase()}
                </Typography>
                {record?.address && (
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontSize: { xs: "0.875rem", sm: "1rem" },
                    }}
                  >
                    <LocationOn fontSize="small" />
                    {record.address}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={3}>
        {/* Organization Details Card */}
        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <Typography variant="h5" gutterBottom color="primary">
                Organization Details
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <Stack spacing={2}>
                <Box>
                  <Typography variant="subtitle2">Name</Typography>
                  <TextField value={record?.name} />
                </Box>

                <Box>
                  <Typography variant="subtitle2">Type</Typography>
                  <Chip
                    label={record?.org_type?.replace(/_/g, " ").toLowerCase()}
                    color="primary"
                    variant="outlined"
                  />
                </Box>

                {record?.address && (
                  <Box>
                    <Typography variant="subtitle2">Address</Typography>
                    <TextField value={record.address} />
                  </Box>
                )}

                <Box>
                  <Typography variant="subtitle2">Created At</Typography>
                  <TextField
                    value={
                      record?.created_at &&
                      new Date(record.created_at).toLocaleDateString()
                    }
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Metadata Card */}
        {record?.metadata && (
          <Grid item xs={12} md={6}>
            <Card elevation={2}>
              <CardContent>
                <Typography variant="h5" gutterBottom color="primary">
                  Additional Information
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <MetadataRenderer metadata={record.metadata} />
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* Related Matters Card */}
        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                sx={{
                  fontSize: { xs: "1.25rem", sm: "1.5rem" },
                  fontWeight: "medium",
                }}
              >
                Related Matters
              </Typography>
              <Divider sx={{ mb: 2 }} />

              <Stack gap={2}>
                {mattersData?.data?.map((matter) => (
                  <Card
                    key={matter.id}
                    variant="outlined"
                    onClick={() => go({ to: `/matters/show/${matter.id}` })}
                    sx={{
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      borderLeft: (theme: Theme) =>
                        `4px solid ${
                          matter.status === "open"
                            ? theme.palette.success.main
                            : theme.palette.grey[300]
                        }`,
                      "&:hover": {
                        bgcolor: "action.hover",
                        transform: "translateY(-2px)",
                        boxShadow: 2,
                      },
                    }}
                  >
                    <CardContent>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: { xs: "1rem", sm: "1.1rem" },
                            }}
                          >
                            {matter.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              mt: 0.5,
                              fontSize: { xs: "0.75rem", sm: "0.875rem" },
                            }}
                          >
                            Created:{" "}
                            {matter.created_at
                              ? new Date(matter.created_at).toLocaleDateString()
                              : "N/A"}
                          </Typography>
                        </Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip
                            label={matter.status}
                            size="small"
                            color={
                              matter.status === "open" ? "success" : "default"
                            }
                            sx={{
                              fontWeight: 500,
                              fontSize: { xs: "0.75rem", sm: "0.875rem" },
                            }}
                          />
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ fontSize: { xs: "0.7rem", sm: "0.75rem" } }}
                          >
                            #{matter.id}
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
                {(!mattersData?.data || mattersData.data.length === 0) && (
                  <Typography color="text.secondary">
                    No related matters found
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Related Attorneys Card */}
        <Grid item xs={12}>
          <Card elevation={2}>
            <CardContent>
              <Typography variant="h5" gutterBottom color="primary">
                Related Attorneys
              </Typography>
              <Divider sx={{ mb: 3 }} />

              <Grid container spacing={2}>
                {attorneysData?.data?.map((attorney) => (
                  <Grid item xs={12} sm={6} md={4} key={attorney.id}>
                    <Card
                      variant="outlined"
                      onClick={() =>
                        go({ to: `/profiles/show/${attorney.id}` })
                      }
                      sx={{
                        cursor: "pointer",
                        transition: "all 0.2s ease-in-out",
                        height: "100%",
                        borderRadius: 2,
                        "&:hover": {
                          bgcolor: "action.hover",
                          transform: "translateY(-2px)",
                          boxShadow: 2,
                        },
                      }}
                    >
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Avatar
                              src={attorney?.avatar_url || ""}
                              sx={{
                                width: { xs: 48, sm: 56 },
                                height: { xs: 48, sm: 56 },
                                bgcolor: "primary.main",
                              }}
                            >
                              {attorney?.full_name?.charAt(0)}
                            </Avatar>
                            <Box>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: 600,
                                  fontSize: { xs: "0.9rem", sm: "1rem" },
                                  lineHeight: 1.2,
                                }}
                              >
                                {attorney.full_name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="primary"
                                sx={{
                                  fontSize: { xs: "0.8rem", sm: "0.875rem" },
                                  mt: 0.5,
                                }}
                              >
                                {attorney?.role}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{
                                  fontSize: { xs: "0.7rem", sm: "0.75rem" },
                                  display: "block",
                                  mt: 0.5,
                                }}
                              >
                                {attorney?.email}
                              </Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {(!attorneysData?.data || attorneysData.data.length === 0) && (
                  <Grid item xs={12}>
                    <Typography color="text.secondary">
                      No related attorneys found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Show>
  );
};
