import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ErrorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.error.contrastText,
}));
