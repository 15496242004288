import { Box, Button, Typography, Paper } from "@mui/material";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 500,
          textAlign: "center",
          borderRadius: 2,
        }}
      >
        <SearchOffIcon
          sx={{
            fontSize: 80,
            color: "primary.main",
            mb: 2,
            animation: "float 6s ease-in-out infinite",
          }}
        />

        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "4rem",
            fontWeight: "bold",
            color: "primary.main",
            mb: 2,
          }}
        >
          404
        </Typography>

        <Typography variant="h4" gutterBottom color="textPrimary">
          Page Not Found
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          Looks like our legal eagles couldn't locate this page! It might have
          been moved, deleted, or never existed in the first place.
        </Typography>

        <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
          <Button
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={() => navigate("/")}
          >
            Go Home
          </Button>
          <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            onClick={() => {
              window.location.href =
                "mailto:dylan@legalease.com?subject=404 Page Report&body=" +
                encodeURIComponent(
                  `404 Error: Page not found\nURL: ${window.location.href}`
                );
            }}
          >
            Report Issue
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

// Add floating animation
const style = document.createElement("style");
style.textContent = `
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
document.head.appendChild(style);
