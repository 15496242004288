import React, { useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  Typography,
  Paper,
  Box,
  Chip,
  Stack,
  IconButton,
  CircularProgress,
  AvatarGroup,
  Avatar,
  SxProps,
  Theme,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Description,
  Event,
  Note,
  Assignment,
  Gavel,
  Update,
  Edit,
  MoreVert,
} from "@mui/icons-material";
import { format } from "date-fns";
import { Tables, Enums } from "../../types";
import { EditActivityModal } from "../edit-activity-modal";
import { useDelete, useNotification } from "@refinedev/core";

interface ActivityTimelineProps {
  activities: Tables<"activities">[];
  onActivityUpdate?: () => void;
  isLoading?: boolean;
  participants?: {
    activity_id: string;
    profile: {
      id: string;
      avatar_url?: string | null;
      full_name: string;
      initials?: string | null;
    };
  }[];
  sx?: SxProps<Theme>;
}

export const ActivityTimeline: React.FC<ActivityTimelineProps> = ({
  activities,
  onActivityUpdate,
  isLoading,
  participants,
  sx,
}) => {
  const [editingActivity, setEditingActivity] =
    useState<Tables<"activities"> | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(null);
  const { mutate: deleteActivity } = useDelete();
  const { open: openNotification } = useNotification();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, activityId: string) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedActivityId(activityId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedActivityId(null);
  };

  const handleDelete = async () => {
    if (!selectedActivityId) return;

    try {
      await deleteActivity({
        resource: "activities",
        id: selectedActivityId,
        successNotification: {
          message: "Activity successfully deleted",
          type: "success",
        },
      });

      onActivityUpdate?.();
    } catch (error) {
      openNotification?.({
        message: "Error deleting activity",
        type: "error",
      });
    }
    handleMenuClose();
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!activities.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
      >
        <Typography color="text.secondary">No activities found</Typography>
      </Box>
    );
  }

  const getActivityIcon = (type: Enums<"activity_type">) => {
    switch (type) {
      case "task":
        return <Assignment fontSize="small" />;
      case "meeting":
        return <Event fontSize="small" />;
      case "document_filed":
        return <Description fontSize="small" />;
      case "court_appearance":
        return <Gavel fontSize="small" />;
      case "status_change":
        return <Update fontSize="small" />;
      case "note":
        return <Note fontSize="small" />;
      default:
        return <Description fontSize="small" />;
    }
  };

  return (
    <>
      <Timeline sx={sx} position="alternate">
        {activities.map((activity, index) => (
          <TimelineItem key={activity.id}>
            <TimelineSeparator>
              <TimelineDot color="primary">
                {getActivityIcon(activity.activity_type)}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ px: 2 }}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  mb: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                  transition: "all 0.2s ease-in-out",
                  "&:hover": {
                    bgcolor: "action.hover",
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography variant="subtitle2" color="text.secondary">
                    {activity.created_at
                      ? format(
                          new Date(activity.created_at),
                          "MMM d, yyyy h:mm a"
                        )
                      : "No date"}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      label={activity.activity_type.replace("_", " ")}
                      size="small"
                      variant="outlined"
                    />
                    <IconButton
                      size="small"
                      onClick={(e) => handleMenuOpen(e, activity.id)}
                    >
                      <MoreVert fontSize="small" />
                    </IconButton>
                  </Stack>
                </Box>
                <Typography variant="h6" component="h3" gutterBottom>
                  {activity.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {activity.description}
                </Typography>
                {activity.activity_type === "task" && (
                  <Box mt={1}>
                    <Chip
                      label={
                        activity.status === "completed"
                          ? "Completed"
                          : "Pending"
                      }
                      color={
                        activity.status === "completed" ? "success" : "default"
                      }
                      size="small"
                    />
                    {activity.due_date && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ ml: 1 }}
                      >
                        Due:{" "}
                        {format(new Date(activity.due_date), "MMM d, yyyy")}
                      </Typography>
                    )}
                  </Box>
                )}
                {participants &&
                  participants.filter((p) => p.activity_id === activity.id)
                    .length > 0 && (
                    <Box mt={2}>
                      <AvatarGroup
                        max={4}
                        sx={{
                          justifyContent:
                            index % 2 === 0 ? "flex-start" : "flex-end",
                        }}
                      >
                        {participants
                          .filter((p) => p.activity_id === activity.id)
                          .map((participant) => (
                            <Avatar
                              key={`${activity.id}-${participant.profile.id}`}
                              src={participant.profile.avatar_url || undefined}
                              alt={participant.profile.full_name}
                              sx={{ width: 24, height: 24 }}
                            >
                              {participant.profile.initials}
                            </Avatar>
                          ))}
                      </AvatarGroup>
                    </Box>
                  )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={() => {
            const activity = activities.find((a) => a.id === selectedActivityId);
            if (activity) {
              setEditingActivity(activity);
              handleMenuClose();
            }
          }}
        >
          <Edit fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
          <Description fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      {editingActivity && (
        <EditActivityModal
          open={!!editingActivity}
          onClose={() => setEditingActivity(null)}
          activity={editingActivity}
          onSuccess={() => {
            setEditingActivity(null);
            onActivityUpdate?.();
          }}
        />
      )}
    </>
  );
};
