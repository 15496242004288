import React, { useState } from "react";
import { Create } from "@refinedev/mui";
import {
  Box,
  TextField,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Tables, OrgType, UserRole } from "../../types/schema";
import { Add as AddIcon } from "@mui/icons-material";

type ExtendedOrganization = Omit<
  Tables<"organizations">,
  "id" | "created_at" | "updated_at"
> & {
  metadata?: {
    practice_areas?: string[];
    billing_rate_range?: string;
  };
};

export const OrganizationCreate: React.FC = () => {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const {
    saveButtonProps,
    register,
    formState: { errors },
    setValue,
  } = useForm<ExtendedOrganization>({
    defaultValues: {
      org_type: OrgType.external_law_firm,
      metadata: {
        practice_areas: [],
        billing_rate_range: "",
      },
      created_by: undefined,
    },
  });

  const [profiles, setProfiles] = useState<
    Array<{
      full_name: string;
      email: string;
      phone?: string;
      role: UserRole;
    }>
  >([]);

  const handleAddContact = () => {
    setIsContactDialogOpen(true);
  };

  const handleContactSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    setProfiles([
      ...profiles,
      {
        full_name: formData.get("full_name") as string,
        email: formData.get("email") as string,
        phone: formData.get("phone") as string,
        role: formData.get("role") as UserRole,
      },
    ]);

    setIsContactDialogOpen(false);
  };

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Create External Counsel Profile"
      wrapperProps={{
        sx: {
          "& .MuiPaper-root": {
            paddingX: { xs: 2, sm: 4 },
            paddingY: { xs: 2, sm: 3 },
          },
        },
      }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          maxWidth: 800,
          mx: "auto",
        }}
        autoComplete="off"
      >
        <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
          Firm Details
        </Typography>

        <Stack spacing={3}>
          {/* Essential Information */}
          <TextField
            {...register("name", {
              required: "Firm name is required",
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters",
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message as string}
            label="Firm Name"
            required
          />

          <TextField
            {...register("main_email")}
            type="email"
            label="Main Contact Email"
            helperText="Optional - Primary email for firm communications"
          />

          <TextField
            {...register("main_phone")}
            label="Main Contact Phone"
            helperText="Optional - Primary phone number for firm communications"
          />

          <TextField
            {...register("website")}
            label="Website"
            helperText="Optional - Firm's website URL"
          />

          <TextField
            {...register("metadata.practice_areas_input")}
            label="Primary Practice Areas"
            helperText="Optional - Separate multiple areas with commas"
            multiline
            rows={2}
            onChange={(e) => {
              const areas = e.target.value
                .split(",")
                .map((area) => area.trim())
                .filter(Boolean);
              setValue("metadata.practice_areas", areas);
            }}
          />

          <TextField
            {...register("metadata.billing_rate_range")}
            label="Standard Rate Range"
            helperText="Optional - e.g., $200-500/hour"
          />

          <TextField
            {...register("address")}
            label="Office Address"
            helperText="Optional - Main office or billing address"
            multiline
            rows={3}
          />

          {/* Profile Association Section with improved styling */}
          <Box
            sx={{
              mt: 4,
              p: 2,
              bgcolor: "background.default",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6" color="primary">
                Key Contacts
              </Typography>
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddContact}
                variant="contained"
                size="small"
                sx={{ borderRadius: 1 }}
              >
                Add Contact
              </Button>
            </Box>

            {profiles.length === 0 ? (
              <Typography variant="body2" color="text.secondary">
                Add key contacts from the law firm who will be working with your
                organization.
              </Typography>
            ) : (
              <Stack spacing={2}>
                {profiles.map((profile, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 1,
                      bgcolor: "background.paper",
                    }}
                  >
                    <Typography variant="subtitle2">
                      {profile.full_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {profile.role.replace("_", " ")} • {profile.email}
                    </Typography>
                    {profile.phone && (
                      <Typography variant="body2" color="text.secondary">
                        {profile.phone}
                      </Typography>
                    )}
                    <Button
                      size="small"
                      color="error"
                      onClick={() => {
                        const updatedProfiles = profiles.filter(
                          (_, i) => i !== index
                        );
                        setProfiles(updatedProfiles);
                      }}
                      sx={{ mt: 1 }}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
        </Stack>

        <input
          type="hidden"
          {...register("org_type")}
          value="external_law_firm"
        />
      </Box>

      {/* Dialog with updated styling */}
      <Dialog
        open={isContactDialogOpen}
        onClose={() => setIsContactDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <Box component="form" onSubmit={handleContactSubmit}>
          <DialogTitle>Add Key Contact</DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ mt: 1 }}>
              <TextField
                name="full_name"
                label="Full Name"
                required
                fullWidth
              />

              <TextField
                name="email"
                label="Email"
                type="email"
                required
                fullWidth
              />

              <TextField name="phone" label="Phone Number" fullWidth />

              <FormControl fullWidth required>
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  label="Role"
                  defaultValue={UserRole.external_counsel}
                >
                  <MenuItem value={UserRole.partner}>Partner</MenuItem>
                  <MenuItem value={UserRole.associate}>Associate</MenuItem>
                  <MenuItem value={UserRole.external_counsel}>
                    External Counsel
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsContactDialogOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Add Contact
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Create>
  );
};
