import { MatterStatus } from "../types/schema";

export const getStatusColor = (status: MatterStatus) => {
  switch (status) {
    case MatterStatus.intake:
      return "#FF9800"; // orange
    case MatterStatus.open:
    case MatterStatus.active:
      return "#4CAF50"; // green
    case MatterStatus.closed:
    case MatterStatus.completed:
      return "#9E9E9E"; // grey
    case MatterStatus.archived:
      return "#607D8B"; // blue grey
    case MatterStatus.on_hold:
      return "#FFC107"; // amber
    case MatterStatus.pending_review:
    case MatterStatus.draft:
      return "#2196F3"; // blue
    case MatterStatus.discovery:
      return "#9C27B0"; // purple
    case MatterStatus.negotiation:
      return "#E91E63"; // pink
    case MatterStatus.trial_prep:
    case MatterStatus.trial:
      return "#F44336"; // red
    case MatterStatus.appeal:
      return "#795548"; // brown
    default:
      return "#9E9E9E"; // default grey
  }
};
