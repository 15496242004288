import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add as AddIcon } from "@mui/icons-material";
import { MatterCard } from "./MatterCard";
import { useGo } from "@refinedev/core";
import { MatterWithRelations } from "../../types/matter";

const CarouselContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  maxWidth: "100%",
  "& .MuiBox-root": {
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    overflowX: "auto",
    overflowY: "hidden",
    padding: theme.spacing(1),
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      width: 6,
      height: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.grey[100],
      borderRadius: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[300],
      borderRadius: 3,
      "&:hover": {
        background: theme.palette.grey[400],
      },
    },
  },
}));

interface MattersCarouselProps {
  matters: MatterWithRelations[];
}

export const MattersCarousel: React.FC<MattersCarouselProps> = ({
  matters,
}) => {
  const go = useGo();
  const [page, setPage] = React.useState(0);
  const itemsPerPage = 3;

  const paginatedMatters = React.useMemo(() => {
    const start = page * itemsPerPage;
    return matters.slice(start, start + itemsPerPage);
  }, [matters, page]);

  const totalPages = Math.ceil(matters.length / itemsPerPage);

  const handleNext = () => {
    setPage((prev) => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrev = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  if (!matters?.length) {
    return (
      <Box
        sx={{
          textAlign: "center",
          py: 6,
          px: 3,
          bgcolor: "background.paper",
          borderRadius: 1,
          border: "2px dashed",
          borderColor: "divider",
        }}
      >
        <Typography color="text.secondary" gutterBottom>
          No active matters found
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => go({ to: "/matters/create" })}
          sx={{ mt: 2 }}
        >
          Create New Matter
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Active Matters
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => go({ to: "/matters" })}
          >
            View All
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => go({ to: "/matters/create" })}
          >
            New Matter
          </Button>
        </Box>
      </Box>
      <CarouselContainer>
        <Box>
          {paginatedMatters.map((matter) => (
            <MatterCard key={matter.id} matter={matter} />
          ))}
        </Box>
      </CarouselContainer>
      {matters.length > itemsPerPage && (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 2 }}>
          <Button size="small" onClick={handlePrev} disabled={page === 0}>
            Previous
          </Button>
          <Typography variant="body2" sx={{ alignSelf: "center" }}>
            {page + 1} of {totalPages}
          </Typography>
          <Button
            size="small"
            onClick={handleNext}
            disabled={page === totalPages - 1}
          >
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};
