import { createClient } from "@refinedev/supabase";
import { Database } from "../types/schema";

const SUPABASE_URL = "https://ohfehwsvtivuovcdefih.supabase.co";
const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9oZmVod3N2dGl2dW92Y2RlZmloIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzAxNjE1MDQsImV4cCI6MjA0NTczNzUwNH0.v8Gq7bjNIDmCH7IHmjTqjUpv8ye95WUET78VDJS9dgo";

export const supabaseClient = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_KEY,
  {
    db: {
      schema: "public",
    },
    auth: {
      persistSession: true,
    },
  }
);
