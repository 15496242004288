import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";

interface LazyImageProps {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  className?: string;
}

export const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  width = "100%",
  height = "100%",
  className,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <Box position="relative" width={width} height={height}>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width={width}
          height={height}
          animation="wave"
        />
      )}
      {!error && (
        <img
          src={src}
          alt={alt}
          className={className}
          style={{
            width,
            height,
            objectFit: "cover",
            display: isLoading ? "none" : "block",
          }}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false);
            setError(true);
          }}
        />
      )}
    </Box>
  );
};
