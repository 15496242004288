import React, { useCallback, useMemo } from "react";
import {
  useTable,
  useCan,
  useNavigation,
} from "@refinedev/core";
import { DeleteButton, List } from "@refinedev/mui";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Avatar,
  AvatarGroup,
  Skeleton,
  Box,
  Button,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  Add as AddIcon,
  FolderOpen as FolderOpenIcon,
} from "@mui/icons-material";
import { format } from "date-fns";
import { Tables, Enums } from "../../types";
import { EditButton } from "@refinedev/mui";
import { ErrorBoundary } from "../../components/common/ErrorBoundary";

// Constants and Types
type TeamMember = Pick<Tables<"profiles">, "id" | "full_name" | "avatar_url">;

interface MatterWithTeamMembers extends Tables<"matters"> {
  team_members: TeamMember[];
}

const statusColors: Record<
  Enums<"matter_status">,
  { bg: string; color: string }
> = {
  open: { bg: "#E6F2EF", color: "#2E7D6E" },
  discovery: { bg: "#EBF0F7", color: "#4A6FA5" },
  negotiation: { bg: "#F7EFE3", color: "#B4833E" },
  trial_prep: { bg: "#EEF2F6", color: "#506B95" },
  trial: { bg: "#F7EBEB", color: "#9E4F50" },
  appeal: { bg: "#F7EFE3", color: "#B4833E" },
  closed: { bg: "#E3E8EF", color: "#697586" },
  archived: { bg: "#E3E8EF", color: "#697586" },
  intake: { bg: "#EBF0F7", color: "#4A6FA5" },
};

// Common Styles
const commonStyles = {
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    bgcolor: "background.paper",
    transition: "all 0.2s ease-in-out",
    border: "1px solid",
    borderColor: "divider",
    boxShadow: "none",
    "&:hover": {
      borderColor: "primary.light",
      boxShadow: "0 4px 12px rgba(0,0,0,0.05)",
      transform: {
        xs: "none",
        sm: "translateY(-2px)",
      },
    },
  },
  cardContent: {
    p: { xs: 2, sm: 2.5 },
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&:last-child": { pb: { xs: 2, sm: 2.5 } },
  },
  avatarGroup: {
    justifyContent: "flex-start",
    "& .MuiAvatar-root": {
      width: { xs: 32, sm: 28 },
      height: { xs: 32, sm: 28 },
      fontSize: { xs: "1rem", sm: "0.875rem" },
      borderWidth: 1.5,
      borderColor: "background.paper",
      bgcolor: "primary.light",
      color: "primary.contrastText",
    },
  },
  button: {
    bgcolor: "grey.50",
    minWidth: { xs: 40, sm: 35 },
    height: { xs: 40, sm: 35 },
    "&:hover": {
      bgcolor: "grey.100",
    },
  },
} as const;

// Skeleton Component
const MatterCardSkeleton: React.FC = () => (
  <Grid item xs={12} sm={6} md={4}>
    <Card
      sx={{
        height: "100%",
        p: 2,
        boxShadow: "none",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Skeleton variant="text" width="80%" height={32} />
          <Skeleton variant="text" width="40%" />
          <Skeleton
            variant="rectangular"
            height={80}
            sx={{ borderRadius: 1 }}
          />
          <Stack direction="row" spacing={1}>
            {[1, 2, 3].map((i) => (
              <Skeleton key={i} variant="circular" width={32} height={32} />
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton variant="text" width={100} />
            <Skeleton
              variant="rectangular"
              width={80}
              height={36}
              sx={{ borderRadius: 1 }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  </Grid>
);

// Matter Card Component
const MatterCard: React.FC<{
  matter: MatterWithTeamMembers;
  onShow: (id: string) => void;
  canEdit: boolean;
  canDelete: boolean;
}> = ({ matter, onShow, canEdit, canDelete }) => {
  const statusColor =
    statusColors[matter.status as Enums<"matter_status">] ||
    statusColors.closed;

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card
        sx={{
          ...commonStyles.card,
          cursor: "pointer",
          "&:hover": {
            ...commonStyles.card["&:hover"],
            "& .action-buttons": {
              opacity: 1,
              visibility: "visible",
            },
          },
        }}
        onClick={(e) => {
          // Prevent click if clicking on action buttons
          if (!(e.target as HTMLElement).closest(".action-buttons")) {
            onShow(matter.id);
          }
        }}
      >
        <CardContent sx={commonStyles.cardContent}>
          <Stack spacing={{ xs: 2.5, sm: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap={1}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  mb: 0.5,
                  fontSize: { xs: "1.1rem", sm: "1rem" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  flex: 1,
                }}
              >
                {matter.title}
              </Typography>
              <Chip
                label={matter.status?.replace("_", " ").toUpperCase()}
                size="small"
                sx={{
                  bgcolor: statusColor.bg,
                  color: statusColor.color,
                  fontWeight: 500,
                  "& .MuiChip-label": { px: 1.5 },
                  flexShrink: 0,
                }}
              />
            </Box>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                minHeight: "3.6em",
                lineHeight: 1.5,
              }}
            >
              {matter.description || "No description provided"}
            </Typography>

            {matter.team_members?.length > 0 && (
              <Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: "block", mb: 1, fontWeight: 500 }}
                >
                  Team Members
                </Typography>
                <AvatarGroup max={4} sx={commonStyles.avatarGroup}>
                  {matter.team_members.map((member) => (
                    <Tooltip
                      key={member.id}
                      title={member?.full_name || "Team Member"}
                      arrow
                      placement="top"
                    >
                      <Avatar
                        alt={member?.full_name || ""}
                        src={member?.avatar_url || ""}
                      >
                        {member.full_name?.charAt(0)}
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Box>
            )}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt="auto"
              pt={2}
              sx={{ borderTop: 1, borderColor: "divider" }}
            >
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                Updated{" "}
                {matter.updated_at
                  ? format(new Date(matter.updated_at), "MMM d, yyyy")
                  : "N/A"}
              </Typography>

              <Stack
                direction="row"
                spacing={{ xs: 1.5, sm: 1 }}
                className="action-buttons"
                sx={{
                  "& .MuiButton-root": {
                    minWidth: { xs: 40, sm: 35 },
                    height: { xs: 40, sm: 35 },
                  },
                  opacity: { xs: 1, sm: 0 },
                  visibility: { xs: "visible", sm: "hidden" },
                  transition:
                    "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out",
                }}
              >
                {canEdit && (
                  <EditButton
                    resource="matters"
                    hideText
                    size="small"
                    recordItemId={matter.id}
                    sx={commonStyles.button}
                  />
                )}
                {canDelete && (
                  <DeleteButton
                    resource="matters"
                    hideText
                    size="small"
                    recordItemId={matter.id}
                    sx={commonStyles.button}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

// Empty State Component
const EmptyState: React.FC<{ onCreate: () => void }> = ({ onCreate }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    minHeight="400px"
    textAlign="center"
    p={3}
  >
    <FolderOpenIcon
      sx={{
        fontSize: 64,
        color: "grey.300",
        mb: 2,
      }}
    />
    <Typography
      variant="h6"
      color="text.primary"
      gutterBottom
      sx={{ fontWeight: 500 }}
    >
      No Matters Yet
    </Typography>
    <Typography
      variant="body1"
      color="text.secondary"
      sx={{ mb: 3, maxWidth: 400 }}
    >
      Create your first matter to start your legal project management journey.
    </Typography>
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onCreate}
      sx={{
        bgcolor: "primary.main",
        py: { xs: 1.5, sm: 1 },
        px: { xs: 3, sm: 2 },
        "&:hover": { bgcolor: "primary.dark" },
      }}
    >
      Create Matter
    </Button>
  </Box>
);

// Main List Component
export const MatterList: React.FC = () => {
  const { data: canEdit } = useCan({ action: "edit", resource: "matters" });
  const { data: canDelete } = useCan({ action: "delete", resource: "matters" });
  const { show, create } = useNavigation();
  
  const {
    tableQuery: { data, isLoading, isError },
  } = useTable<MatterWithTeamMembers>({
    resource: "matters",
    pagination: { pageSize: 12 },
    meta: {
      select: `*,team_members:matter_team_members(profiles(id,full_name,avatar_url))`,
    },
  });

  const handleShow = useCallback((id: string) => show("matters", id), [show]);

  const memoizedMatters = useMemo(
    () =>
      data?.data.map((matter) => (
        <MatterCard
          key={matter.id}
          matter={matter}
          onShow={handleShow}
          canDelete={canDelete?.can ?? false}
          canEdit={canEdit?.can ?? false}
        />
      )),
    [data?.data, handleShow, canEdit, canDelete]
  );

  if (isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <Typography color="error">
          Error loading matters. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <List
        headerButtons={() => (
          <Button
            variant="contained"
            onClick={() => create("matters")}
            startIcon={<AddIcon />}
            sx={{
              bgcolor: "primary.main",
              py: { xs: 1.5, sm: 1 },
              px: { xs: 3, sm: 2 },
              "&:hover": { bgcolor: "primary.dark" },
            }}
          >
            Create Matter
          </Button>
        )}
        wrapperProps={{
          sx: {
            height: "100%",
            minHeight: "calc(100vh - 64px)",
            bgcolor: "background.paper",
            p: { xs: 1.5, sm: 2, md: 3 },
            borderRadius: 2,
            border: "1px solid",
            borderColor: "divider",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            "& .MuiPaper-root": {
              bgcolor: "transparent",
              boxShadow: "none",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
      >
        {isLoading ? (
          <Grid
            container
            spacing={{ xs: 2, sm: 2.5 }}
            sx={{ width: "100%", margin: 0, pb: 3, flex: 1 }}
          >
            {Array.from({ length: 6 }).map((_, i) => (
              <MatterCardSkeleton key={i} />
            ))}
          </Grid>
        ) : data?.data.length === 0 ? (
          <EmptyState onCreate={() => create("matters")} />
        ) : (
          <Grid
            container
            spacing={{ xs: 2, sm: 2.5 }}
            sx={{ width: "100%", margin: 0, pb: 3, flex: 1 }}
          >
            {memoizedMatters}
          </Grid>
        )}
      </List>
    </ErrorBoundary>
  );
};
