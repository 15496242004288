import React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Link,
  InputAdornment,
  CircularProgress,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";
import { useLogin, useNotification, useNavigation } from "@refinedev/core";
import { useForm } from "react-hook-form";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShieldIcon from "@mui/icons-material/Shield";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";

type LoginFormValues = {
  email: string;
  password: string;
};

// Styled component for the button
const AnimatedButton = styled(Button)(({ theme }) => ({
  fontFamily: "Barlow",
  color: theme.palette.primary.main,
  textTransform: "none",
  fontWeight: 600,
  padding: 0,
  minWidth: "auto",
  position: "relative",
  backgroundColor: "transparent",

  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "2px",
    bottom: -2,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    transform: "scaleX(0)",
    transition: "transform 0.3s ease-in-out",
  },

  "&:hover": {
    backgroundColor: "transparent",
    "&::after": {
      transform: "scaleX(1)",
    },
  },
}));

export const Login: React.FC = () => {
  const { mutate: login, isLoading } = useLogin();
  const { open } = useNotification();
  const [showPassword, setShowPassword] = React.useState(false);
  const theme = useTheme();
  const { push } = useNavigation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: LoginFormValues) => {
    login(data, {
      onError: (error) => {
        open?.({
          type: "error",
          message: error instanceof Error 
            ? error.message 
            : "Login failed. Please check your credentials and try again.",
          description: "If this problem persists, please contact support.",
        });
      },
      onSuccess: () => {
        open?.({
          type: "success",
          message: "Successfully logged in",
        });
      },
    });
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: theme.palette.background.default,
        p: 2,
      }}
    >
      <Card
        component={motion.div}
        whileHover={{ y: -5 }}
        transition={{ type: "spring", stiffness: 300 }}
        sx={{
          width: "100%",
          maxWidth: { xs: "100%", sm: 480 },
          boxShadow: theme.shadows[5],
          borderRadius: 2,
        }}
      >
        <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
          <Box
            sx={{
              mb: 4,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: 56,
                height: 56,
                borderRadius: "50%",
                bgcolor: theme.palette.grey[200],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
              }}
            >
              <ShieldIcon
                sx={{ fontSize: 32, color: theme.palette.primary.main }}
              />
            </Box>

            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
                fontSize: { xs: "1.75rem", sm: "2rem" },
                letterSpacing: "-0.5px",
              }}
            >
              Welcome Back
            </Typography>
            <Typography
              color="text.secondary"
              sx={{
                maxWidth: "85%",
                fontSize: "1rem",
              }}
            >
              Sign in to your secure matter management portal
            </Typography>
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
          >
            <TextField
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              label="Email Address"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  bgcolor: "white",
                  transition: "all 0.2s",
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "2px",
                  },
                },
              }}
            />

            <TextField
              {...register("password", {
                required: "Password is required",
              })}
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              error={!!errors.password}
              helperText={errors.password?.message}
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="small"
                      disabled={isLoading}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  bgcolor: "white",
                  transition: "all 0.2s",
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "2px",
                  },
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{
                py: 1.5,
                mt: 1,
                bgcolor: theme.palette.primary.main,
                fontSize: "1rem",
                transition: "all 0.2s",
                "&:hover": {
                  bgcolor: theme.palette.primary.dark,
                  transform: "translateY(-1px)",
                  boxShadow: theme.shadows[4],
                },
                "&:active": {
                  transform: "translateY(0)",
                },
                "&.Mui-disabled": {
                  bgcolor: theme.palette.grey[400],
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Sign In"
              )}
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                mt: 1,
              }}
            >
              <Divider sx={{ width: "100%", my: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ px: 2 }}
                >
                  or
                </Typography>
              </Divider>

              <AnimatedButton onClick={() => push("/register")} disableRipple>
                Create an account
              </AnimatedButton>

              <Button
                sx={{ mt: 1 }}
                onClick={() => {
                  push("/forgot-password");
                }}
                disabled={isLoading}
              >
                Forgot Password?
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
