import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Container,
  InputAdornment,
  IconButton,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigation, useNotification } from "@refinedev/core";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { resetPasswordSchema, type ResetPasswordFormValues } from "../../types/schemas";
import {
  Visibility,
  VisibilityOff,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { supabaseClient } from "../../utility";

const passwordRequirements = [
  { id: "length", label: "At least 8 characters", regex: /.{8,}/ },
  { id: "uppercase", label: "At least one uppercase letter", regex: /[A-Z]/ },
  { id: "lowercase", label: "At least one lowercase letter", regex: /[a-z]/ },
  { id: "number", label: "At least one number", regex: /[0-9]/ },
];

export const ResetPasswordPage = () => {
  const { push } = useNavigation();
  const { open } = useNotification();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [searchParams] = useSearchParams();
  const [error, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    trigger,
  } = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    if (confirmPassword) {
      trigger("confirmPassword");
    }
  }, [password, confirmPassword, trigger]);

  const handleError = (error: unknown, defaultMessage = "An error occurred") => {
    const message = error instanceof Error 
      ? error.message.includes("network")
        ? "Network error occurred. Please check your connection and try again."
        : error.message.includes("expired")
        ? "Your session has expired. Please request a new reset link."
        : error.message
      : defaultMessage;

    setErrorMessage(message);
    setError("password", {
      type: "manual",
      message,
    });

    open?.({
      type: "error",
      message: "Password Reset Failed",
      description: message,
    });

    return message;
  };

  // Exchange recovery code for session
  const exchangeRecoveryCode = async (code: string) => {
    try {
      setIsProcessing(true);
      setErrorMessage(null);

      // First sign out any existing session
      await supabaseClient.auth.signOut();

      const { error: sessionError } = await supabaseClient.auth.exchangeCodeForSession(code);
      
      if (sessionError) {
        if (sessionError.message.includes("expired")) {
          throw new Error("This recovery link has expired. Please request a new one.");
        }
        if (sessionError.message.includes("been used")) {
          throw new Error("This recovery link has already been used. Please request a new one.");
        }
        throw sessionError;
      }

      return true;
    } catch (error) {
      handleError(error, "Failed to validate recovery link");
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const validateRecoveryFlow = async () => {
      try {
        setIsValidating(true);
        
        // Get hash parameters from URL
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        
        // Check for error parameters
        const error = params.get("error");
        const error_code = params.get("error_code");
        const error_description = params.get("error_description");

        if (error || error_code || error_description) {
          console.error('Auth error from URL:', { error, error_code, error_description });
          
          // Handle specific error cases
          if (error_code === 'otp_expired') {
            setErrorMessage("This password reset link has expired. Please request a new one.");
          } else if (error === 'access_denied') {
            setErrorMessage("This password reset link is no longer valid. Please request a new one.");
          } else {
            setErrorMessage(error_description?.replace(/\+/g, ' ') || error || "An error occurred");
          }
          return;
        }

        // Verify we're in a recovery flow
        const type = params.get("type");
        const access_token = params.get("access_token");

        if (!access_token || type !== "recovery") {
          console.error('Invalid recovery parameters:', { type, hasToken: !!access_token });
          setErrorMessage("Invalid password reset link. Please request a new one.");
          return;
        }

        // Clear any existing session first
        await supabaseClient.auth.signOut();

        // The session will be automatically set up by Supabase's client library
        const { data: { session }, error: sessionError } = await supabaseClient.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          if (sessionError.message.includes('expired')) {
            throw new Error("This password reset link has expired. Please request a new one.");
          }
          throw new Error("Failed to validate session. Please request a new reset link.");
        }

        if (!session) {
          console.error('No session established after token exchange');
          throw new Error("Failed to establish session. Please request a new reset link.");
        }

        // Verify the session is for recovery
        if (session.user?.aud !== "authenticated") {
          console.error('Invalid session audience:', session.user?.aud);
          throw new Error("Invalid session type. Please request a new reset link.");
        }
      } catch (err) {
        console.error('Recovery flow error:', err);
        setErrorMessage(
          err instanceof Error 
            ? err.message 
            : "Failed to validate recovery link. Please try again."
        );
      } finally {
        setIsValidating(false);
      }
    };

    validateRecoveryFlow();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsProcessing(true);
      setErrorMessage(null);

      const { data: { user }, error: userError } = await supabaseClient.auth.getUser();
      
      if (userError || !user) {
        throw new Error("Your session has expired. Please request a new reset link.");
      }

      // Update password using Supabase
      const { error: updateError } = await supabaseClient.auth.updateUser({
        password: data.password
      });

      if (updateError) {
        throw updateError;
      }

      // Sign out after successful password update
      await supabaseClient.auth.signOut();

      open?.({
        type: "success",
        message: "Password Reset Successful",
        description: "Your password has been updated successfully. Please login with your new password.",
      });

      setTimeout(() => {
        push("/login");
      }, 1500);
    } catch (error) {
      console.error("Password reset error:", error);
      handleError(error, "Failed to reset password");
    } finally {
      setIsProcessing(false);
    }
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const meetsAllRequirements = password && passwordRequirements.every(({ regex }) => regex.test(password));
  const isValidForm = meetsAllRequirements && confirmPassword && !errors.confirmPassword;

  const isLoading = isProcessing || isValidating;

  // Show loading state while validating the recovery flow
  if (isValidating) {
    return (
      <Box
        component="div"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent sx={{ textAlign: "center", py: 4 }}>
              <CircularProgress size={40} sx={{ mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Validating Reset Link
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Please wait while we validate your password reset link...
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  }

  // If there's an error, show error message with option to request new link
  if (error) {
    return (
      <Box
        component="div"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent sx={{ p: 4 }}>
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 3,
                  '& .MuiAlert-message': {
                    width: '100%'
                  }
                }}
              >
                <Typography variant="subtitle1" component="div" gutterBottom fontWeight="500">
                  Link Expired
                </Typography>
                {error}
              </Alert>
              <Typography variant="body2" sx={{ mb: 3 }}>
                For security reasons, password reset links expire after 60 minutes. Please request a new one.
              </Typography>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={() => push("/forgot-password")}
                sx={{ mb: 2 }}
              >
                Request New Reset Link
              </LoadingButton>
              <Button
                fullWidth
                onClick={() => push("/login")}
              >
                Back to Login
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      component="div"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container maxWidth="sm">
        <Card
          elevation={6}
          sx={{
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              align="center"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
                mb: 3,
              }}
            >
              Set New Password
            </Typography>
            <Typography
              variant="body2"
              align="center"
              sx={{
                mb: 4,
                color: theme.palette.text.secondary,
              }}
            >
              Please enter your new password below.
            </Typography>

            <Box
              component="form"
              onSubmit={onSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <TextField
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password?.message}
                label="New Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                autoFocus
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />

              <TextField
                {...register("confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                fullWidth
                required
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                        size="large"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />

              <List dense sx={{ mt: 1 }}>
                {passwordRequirements.map(({ id, label, regex }) => (
                  <ListItem key={id} dense>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      {password && (
                        regex.test(password) ? (
                          <CheckIcon color="success" fontSize="small" />
                        ) : (
                          <CloseIcon color="error" fontSize="small" />
                        )
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        variant: "caption",
                        color: password
                          ? regex.test(password)
                            ? "success.main"
                            : "error.main"
                          : "text.secondary",
                      }}
                    />
                  </ListItem>
                ))}
              </List>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                loading={isLoading}
                disabled={!isValidForm}
                sx={{
                  mt: 2,
                  mb: 1,
                  height: 48,
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 600,
                  boxShadow: theme.shadows[2],
                  "&:hover": {
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                Reset Password
              </LoadingButton>

              <Button
                fullWidth
                onClick={() => push("/login")}
                disabled={isLoading}
                sx={{
                  textTransform: "none",
                  color: theme.palette.text.secondary,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                Back to Login
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}; 