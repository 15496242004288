import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useForm } from "@refinedev/react-hook-form";
import { HttpError } from "@refinedev/core";
import { useNotification, useSelect } from "@refinedev/core";
import { Profiles, Activities } from "../../types/schema";

interface EditActivityModalProps {
  open: boolean;
  onClose: () => void;
  activity: Activities;
  onSuccess?: () => void;
}

export const EditActivityModal: React.FC<EditActivityModalProps> = ({
  open,
  onClose,
  activity,
  onSuccess,
}) => {
  const { open: openNotification } = useNotification();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Activities>({
    refineCoreProps: {
      action: "edit",
      resource: "activities",
      id: activity.id,
      redirect: false,
      onMutationSuccess: () => {
        onClose();
        onSuccess?.();
      },
      onMutationError: (error: HttpError) => {
        openNotification?.({
          type: "error",
          message: error?.message ?? "Error editing activity",
        });
      },
    },
    defaultValues: {
      ...activity,
      due_date: activity.due_date ? new Date(activity.due_date) : null,
    },
  });

  const { options: profiles } = useSelect<Profiles>({
    resource: "profiles",
    defaultValue: activity.assigned_to_id ?? undefined,
  });

  const activityType = watch("activity_type");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form
        onSubmit={handleSubmit(() => {
          // The form submission is handled by useModalForm
        })}
      >
        <DialogTitle>Edit Activity</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              {...register("title", { required: "Title is required" })}
              label="Title"
              fullWidth
              error={!!errors.title}
              helperText={errors.title?.message as string}
            />

            <TextField
              {...register("description")}
              label="Description"
              multiline
              rows={4}
              fullWidth
            />

            <DatePicker
              label="Due Date"
              value={watch("due_date") ? new Date(watch("due_date")) : null}
              onChange={(date) => {
                if (date) {
                  setValue("due_date", date.toISOString());
                } else {
                  setValue("due_date", null);
                }
              }}
              slotProps={{
                textField: { fullWidth: true },
              }}
            />

            {activityType === "task" && (
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select {...register("priority")} label="Priority">
                    <MenuItem value="high">High</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select {...register("status")} label="Status">
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="in_progress">In Progress</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Assign To</InputLabel>
                  <Select {...register("assigned_to_id")} label="Assign To">
                    {profiles?.map((profile) => (
                      <MenuItem key={profile.value} value={profile.value}>
                        {profile.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
