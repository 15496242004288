import React, { useEffect, useMemo } from "react";
import { Box, Typography, IconButton, Tooltip, Alert, Collapse, Link, Paper, Button } from "@mui/material";
import { useGetIdentity, useInvalidate, useList } from "@refinedev/core";
import { styled } from "@mui/material/styles";
import { Refresh as RefreshIcon, Close as CloseIcon } from "@mui/icons-material";
import { MatterStatus, Tables } from "../../types/schema";
import { DashboardSkeleton } from "../../components/skeletons/DashboardSkeleton";
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import { ErrorBoundary } from "../../components/common/ErrorBoundary";
import { useActivityFilters } from "../../hooks";
import {
  DashboardStats,
  MattersCarousel,
  ActivityTimeline,
} from "../../components/dashboard";
import { MatterWithRelations } from "../../types/matter";
import { AuthUser } from "../../types";
import { theme } from "../../theme";

const DashboardContainer = styled(Box)(({ theme }) => ({
  maxWidth: "1200px",
  width: "100%",
  margin: "0 auto",
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  minHeight: "100vh",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const MainContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const WelcomeSection = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const ContentSection = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  height: '100%',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
}));

const ExtensionAlert = styled(Alert)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
  maxWidth: '400px',
  zIndex: 9999,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  borderLeft: `4px solid ${theme.palette.info.main}`,
  boxShadow: theme.shadows[3],
  backdropFilter: 'blur(8px)',
  '& .MuiAlert-message': {
    width: '100%'
  },
  '& .MuiLink-root': {
    color: theme.palette.info.main,
    fontWeight: 500,
    textDecoration: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.info.dark,
      textDecoration: 'underline',
    }
  },
  '& .MuiAlert-icon': {
    color: theme.palette.info.main
  },
  [theme.breakpoints.down('sm')]: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    left: theme.spacing(2),
    maxWidth: 'none',
  },
}));

interface DashboardStats {
  activeMattersCount: number;
  pendingTasksCount: number;
}

export const Dashboard: React.FC = () => {
  const invalidate = useInvalidate();
  const { data: currentUser } = useGetIdentity<AuthUser>();

  const {
    data: activities,
    isLoading: isLoadingActivities,
    error: activitiesError,
  } = useActivityFilters();

  const {
    data: matters,
    isLoading: isLoadingMatters,
    error: mattersError,
  } = useList<MatterWithRelations>({
    resource: "matters",
    filters: [
      {
        field: "status",
        operator: "ne",
        value: MatterStatus.closed,
      },
    ],
    queryOptions: {
      staleTime: 30000,
    },
    meta: {
      select: `
        id,
        title,
        matter_number,
        status,
        description,
        matter_type,
        is_high_priority,
        team_members:matter_team_members(
          profile:profiles!matter_team_members_profile_id_fkey(
            id,
            full_name,
            avatar_url,
            initials
          )
        ),
        activities(
          id,
          title,
          status,
          due_date,
          activity_type
        ),
        client_org:organizations!matters_client_org_id_fkey(
          id,
          name,
          logo_url
        ),
        lead_attorney:profiles!matters_lead_attorney_id_fkey(
          id,
          full_name,
          avatar_url,
          initials
        )
      `,
    },
  });

  const stats: DashboardStats = useMemo(
    () => ({
      activeMattersCount: matters?.total ?? 0,
      pendingTasksCount: activities?.length ?? 0,
    }),
    [matters?.total, activities?.length]
  );

  const error = useMemo(
    () => mattersError || activitiesError,
    [mattersError, activitiesError]
  );

  const handleRefresh = () => {
    invalidate({
      resource: "matters",
      invalidates: ["list"],
    });

    invalidate({
      resource: "activities",
      invalidates: ["list"],
    });
  };

  useEffect(() => {
    invalidate({
      resource: "matters",
      invalidates: ["list"],
    });

    invalidate({
      resource: "activities",
      invalidates: ["list"],
    });
  }, [invalidate]);

  const [showExtensionAlert, setShowExtensionAlert] = React.useState(true);

  const handleCloseAlert = () => {
    setShowExtensionAlert(false);
    localStorage.setItem('hideExtensionAlert', 'true');
  };

  if (isLoadingActivities || isLoadingMatters) {
    return (
      <DashboardContainer>
        <LoadingIndicator message="Loading your dashboard..." />
        <DashboardSkeleton />
      </DashboardContainer>
    );
  }

  if (error) {
    return (
      <ErrorBoundary>
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" color="error" gutterBottom fontWeight="600">
            Error loading dashboard
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ maxWidth: '600px', mx: 'auto', mb: 3 }}>
            {error.message}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleRefresh}
            startIcon={<RefreshIcon />}
          >
            Try Again
          </Button>
        </Box>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <DashboardContainer>
        <Collapse in={showExtensionAlert}>
          <ExtensionAlert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
                sx={{ 
                  mt: -1,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.grey[900], fontWeight: 600 }}>
              ⚖️ New: Email Timeline Integration Available!
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: theme.palette.grey[700] }}>
              While our Chrome extension undergoes bar review (yes, even extensions need legal approval!), 
              you can start extracting matter timelines from your emails today:
            </Typography>
            <Typography variant="body2" component="ol" sx={{ pl: 2, mb: 2, color: theme.palette.grey[700] }}>
              <li>Download the <Link href="/dist_chrome.zip" download>secure extension package</Link></li>
              <li>Unzip the file</li>
              <li>Visit chrome://extensions/</li>
              <li>Enable "Developer mode" (top right)</li>
              <li>Click "Load unpacked" and select the unzipped folder</li>
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.grey[700], mb: 2 }}>
              We expect Chrome Web Store approval this week - after which you'll be able to install directly from there!
            </Typography>
            <Typography variant="body2" sx={{ 
              fontStyle: 'italic',
              color: theme.palette.grey[600],
              borderTop: `1px solid ${theme.palette.grey[200]}`,
              pt: 2,
              mt: 2
            }}>
              Don't let crucial matter details get lost in your inbox - let our extension do the discovery for you! 🔍
            </Typography>
          </ExtensionAlert>
        </Collapse>

        <WelcomeSection elevation={0}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 3,
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="h3" sx={{ 
                fontWeight: 600, 
                mb: 1,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                [theme.breakpoints.down('sm')]: {
                  fontSize: '2rem',
                }
              }}>
                Welcome back, {currentUser?.full_name?.split(" ")[0] || "User"}
              </Typography>
              <Typography 
                variant="subtitle1" 
                color="text.secondary" 
                sx={{ 
                  opacity: 0.9,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                  }
                }}
              >
                Here's what's happening across your matters today.
              </Typography>
            </Box>
            <Tooltip title="Refresh dashboard">
              <IconButton 
                onClick={handleRefresh} 
                size="large"
                sx={{ 
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                    transform: 'rotate(180deg)',
                  }
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <StatsContainer>
            <DashboardStats {...stats} />
          </StatsContainer>
        </WelcomeSection>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr",
            },
            gap: 4,
            alignItems: "start",
          }}
        >
          <ContentSection>
            <MattersCarousel matters={matters?.data ?? []} />
          </ContentSection>
          <ContentSection>
            <ActivityTimeline />
          </ContentSection>
        </Box>
      </DashboardContainer>
    </ErrorBoundary>
  );
};
