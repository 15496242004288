import React from "react";
import { Box, BoxProps, Grow } from "@mui/material";

interface AnimatedContainerProps extends BoxProps {
  delay?: number;
}

export const AnimatedContainer: React.FC<AnimatedContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Grow in={true} timeout={500} style={{ transformOrigin: "0 0 0" }}>
      <Box {...props}>{children}</Box>
    </Grow>
  );
};
