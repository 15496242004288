import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Tables } from "../../types/schema";

type Matter = Tables<"matters">;

export const BreadcrumbNav: React.FC<{ matter?: Matter }> = ({ matter }) => {
  return (
    <Breadcrumbs sx={{ mb: 2 }}>
      <Link color="inherit" href="/matters">
        Matters
      </Link>
      <Typography color="text.primary">
        {matter?.title || "Loading..."}
      </Typography>
    </Breadcrumbs>
  );
};
