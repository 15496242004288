import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { Person } from "@mui/icons-material";
import { useCreate, useDelete, useList, useNotification } from "@refinedev/core";
import React, { useState } from "react";
import { TeamMemberWithProfile, Profiles, UserRole } from "../../types/schema";

interface TeamMembersProps {
  members: TeamMemberWithProfile[];
  isLoading: boolean;
  organizationId: string;
  matterId: string;
}

interface TeamMemberItemProps {
  member: TeamMemberWithProfile;
  onRemove: (memberId: string) => void;
}

const TeamMemberItem: React.FC<TeamMemberItemProps> = ({ member, onRemove }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        mb: 1,
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Person />
        <Box>
          <Typography variant="subtitle1">{member.profile.full_name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {member.role}
          </Typography>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={() => onRemove(member.profile.id)}
      >
        Remove
      </Button>
    </Box>
  );
};

interface ProfileData extends Omit<Profiles, 'role'> {
  id: string;
  full_name: string;
  role: UserRole;
}

const AddMemberModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onAdd: (userId: string) => void;
  organizationId: string;
  matterId: string;
  existingMembers: string[];
}> = ({ open, onClose, onAdd, organizationId, matterId, existingMembers }) => {
  const [selectedUser, setSelectedUser] = useState<string>("");
  const { open: openNotification } = useNotification();

  const { data, isLoading, error } = useList<ProfileData>({
    resource: "profiles",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organizationId,
      },
    ],
    meta: {
      select: "id,full_name,role,avatar_url,initials",
    },
  });

  // Filter out users that are already team members
  const availableUsers = React.useMemo(() => {
    if (!data?.data) return [];
    return data.data.filter((user) => user.id && !existingMembers.includes(user.id));
  }, [data?.data, existingMembers]);

  React.useEffect(() => {
    if (error) {
      console.error("Profile List Error:", error);
      openNotification?.({
        type: "error",
        message: "Failed to load team members",
        description: error?.message,
      });
    }
  }, [error, openNotification]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-team-member-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Add Team Member
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Select User</InputLabel>
          <Select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="Select User"
          >
            {isLoading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : error ? (
              <MenuItem disabled>Error loading users</MenuItem>
            ) : availableUsers.length === 0 ? (
              <MenuItem disabled>No available users</MenuItem>
            ) : (
              availableUsers.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.full_name} ({user.role})
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        <Box
          sx={{ mt: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              if (selectedUser) {
                onAdd(selectedUser);
                setSelectedUser(""); // Reset selection
                onClose();
              }
            }}
            disabled={!selectedUser || isLoading}
          >
            Add Member
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const TeamMembers: React.FC<TeamMembersProps> = ({
  members,
  isLoading,
  organizationId,
  matterId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: createMember } = useCreate();
  const { mutate: deleteMember } = useDelete();
  const { open: openNotification } = useNotification();

  // Get list of existing member IDs
  const existingMemberIds = React.useMemo(() => {
    return members
      .filter(member => member.profile?.id)
      .map(member => member.profile.id);
  }, [members]);

  const handleAddMember = (userId: string) => {
    if (!userId) {
      openNotification?.({
        type: "error",
        message: "Invalid user selected",
      });
      return;
    }

    createMember(
      {
        resource: "matter_team_members",
        values: {
          matter_id: matterId,
          profile_id: userId,
          assigned_at: new Date().toISOString(),
          role: "team_member",
        },
      },
      {
        onSuccess: () => {
          openNotification?.({
            type: "success",
            message: "Team member added successfully",
          });
          setIsModalOpen(false);
        },
        onError: (error) => {
          console.error("Create Error:", {
            error,
            matterId,
            userId,
          });
          openNotification?.({
            type: "error",
            message: "Failed to add team member",
            description: error?.message,
          });
        },
      }
    );
  };

  const handleRemoveMember = (memberId: string) => {
    if (!memberId || !matterId) {
      openNotification?.({
        type: "error",
        message: "Invalid member or matter ID",
      });
      return;
    }

    deleteMember(
      {
        resource: "matter_team_members",
        id: `${matterId}_${memberId}`,
        meta: {
          select: "*",
        },
      },
      {
        onSuccess: () => {
          openNotification?.({
            type: "success",
            message: "Team member removed successfully",
          });
        },
        onError: (error) => {
          console.error("Delete Error:", {
            error,
            matterId,
            memberId,
          });
          openNotification?.({
            type: "error",
            message: "Failed to remove team member",
            description: error?.message,
          });
        },
      }
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={() => setIsModalOpen(true)}
          startIcon={<Person />}
        >
          Add Team Member
        </Button>
      </Box>

      {members
        .filter(member => member.profile?.id)
        .map((member) => (
          <TeamMemberItem
            key={member.profile.id}
            member={member}
            onRemove={handleRemoveMember}
          />
        ))}

      <AddMemberModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAdd={handleAddMember}
        organizationId={organizationId}
        matterId={matterId}
        existingMembers={existingMemberIds}
      />
    </Box>
  );
};
