import React from "react";
import { Edit } from "@refinedev/mui";
import { Box, TextField, MenuItem, Typography } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Tables } from "../../types";
import { useSelect } from "@refinedev/core";
import CircularProgress from "@mui/material/CircularProgress";

type OrganizationForm = Tables<"organizations">;

export const OrganizationEdit: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useOne<OrganizationForm>({
    resource: "organizations",
    id: id as string,
  });
  const organization = data?.data;

  const {
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm<OrganizationForm>({
    refineCoreProps: {
      redirect: "list",
      successNotification: {
        message: "Organization successfully updated",
        type: "success",
      },
    },
    values: {
      id: organization?.id,
      name: organization?.name,
      org_type: organization?.org_type,
      email: organization?.main_email ?? null,
      phone: organization?.main_phone ?? null,
      address: organization?.address ?? null,
      logo_url: organization?.logo_url ?? null,
      created_at: organization?.created_at,
      updated_at: organization?.updated_at,
    },
  });

  const orgTypeOptions = [
    { value: "law_firm", label: "Law Firm" },
    { value: "client", label: "Client" },
    { value: "opposing_counsel", label: "Opposing Counsel" },
  ] as const;

  const { options: relatedMattersSelectProps } = useSelect({
    resource: "matters",
    optionLabel: "title",
    optionValue: "id",
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        autoComplete="off"
      >
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
          <Typography variant="subtitle1" gridColumn="1 / -1">
            Basic Information
          </Typography>

          <TextField
            {...register("name")}
            label="Organization Name"
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "background.paper",
              },
            }}
            fullWidth
          />

          <TextField
            select
            {...register("org_type", {
              required: "Organization type is required",
            })}
            error={!!errors.org_type}
            helperText={errors.org_type?.message?.toString()}
            label="Organization Type"
            fullWidth
          >
            {orgTypeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>

          <Typography variant="subtitle1" gridColumn="1 / -1" mt={2}>
            Contact Information
          </Typography>

          <TextField {...register("phone")} label="Phone Number" fullWidth />

          <TextField {...register("email")} label="Email Address" fullWidth />

          <Typography variant="subtitle1" gridColumn="1 / -1" mt={2}>
            Additional Details
          </Typography>

          <TextField
            {...register("address")}
            multiline
            rows={3}
            label="Physical Address"
            fullWidth
          />

          <TextField
            {...register("logo_url")}
            label="Organization Logo URL"
            fullWidth
          />

          <TextField
            {...register("related_matters")}
            select
            SelectProps={{ multiple: true }}
            {...relatedMattersSelectProps}
            label="Related Legal Matters"
            fullWidth
          />
        </Box>
      </Box>
    </Edit>
  );
};
