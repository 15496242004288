import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Chip,
  Button,
  Stack,
} from "@mui/material";
import {
  Description,
  Person,
  Event,
} from "@mui/icons-material";
import { format, differenceInDays } from "date-fns";
import { TeamMembers } from "../../components/team-members";
import { ActivityTimeline } from "../../components/activity-timeline";
import { AnimatedContainer } from "../../components/animated-container";
import { useShow, useNavigation, useGetIdentity } from "@refinedev/core";
import { useParams, Navigate } from "react-router-dom";
import { BreadcrumbNav } from "../../components/breadcrumb";
import { MatterWithRelations, MatterStatus } from "../../types/schema";
import { ErrorBoundary } from "../../components/error-boundary";
import { CreateActivityModal } from "../../components/create-activity-modal";
import { MatterShowSkeleton } from "./components/MatterShowSkeleton";
import { MatterHeader } from "./components/MatterHeader";
import { EmptyState } from "../../components/empty-states";
import { cardStyles, getStatusChipStyles } from "./styles/MatterShow.styles";
import { useActivityModal } from "../../hooks/useActivityModal";
import { separateActivities } from "../../utils/matterActivities";
import { MATTER_QUERY_OPTIONS, MATTER_SELECT_QUERY } from "../../constants/queryConfig";
import { AuthUser } from "../../types";

// Memoize the timeline component for better performance
const MemoizedActivityTimeline = React.memo(ActivityTimeline);

export const MatterShow: React.FC = () => {
  const { id } = useParams();
  const { list } = useNavigation();
  const { data: identity } = useGetIdentity<AuthUser>();
  
  // Add detailed error logging
  React.useEffect(() => {
    const handleError = (error: any) => {
      console.error('Matter Show Error:', {
        error,
        message: error?.message,
        details: error?.details,
        hint: error?.hint,
        code: error?.code,
        stack: error?.stack,
      });
    };

    window.addEventListener('unhandledrejection', (event) => {
      handleError(event.reason);
    });

    return () => {
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

  // Redirect to matters list if no ID is provided
  if (!id) {
    return <Navigate to="/matters" replace />;
  }

  const { isOpen: createActivityModalOpen, openModal: handleCreateActivity, closeModal: handleCloseActivityModal, activityType } = useActivityModal();

  const {
    query: { data: matterData, isLoading, refetch: refetchMatter, error },
  } = useShow<MatterWithRelations>({
    resource: "matters",
    id,
    meta: {
      select: MATTER_SELECT_QUERY,
      order: {
        "activities.created_at": "desc"
      }
    },
    queryOptions: {
      ...MATTER_QUERY_OPTIONS,
      onError: (error) => {
        console.error('Matter Query Error:', {
          error,
          message: error?.message,
          details: error?.details,
          hint: error?.hint,
          code: error?.code,
          stack: error?.stack,
        });
      },
    },
  });

  const matter = matterData?.data;
  
  // Add debug logging
  React.useEffect(() => {
    console.log("Matter Activities Debug:", {
      matterId: id,
      hasActivities: Boolean(matter?.activities),
      activitiesCount: matter?.activities?.length,
      activities: matter?.activities
    });
  }, [matter?.activities, id]);

  const { timelineActivities, tasks } = React.useMemo(
    () => {
      const result = separateActivities(matter?.activities);
      console.log("Separated Activities:", {
        timelineActivities: result.timelineActivities,
        tasks: result.tasks,
        totalTimelineActivities: result.timelineActivities?.length,
        totalTasks: result.tasks?.length
      });
      return result;
    },
    [matter?.activities]
  );

  const handleActivitySuccess = React.useCallback(async () => {
    try {
      await refetchMatter();
      handleCloseActivityModal();
    } catch (error) {
      console.error("Error refetching matter:", error);
    }
  }, [refetchMatter, handleCloseActivityModal]);

  // Add debug logging for activities data
  React.useEffect(() => {
    if (matter?.activities) {
      console.log("Activities Debug:", {
        activities: matter.activities,
        hasUndefinedAssignees: matter.activities.some(a => !a.assigned_to_id),
        assigneeIds: matter.activities.map(a => a.assigned_to_id),
      });
    }
  }, [matter?.activities]);

  // Add debug logging for participants mapping
  const participants = React.useMemo(() => {
    if (!timelineActivities) return [];
    
    return timelineActivities
      .filter(activity => {
        // Add detailed logging for each activity
        console.log("Activity Debug:", {
          id: activity.id,
          assigned_to_id: activity.assigned_to_id,
          assigned_to: activity.assigned_to,
          has_valid_assignee: Boolean(activity.assigned_to_id && activity.assigned_to?.id)
        });
        
        // Only include activities with valid assignees
        return Boolean(activity.assigned_to_id && 
          activity.assigned_to?.id && 
          activity.assigned_to?.id === activity.assigned_to_id);
      })
      .map(activity => {
        const assignee = activity.assigned_to;
        return {
          activity_id: activity.id,
          profile: {
            id: assignee.id,
            full_name: assignee.full_name || 'Unassigned',
            avatar_url: assignee.avatar_url,
            initials: assignee.initials || 'UA',
          },
        };
      });
  }, [timelineActivities]);

  if (isLoading) return <MatterShowSkeleton />;
  
  if (error) {
    return (
      <EmptyState
        title="Error Loading Matter"
        message={error?.message || "An error occurred while loading the matter details"}
        action={{
          label: "Return to Matters",
          onClick: () => list("matters"),
        }}
      />
    );
  }
  
  if (!matter) {
    return (
      <EmptyState
        title="Matter Not Found"
        message="The requested matter could not be found"
        action={{
          label: "Return to Matters",
          onClick: () => list("matters"),
        }}
      />
    );
  }

  return (
    <ErrorBoundary>
      <Box sx={{ p: { xs: 2, sm: 3 }, bgcolor: "grey.50", minHeight: "100vh" }}>
        <BreadcrumbNav matter={matter} />
        <MatterHeader
          matter={matter}
          leadAttorney={matter.lead_attorney}
        />

        <Grid container spacing={3}>
          {/* Matter Overview and Team */}
          <Grid item xs={12} lg={4}>
            <Stack spacing={3}>
              {/* Quick Overview Card */}
              <AnimatedContainer>
                <Card elevation={0} sx={cardStyles.card}>
                  <CardHeader
                    sx={cardStyles.header}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <Description color="primary" sx={{ fontSize: 24 }} />
                        <Typography variant="h6">Overview</Typography>
                      </Stack>
                    }
                  />
                  <CardContent sx={cardStyles.content}>
                    <Stack spacing={2}>
                      <Box>
                        <Typography sx={cardStyles.labelText}>Status</Typography>
                        <Chip
                          label={matter.status?.replace("_", " ") || "intake"}
                          sx={getStatusChipStyles(matter.status as MatterStatus)}
                          size="small"
                        />
                      </Box>
                      {matter.open_date && (
                        <Box>
                          <Typography sx={cardStyles.labelText}>Timeline</Typography>
                          <Typography sx={cardStyles.valueText}>
                            Opened {format(new Date(matter.open_date), "MMM d, yyyy")}
                            <Typography variant="caption" color="text.secondary" display="block">
                              {differenceInDays(new Date(), new Date(matter.open_date))} days ago
                            </Typography>
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </AnimatedContainer>

              {/* Team Members */}
              <AnimatedContainer>
                <Card elevation={0} sx={cardStyles.card}>
                  <CardHeader
                    sx={cardStyles.header}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <Person color="primary" sx={{ fontSize: 24 }} />
                        <Typography variant="h6">Team</Typography>
                      </Stack>
                    }
                  />
                  <CardContent sx={cardStyles.content}>
                    <TeamMembers
                      members={matter.team_members || []}
                      matterId={id}
                      isLoading={isLoading}
                      organizationId={identity?.organizationId || ''}
                    />
                  </CardContent>
                </Card>
              </AnimatedContainer>
            </Stack>
          </Grid>

          {/* Activities and Tasks */}
          <Grid item xs={12} lg={8}>
            <Stack spacing={3}>
              {/* Timeline */}
              <AnimatedContainer>
                <Card elevation={0} sx={cardStyles.card}>
                  <CardHeader
                    sx={cardStyles.header}
                    title={
                      <Stack direction="row" alignItems="center" spacing={1.5}>
                        <Event color="primary" sx={{ fontSize: 24 }} />
                        <Typography variant="h6">Activities</Typography>
                      </Stack>
                    }
                    action={
                      <Button
                        variant="contained"
                        onClick={() => handleCreateActivity()}
                        startIcon={<Event />}
                        sx={cardStyles.button}
                      >
                        Add Activity
                      </Button>
                    }
                  />
                  <CardContent sx={cardStyles.content}>
                    {timelineActivities && timelineActivities.length > 0 ? (
                      <MemoizedActivityTimeline
                        activities={timelineActivities}
                        onActivityUpdate={refetchMatter}
                        isLoading={isLoading}
                        participants={participants}
                        sx={{
                          "& .MuiTimelineItem-root": {
                            "&:before": {
                              display: "none",
                            },
                          },
                          "& .MuiTimelineDot-root": {
                            margin: 0,
                            boxShadow: "none",
                            p: 1,
                          },
                          "& .MuiTimelineConnector-root": {
                            width: "2px",
                            backgroundColor: "divider",
                          },
                        }}
                      />
                    ) : (
                      <EmptyState
                        title="No Activities"
                        message="There are no activities for this matter yet"
                        action={{
                          label: "Add Activity",
                          onClick: () => handleCreateActivity(),
                        }}
                      />
                    )}
                  </CardContent>
                </Card>
              </AnimatedContainer>
            </Stack>
          </Grid>
        </Grid>

        <CreateActivityModal
          open={createActivityModalOpen}
          onClose={handleCloseActivityModal}
          matterId={id}
          onSuccess={handleActivitySuccess}
          defaultActivityType={"task"}
          organizationId={identity?.organizationId}
        />
      </Box>
    </ErrorBoundary>
  );
};
