import React from "react";
import { Create } from "@refinedev/mui";
import {
  Box,
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Alert,
  FormHelperText,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { useNotification, useGetIdentity, useList } from "@refinedev/core";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { Database, Tables, AuthUser } from "../../types";
import { BaseSyntheticEvent } from "react";

// Define interface for form data
interface IUserCreateForm {
  email: string;
  full_name: string;
  organization_id: string;
  role: Database["public"]["Enums"]["user_role"];
  is_active: boolean;
  send_invitation: boolean;
  phone: string | null;
  timezone: string;
  out_of_office: boolean;
  out_of_office_until: string | null;
}

interface IIdentity {
  id: string;
  organization_id: string;
}

interface IOrganizationRelationship {
  org_id: string;
  related_org_id: string;
  relationship_type: string;
}

interface IOrganization {
  id: string;
  name: string;
}

export const UserCreate: React.FC = () => {
  const { open } = useNotification();
  const { data: identity, isLoading: isIdentityLoading } =
    useGetIdentity<AuthUser>();

  const {
    saveButtonProps,
    register: registerForm,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
  } = useForm<IUserCreateForm>({
    defaultValues: {
      is_active: false,
      role: "external_contact",
      send_invitation: true,
      organization_id: identity?.organizationId ?? "",
    },
  });

  // Fetch related organizations
  const {
    data: relationshipsData,
    isLoading: isRelationshipsLoading,
    error: relationshipsError,
  } = useList<IOrganizationRelationship>({
    resource: "organization_relationships",
    filters: [
      {
        field: "org_id",
        operator: "eq",
        value: identity?.organizationId ?? "",
      },
    ],
  });

  // Fetch organization details for the related organizations
  const {
    data: organizationsData,
    isLoading: isOrgsLoading,
    error: orgsError,
  } = useList<IOrganization>({
    resource: "organizations",
    filters: [
      {
        field: "id",
        operator: "in",
        value: [
          identity?.organizationId,
          ...(relationshipsData?.data?.map((rel) => rel.related_org_id) || []),
        ],
      },
    ],
  });

  const isLoading =
    isIdentityLoading || isRelationshipsLoading || isOrgsLoading;
  const error = relationshipsError || orgsError;

  // Fix the error message type issue
  const getErrorMessage = (error: unknown): string => {
    if (error instanceof Error) return error.message;
    if (typeof error === "string") return error;
    return "Unknown error occurred";
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading organizations: {getErrorMessage(error)}
      </Alert>
    );
  }

  // Type-safe form submission
  const onSubmit: SubmitHandler<IUserCreateForm> = async (data) => {
    try {
      const { send_invitation, ...profileData } = data;

      const profileCreateData: Partial<Tables<"profiles">> = {
        ...profileData,
        auth_user_id: null,
        initials: profileData.full_name
          .split(" ")
          .map((n) => n[0])
          .join("")
          .toUpperCase(),
        date_joined: null,
        last_login: null,
        metadata: null,
        contact_preferences: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      // Create minimal synthetic event with our data
      const event = {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        preventDefault: () => {},
        target: { value: profileCreateData },
        currentTarget: { value: profileCreateData },
      } as BaseSyntheticEvent;

      await saveButtonProps.onClick?.(event);

      if (send_invitation) {
        console.log("Sending invitation email to:", data.email);
      }

      open?.({
        type: "success",
        message: send_invitation
          ? "User invited successfully"
          : "User profile created successfully",
      });
    } catch (error) {
      open?.({
        type: "error",
        message: getErrorMessage(error),
      });
    }
  };

  const roleOptions: Database["public"]["Enums"]["user_role"][] = [
    "admin",
    "partner",
    "associate",
    "paralegal",
    "support_staff",
    "external_contact",
  ];

  const selectedOrgId = watch("organization_id");
  // const selectedOrg = organizationsData?.data?.find(
  //   (org) => org.id === selectedOrgId
  // );

  // Only allow admin role for users in the same organization
  const filteredRoleOptions =
    selectedOrgId === identity?.organizationId
      ? roleOptions
      : roleOptions.filter((role) => role !== "admin");

  // Fix the FormHelperText error message type issue
  const getFieldErrorMessage = (fieldError: unknown): string => {
    if (typeof fieldError === "string") return fieldError;
    if (
      fieldError &&
      typeof fieldError === "object" &&
      "message" in fieldError
    ) {
      return String(fieldError.message);
    }
    return "Invalid value";
  };

  return (
    <Create
      title="Invite User"
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(onSubmit as SubmitHandler<FieldValues>),
      }}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        autoComplete="off"
      >
        <FormControl fullWidth error={!!errors.organization_id}>
          <InputLabel>Organization</InputLabel>
          <Select
            {...registerForm("organization_id", {
              required: "Organization is required",
            })}
            label="Organization"
            disabled={
              isRelationshipsLoading ||
              isOrgsLoading ||
              organizationsData?.data?.length === 1
            }
          >
            {isOrgsLoading ? (
              <MenuItem disabled>Loading organizations...</MenuItem>
            ) : organizationsData?.data?.length === 0 ? (
              <MenuItem disabled>No organizations available</MenuItem>
            ) : (
              organizationsData?.data?.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}{" "}
                  {org.id === identity?.organizationId && "(Current)"}
                </MenuItem>
              ))
            )}
          </Select>
          {errors.organization_id && (
            <FormHelperText error>
              {getFieldErrorMessage(errors.organization_id.message)}
            </FormHelperText>
          )}
          <FormHelperText>
            Select the organization this user will belong to
          </FormHelperText>
        </FormControl>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
          <TextField
            {...registerForm("full_name", {
              required: "Full name is required",
            })}
            error={!!errors.full_name}
            helperText={errors.full_name?.message?.toString()}
            label="Full Name"
            fullWidth
          />

          <TextField
            {...registerForm("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message?.toString()}
            label="Email"
            fullWidth
          />

          <TextField
            {...registerForm("phone")}
            error={!!errors.phone}
            helperText={errors.phone?.message?.toString()}
            label="Phone"
            fullWidth
          />

          <TextField
            {...registerForm("timezone")}
            defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
            label="Timezone"
            fullWidth
          />

          <TextField
            select
            {...registerForm("role", {
              required: "Role is required",
            })}
            error={!!errors.role}
            helperText={errors.role?.message?.toString()}
            label="Role"
            fullWidth
          >
            {filteredRoleOptions.map((role) => (
              <MenuItem key={role} value={role}>
                {role
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                onChange={(e) => setValue("send_invitation", e.target.checked)}
              />
            }
            label="Send Invitation Email"
          />

          <FormControlLabel
            control={
              <Switch
                onChange={(e) => setValue("is_active", e.target.checked)}
              />
            }
            label="Active"
          />

          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setValue("out_of_office", e.target.checked);
                  if (!e.target.checked) {
                    setValue("out_of_office_until", null);
                  }
                }}
              />
            }
            label="Out of Office"
          />

          <TextField
            type="date"
            {...registerForm("out_of_office_until")}
            label="Out of Office Until"
            InputLabelProps={{ shrink: true }}
            disabled={!watch("out_of_office")}
            fullWidth
          />
        </Box>
      </Box>
    </Create>
  );
};
