import React, { useState, useEffect } from "react";
import { useOne, useUpdate, useGetIdentity, useNotification, HttpError } from "@refinedev/core";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Avatar,
  Grid,
  Chip,
  CircularProgress,
  FormControlLabel,
  Switch,
  Divider,
  Paper,
  Container,
  Stack,
  Alert,
  Tab,
  Tabs,
  Collapse,
} from "@mui/material";
import { useForm, UseFormReturnType } from "@refinedev/react-hook-form";
import { LoadingButton } from "@mui/lab";
import { supabaseClient } from "../../utility";
import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MetadataEditor } from "../../components/metadata-editor";
import { Tables } from "../../types/schema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { AuthUser } from "../../types";

const DEBUG = false;

// Types
type ProfileFormType = Tables<"profiles"> & {
  currentPassword?: string;
  newPassword?: string;
};

type OrganizationFormType = Tables<"organizations">;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

// Add after imports, before component
const profileFormSchema = z.object({
  full_name: z.string().min(1, "Full name is required"),
  email: z.string().email("Invalid email address"),
  phone: z.string().optional(),
  timezone: z.string(),
  initials: z.string().optional(),
  out_of_office: z.boolean(),
  out_of_office_until: z.string().nullable(),
  metadata: z.record(z.unknown()).nullable().optional(),
  contact_preferences: z.record(z.unknown()).nullable().optional(),
  is_active: z.boolean().nullable().optional(),
  currentPassword: z.string().optional(),
  newPassword: z.string().min(8, "Password must be at least 8 characters").optional(),
});

const organizationFormSchema = z.object({
  name: z.string().min(1, "Organization name is required"),
  main_email: z.string().email("Invalid email address").optional(),
  main_phone: z.string().optional(),
  address: z.string().optional(),
  website: z.string().url("Invalid website URL").optional(),
  metadata: z.record(z.unknown()).optional(),
});

type ProfileFormSchema = z.infer<typeof profileFormSchema>;
type OrganizationFormSchema = z.infer<typeof organizationFormSchema>;

// Component
export const SettingsPage: React.FC = () => {
  // State
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);


  // State for collapsible sections
  const [expandedSections, setExpandedSections] = useState<{
    profileMetadata: boolean;
    contactPreferences: boolean;
    organizationMetadata: boolean;
  }>({
    profileMetadata: false,
    contactPreferences: false,
    organizationMetadata: false,
  });

  const toggleSection = (section: keyof typeof expandedSections) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Hooks
  const { data: identity } = useGetIdentity<AuthUser>();
  const profileId = identity?.profileId;

  // Profile Data
  const { data: profileData, isLoading } = useOne<Tables<"profiles">>({
    resource: "profiles",
    id: profileId || "",
    queryOptions: { enabled: !!profileId },
  });

  // Organization Data
  const { data: orgData } = useOne<
    Tables<"organizations">
  >({
    resource: "organizations",
    id: profileData?.data?.organization_id || "",
    queryOptions: { enabled: !!profileData?.data?.organization_id },
  });

  // Mutations
  const { mutate, isLoading: isMutating } = useUpdate();
  const { mutateAsync: mutateOrg, isLoading: isMutatingOrg } = useUpdate();

  // Profile Form
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ProfileFormSchema>({
      resolver: zodResolver(profileFormSchema),
      defaultValues: {
        full_name: "",
        email: "",
        phone: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        initials: "",
        out_of_office: false,
        out_of_office_until: null,
        metadata: {},
        contact_preferences: {},
        is_active: null,
      },
    }) as unknown as UseFormReturn<ProfileFormSchema>;

  // Organization Form
  const {
    register: registerOrg,
    handleSubmit: handleSubmitOrg,
    setValue: setValueOrg,
    watch: watchOrg,
    formState: { errors: orgErrors, isSubmitting: isSubmittingOrg },
    reset: resetOrg,
  } = useForm<OrganizationFormSchema>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues: {
      name: "",
      main_email: "",
      main_phone: "",
      address: "",
      website: "",
      metadata: {},
    },
  }) as UseFormReturnType<OrganizationFormSchema>;

  // Effects
  useEffect(() => {
    if (profileData?.data) {
      reset({
        full_name: profileData.data.full_name || "",
        email: profileData.data.email || "",
        phone: profileData.data.phone || undefined,
        timezone: profileData.data.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        initials: profileData.data.initials || undefined,
        out_of_office: profileData.data.out_of_office || false,
        out_of_office_until: profileData.data.out_of_office_until || null,
        metadata: profileData.data.metadata as Record<string, unknown> || {},
        contact_preferences: profileData.data.contact_preferences as Record<string, unknown> || {},
        is_active: profileData.data.is_active || false,
      });
    }
  }, [profileData?.data, reset]);

  useEffect(() => {
    if (orgData?.data) {
      resetOrg(orgData.data);
    }
  }, [orgData?.data, resetOrg]);

  // Handlers
  const { open } = useNotification();

  const onSubmit: SubmitHandler<ProfileFormSchema> = async (data) => {
    try {
      await mutate({
        resource: "profiles",
        id: profileId || "",
        values: data,
      });

      open?.({
        message: "Profile updated successfully",
        type: "success",
      });
    } catch (error: unknown) {
      open?.({
        message: error instanceof Error ? error.message : "Error updating profile",
        type: "error",
      });
    }
  };

  const onSubmitOrg = async (data: OrganizationFormSchema) => {
    try {
      await mutateOrg({
        resource: "organizations",
        id: profileData?.data?.organization_id || "",
        values: data,
      });

      open?.({
        message: "Organization updated successfully",
        type: "success",
      });
    } catch (error: unknown) {
      open?.({
        message: error instanceof Error ? error.message : "Error updating organization",
        type: "error",
      });
    }
  };

  const handlePasswordChange = async (
    currentPassword: string,
    newPassword: string
  ) => {
    try {
      const { error } = await supabaseClient.auth.updateUser({
        password: newPassword,
      });
      if (error) throw error;
      setShowPasswordChange(false);
      setPasswordError(null);
    } catch (error) {
      setPasswordError(
        error instanceof Error
          ? (error.message as string as string)
          : "An error occurred"
      );
    }
  };


  // Guards
  const renderLoader = () => (
    <Box display="flex" justifyContent="center" p={4}>
      <CircularProgress />
    </Box>
  );

  if (isLoading) {
    return renderLoader();
  }

  const profile = profileData?.data;
  if (!profile) {
    return (
      <Box p={4} textAlign="center">
        <Typography variant="h6" color="error">
          Profile not found
        </Typography>
      </Box>
    );
  }

  const canManageOrg = profile.role === "admin" || DEBUG;

  // Render
  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Grid container spacing={4}>
          {/* Profile Summary Card */}
          <Grid item xs={12} md={4}>
            <Card elevation={3}>
              <CardContent>
                <Stack spacing={3} alignItems="center">
                  <Avatar
                    src={profile.avatar_url || undefined}
                    sx={{
                      width: 120,
                      height: 120,
                      fontSize: "2.5rem",
                      bgcolor: "primary.main",
                      border: 3,
                      borderColor: "primary.light",
                    }}
                  >
                    {profile.initials || profile.full_name?.charAt(0)}
                  </Avatar>
                  <Typography variant="h5" fontWeight="500">
                    {profile.full_name}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={profile.role.replace(/_/g, " ").toUpperCase()}
                      color="primary"
                      variant="outlined"
                    />
                    <Chip
                      label={profile.is_active ? "Active" : "Inactive"}
                      color={profile.is_active ? "success" : "error"}
                    />
                  </Stack>
                  {profile.last_login && (
                    <Typography variant="body2" color="text.secondary">
                      Last login:{" "}
                      {new Date(profile.last_login).toLocaleString()}
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          {/* Settings Tabs */}
          <Grid item xs={12} md={8}>
            <Paper elevation={3}>
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                aria-label="settings tabs"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab icon={<PersonIcon />} label="Profile" />
                <Tab icon={<NotificationsIcon />} label="Preferences" />
                {canManageOrg && (
                  <Tab icon={<BusinessIcon />} label="Organization" />
                )}
              </Tabs>

              {/* Profile Settings */}
              <TabPanel value={activeTab} index={0}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <Typography variant="h6" color="primary">
                      Personal Information
                    </Typography>
                    <TextField
                      {...register("full_name")}
                      label="Full Name"
                      fullWidth
                      error={!!errors.full_name}
                      helperText={errors.full_name?.message as string}
                    />
                    <TextField
                      {...register("initials")}
                      label="Initials"
                      fullWidth
                      error={!!errors.initials}
                      helperText={errors.initials?.message as string}
                    />
                    <TextField
                      {...register("email")}
                      label="Email"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message as string}
                    />
                    <TextField
                      {...register("phone")}
                      label="Phone"
                      fullWidth
                      error={!!errors.phone}
                      helperText={errors.phone?.message as string}
                    />

                    <Divider />

                    <Typography variant="h6" color="primary">
                      Availability Settings
                    </Typography>
                    <TextField
                      {...register("timezone")}
                      label="Timezone"
                      fullWidth
                      error={!!errors.timezone}
                      helperText={errors.timezone?.message as string}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          {...register("out_of_office")}
                          checked={watch("out_of_office")}
                        />
                      }
                      label="Out of Office"
                    />
                    {watch("out_of_office") && (
                      <TextField
                        {...register("out_of_office_until")}
                        type="date"
                        label="Out Until"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}

                    <Divider />

                    {/* Contact Preferences Section */}
                    <Box>
                      <Button
                        onClick={() => toggleSection("contactPreferences")}
                        endIcon={
                          expandedSections.contactPreferences ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        fullWidth
                        sx={{ justifyContent: "space-between", py: 1 }}
                      >
                        <Typography variant="h6" color="primary">
                          Contact Preferences
                        </Typography>
                      </Button>
                      <Collapse in={expandedSections.contactPreferences}>
                        <Box
                          sx={{
                            mt: 2,
                            p: 2,
                            bgcolor: "background.default",
                            borderRadius: 1,
                          }}
                        >
                          <MetadataEditor
                            value={watch("contact_preferences") || {}}
                            onChange={(prefs) =>
                              setValue("contact_preferences", prefs)
                            }
                          />
                        </Box>
                      </Collapse>
                    </Box>

                    <Divider />

                    {/* Profile Metadata Section */}
                    <Box>
                      <Button
                        onClick={() => toggleSection("profileMetadata")}
                        endIcon={
                          expandedSections.profileMetadata ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        fullWidth
                        sx={{ justifyContent: "space-between", py: 1 }}
                      >
                        <Typography variant="h6" color="primary">
                          Additional Information
                        </Typography>
                      </Button>
                      <Collapse in={expandedSections.profileMetadata}>
                        <Box
                          sx={{
                            mt: 2,
                            p: 2,
                            bgcolor: "background.default",
                            borderRadius: 1,
                          }}
                        >
                          <MetadataEditor
                            value={watch("metadata") || {}}
                            onChange={(metadata) =>
                              setValue("metadata", metadata)
                            }
                          />
                        </Box>
                      </Collapse>
                    </Box>

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting || isMutating}
                      startIcon={<SaveIcon />}
                    >
                      Save Profile
                    </LoadingButton>
                  </Stack>
                </form>
              </TabPanel>

              {/* Preferences Tab */}
              <TabPanel value={activeTab} index={1}>
                <Stack spacing={3}>
                  <Typography variant="h6" color="primary">
                    Account Status
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        {...register("is_active")}
                        checked={!!watch("is_active")}
                      />
                    }
                    label="Active Account"
                  />

                  <Typography variant="h6" color="primary">
                    Security
                  </Typography>
                  <Button
                    startIcon={<LockIcon />}
                    variant="outlined"
                    onClick={() => setShowPasswordChange(true)}
                  >
                    Change Password
                  </Button>
                  {passwordError && (
                    <Alert severity="error">{passwordError}</Alert>
                  )}
                  {showPasswordChange && (
                    <Stack spacing={2}>
                      <TextField
                        type="password"
                        label="Current Password"
                        fullWidth
                        {...register("currentPassword")}
                      />
                      <TextField
                        type="password"
                        label="New Password"
                        fullWidth
                        {...register("newPassword")}
                      />
                      <LoadingButton
                        onClick={() =>
                          handlePasswordChange(
                            watch("currentPassword") || "",
                            watch("newPassword") || ""
                          )
                        }
                        loading={isSubmitting}
                        variant="contained"
                      >
                        Update Password
                      </LoadingButton>
                    </Stack>
                  )}
                </Stack>
              </TabPanel>

              {/* Organization Settings */}
              {canManageOrg && (
                <TabPanel value={activeTab} index={2}>
                  {/* @ts-ignore */}
                  <form onSubmit={handleSubmitOrg(onSubmitOrg)}>
                    <Stack spacing={3}>
                      <Typography variant="h6" color="primary">
                        Basic Information
                      </Typography>
                      <TextField
                        {...registerOrg("name")}
                        label="Organization Name"
                        fullWidth
                        error={!!orgErrors.name}
                        helperText={orgErrors.name?.message as string}
                      />
                      <TextField
                        {...registerOrg("main_email")}
                        label="Organization Email"
                        fullWidth
                        error={!!orgErrors.main_email}
                        helperText={orgErrors.main_email?.message as string}
                      />
                      <TextField
                        {...registerOrg("main_phone")}
                        label="Organization Phone"
                        fullWidth
                        error={!!orgErrors.main_phone}
                        helperText={orgErrors.main_phone?.message as string}
                      />

                      <Divider />

                      <Typography variant="h6" color="primary">
                        Location & Contact
                      </Typography>
                      <TextField
                        {...registerOrg("address")}
                        label="Address"
                        fullWidth
                        multiline
                        rows={3}
                        error={!!orgErrors.address}
                        helperText={orgErrors.address?.message as string}
                      />
                      <TextField
                        {...registerOrg("website")}
                        label="Website"
                        fullWidth
                        error={!!orgErrors.website}
                        helperText={orgErrors.website?.message as string}
                      />
                      <TextField
                        {...registerOrg("logo_url")}
                        label="Logo URL"
                        fullWidth
                        error={!!orgErrors.logo_url}
                        helperText={orgErrors.logo_url?.message as string}
                      />

                      <Divider />

                      {/* Organization Metadata Section */}
                      <Box>
                        <Button
                          onClick={() => toggleSection("organizationMetadata")}
                          endIcon={
                            expandedSections.organizationMetadata ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                          fullWidth
                          sx={{ justifyContent: "space-between", py: 1 }}
                        >
                          <Typography variant="h6" color="primary">
                            Additional Information
                          </Typography>
                        </Button>
                        <Collapse in={expandedSections.organizationMetadata}>
                          <Box
                            sx={{
                              mt: 2,
                              p: 2,
                              bgcolor: "background.default",
                              borderRadius: 1,
                            }}
                          >
                            <MetadataEditor
                              value={watchOrg("metadata") || {}}
                              onChange={(metadata) =>
                                setValueOrg("metadata", metadata)
                              }
                            />
                          </Box>
                        </Collapse>
                      </Box>

                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmittingOrg || isMutatingOrg}
                        startIcon={<SaveIcon />}
                      >
                        Save Organization
                      </LoadingButton>
                    </Stack>
                  </form>
                </TabPanel>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
