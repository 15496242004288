export type Json =
  | string
  | number
  | boolean
  | null
  | { [key: string]: Json | undefined }
  | Json[];

export type Database = {
  public: {
    Tables: {
      activities: {
        Row: {
          activity_type: Database["public"]["Enums"]["activity_type"];
          assigned_to_id: string | null;
          completed_at: string | null;
          created_at: string | null;
          created_by_id: string;
          description: string | null;
          due_date: string | null;
          email_hash: string | null;
          id: string;
          matter_id: string;
          metadata: Json | null;
          parent_activity_id: string | null;
          priority: Database["public"]["Enums"]["activity_priority"] | null;
          status: string;
          title: string;
          updated_at: string | null;
        };
        Insert: {
          activity_type: Database["public"]["Enums"]["activity_type"];
          assigned_to_id?: string | null;
          completed_at?: string | null;
          created_at?: string | null;
          created_by_id: string;
          description?: string | null;
          due_date?: string | null;
          email_hash?: string | null;
          id?: string;
          matter_id: string;
          metadata?: Json | null;
          parent_activity_id?: string | null;
          priority?: Database["public"]["Enums"]["activity_priority"] | null;
          status?: string;
          title: string;
          updated_at?: string | null;
        };
        Update: {
          activity_type?: Database["public"]["Enums"]["activity_type"];
          assigned_to_id?: string | null;
          completed_at?: string | null;
          created_at?: string | null;
          created_by_id?: string;
          description?: string | null;
          due_date?: string | null;
          email_hash?: string | null;
          id?: string;
          matter_id?: string;
          metadata?: Json | null;
          parent_activity_id?: string | null;
          priority?: Database["public"]["Enums"]["activity_priority"] | null;
          status?: string;
          title?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "activities_assigned_to_id_fkey";
            columns: ["assigned_to_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activities_created_by_id_fkey";
            columns: ["created_by_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activities_matter_id_fkey";
            columns: ["matter_id"];
            isOneToOne: false;
            referencedRelation: "matters";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activities_parent_activity_id_fkey";
            columns: ["parent_activity_id"];
            isOneToOne: false;
            referencedRelation: "activities";
            referencedColumns: ["id"];
          }
        ];
      };
      activity_comments: {
        Row: {
          activity_id: string | null;
          comment: string;
          created_at: string | null;
          id: string;
          parent_comment_id: string | null;
          profile_id: string;
          updated_at: string | null;
        };
        Insert: {
          activity_id?: string | null;
          comment: string;
          created_at?: string | null;
          id?: string;
          parent_comment_id?: string | null;
          profile_id: string;
          updated_at?: string | null;
        };
        Update: {
          activity_id?: string | null;
          comment?: string;
          created_at?: string | null;
          id?: string;
          parent_comment_id?: string | null;
          profile_id?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "activity_comments_activity_id_fkey";
            columns: ["activity_id"];
            isOneToOne: false;
            referencedRelation: "activities";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activity_comments_parent_comment_id_fkey";
            columns: ["parent_comment_id"];
            isOneToOne: false;
            referencedRelation: "activity_comments";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activity_comments_profile_id_fkey";
            columns: ["profile_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      activity_participants: {
        Row: {
          activity_id: string;
          profile_id: string;
        };
        Insert: {
          activity_id: string;
          profile_id: string;
        };
        Update: {
          activity_id?: string;
          profile_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: "activity_participants_activity_id_fkey";
            columns: ["activity_id"];
            isOneToOne: false;
            referencedRelation: "activities";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "activity_participants_profile_id_fkey";
            columns: ["profile_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      approvals: {
        Row: {
          approver_id: string | null;
          comments: string | null;
          created_at: string | null;
          id: string;
          matter_id: string | null;
          requested_by_id: string | null;
          status: string | null;
          updated_at: string | null;
        };
        Insert: {
          approver_id?: string | null;
          comments?: string | null;
          created_at?: string | null;
          id?: string;
          matter_id?: string | null;
          requested_by_id?: string | null;
          status?: string | null;
          updated_at?: string | null;
        };
        Update: {
          approver_id?: string | null;
          comments?: string | null;
          created_at?: string | null;
          id?: string;
          matter_id?: string | null;
          requested_by_id?: string | null;
          status?: string | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "approvals_approver_id_fkey";
            columns: ["approver_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "approvals_matter_id_fkey";
            columns: ["matter_id"];
            isOneToOne: false;
            referencedRelation: "matters";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "approvals_requested_by_id_fkey";
            columns: ["requested_by_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      business_units: {
        Row: {
          cost_center: string | null;
          created_at: string | null;
          id: string;
          name: string;
          parent_unit_id: string | null;
          primary_contact_id: string | null;
          updated_at: string | null;
        };
        Insert: {
          cost_center?: string | null;
          created_at?: string | null;
          id?: string;
          name: string;
          parent_unit_id?: string | null;
          primary_contact_id?: string | null;
          updated_at?: string | null;
        };
        Update: {
          cost_center?: string | null;
          created_at?: string | null;
          id?: string;
          name?: string;
          parent_unit_id?: string | null;
          primary_contact_id?: string | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "business_units_parent_unit_id_fkey";
            columns: ["parent_unit_id"];
            isOneToOne: false;
            referencedRelation: "business_units";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "business_units_primary_contact_id_fkey";
            columns: ["primary_contact_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      calendar_events: {
        Row: {
          all_day: boolean | null;
          created_at: string | null;
          created_by_id: string;
          description: string | null;
          end_date: string | null;
          event_type: string | null;
          id: string;
          location: string | null;
          matter_id: string | null;
          metadata: Json | null;
          start_date: string;
          title: string;
          updated_at: string | null;
          virtual_meeting_url: string | null;
        };
        Insert: {
          all_day?: boolean | null;
          created_at?: string | null;
          created_by_id: string;
          description?: string | null;
          end_date?: string | null;
          event_type?: string | null;
          id?: string;
          location?: string | null;
          matter_id?: string | null;
          metadata?: Json | null;
          start_date: string;
          title: string;
          updated_at?: string | null;
          virtual_meeting_url?: string | null;
        };
        Update: {
          all_day?: boolean | null;
          created_at?: string | null;
          created_by_id?: string;
          description?: string | null;
          end_date?: string | null;
          event_type?: string | null;
          id?: string;
          location?: string | null;
          matter_id?: string | null;
          metadata?: Json | null;
          start_date?: string;
          title?: string;
          updated_at?: string | null;
          virtual_meeting_url?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "calendar_events_created_by_id_fkey";
            columns: ["created_by_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "calendar_events_matter_id_fkey";
            columns: ["matter_id"];
            isOneToOne: false;
            referencedRelation: "matters";
            referencedColumns: ["id"];
          }
        ];
      };
      matter_team_members: {
        Row: {
          assigned_at: string | null;
          billable_rate: number | null;
          created_at: string | null;
          matter_id: string;
          notes: string | null;
          permissions: Json | null;
          profile_id: string;
          role: string;
          updated_at: string | null;
        };
        Insert: {
          assigned_at?: string | null;
          billable_rate?: number | null;
          created_at?: string | null;
          matter_id: string;
          notes?: string | null;
          permissions?: Json | null;
          profile_id: string;
          role: string;
          updated_at?: string | null;
        };
        Update: {
          assigned_at?: string | null;
          billable_rate?: number | null;
          created_at?: string | null;
          matter_id?: string;
          notes?: string | null;
          permissions?: Json | null;
          profile_id?: string;
          role?: string;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "matter_team_members_matter_id_fkey";
            columns: ["matter_id"];
            isOneToOne: false;
            referencedRelation: "matters";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "matter_team_members_profile_id_fkey";
            columns: ["profile_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      matter_templates: {
        Row: {
          created_at: string | null;
          description: string | null;
          id: string;
          matter_type: Database["public"]["Enums"]["matter_type"];
          name: string;
          required_approvals: string[] | null;
          updated_at: string | null;
          workflow_steps: Json | null;
        };
        Insert: {
          created_at?: string | null;
          description?: string | null;
          id?: string;
          matter_type: Database["public"]["Enums"]["matter_type"];
          name: string;
          required_approvals?: string[] | null;
          updated_at?: string | null;
          workflow_steps?: Json | null;
        };
        Update: {
          created_at?: string | null;
          description?: string | null;
          id?: string;
          matter_type?: Database["public"]["Enums"]["matter_type"];
          name?: string;
          required_approvals?: string[] | null;
          updated_at?: string | null;
          workflow_steps?: Json | null;
        };
        Relationships: [];
      };
      matters: {
        Row: {
          billable: boolean | null;
          business_unit_id: string | null;
          client_org_id: string;
          close_date: string | null;
          compliance_category: string | null;
          confidentiality_level: string | null;
          court_case_number: string | null;
          created_at: string | null;
          description: string | null;
          estimated_value: number | null;
          external_budget: number | null;
          external_counsel_id: string | null;
          id: string;
          internal_client_id: string | null;
          is_high_priority: boolean | null;
          is_repeatable: boolean | null;
          is_template_based: boolean | null;
          jurisdiction: string | null;
          lead_attorney_id: string;
          matter_number: string | null;
          matter_type: Database["public"]["Enums"]["matter_type"];
          metadata: Json | null;
          open_date: string | null;
          opposing_org_id: string | null;
          policy_references: string[] | null;
          priority: string | null;
          regulatory_jurisdiction: string[] | null;
          risk_level: string | null;
          status: Database["public"]["Enums"]["matter_status"] | null;
          statute_of_limitations: string | null;
          supervising_attorney_id: string | null;
          template_id: string | null;
          title: string;
          updated_at: string | null;
          team_member_ids: string[];
        };
        Insert: {
          billable?: boolean | null;
          business_unit_id?: string | null;
          client_org_id: string;
          close_date?: string | null;
          compliance_category?: string | null;
          confidentiality_level?: string | null;
          court_case_number?: string | null;
          created_at?: string | null;
          description?: string | null;
          estimated_value?: number | null;
          external_budget?: number | null;
          external_counsel_id?: string | null;
          id?: string;
          internal_client_id?: string | null;
          is_high_priority?: boolean | null;
          is_repeatable?: boolean | null;
          is_template_based?: boolean | null;
          jurisdiction?: string | null;
          lead_attorney_id: string;
          matter_number?: string | null;
          matter_type: Database["public"]["Enums"]["matter_type"];
          metadata?: Json | null;
          open_date?: string | null;
          opposing_org_id?: string | null;
          policy_references?: string[] | null;
          priority?: string | null;
          regulatory_jurisdiction?: string[] | null;
          risk_level?: string | null;
          status?: Database["public"]["Enums"]["matter_status"] | null;
          statute_of_limitations?: string | null;
          supervising_attorney_id?: string | null;
          template_id?: string | null;
          title: string;
          updated_at?: string | null;
          team_member_ids?: string[];
        };
        Update: {
          billable?: boolean | null;
          business_unit_id?: string | null;
          client_org_id?: string;
          close_date?: string | null;
          compliance_category?: string | null;
          confidentiality_level?: string | null;
          court_case_number?: string | null;
          created_at?: string | null;
          description?: string | null;
          estimated_value?: number | null;
          external_budget?: number | null;
          external_counsel_id?: string | null;
          id?: string;
          internal_client_id?: string | null;
          is_high_priority?: boolean | null;
          is_repeatable?: boolean | null;
          is_template_based?: boolean | null;
          jurisdiction?: string | null;
          lead_attorney_id?: string;
          matter_number?: string | null;
          matter_type?: Database["public"]["Enums"]["matter_type"];
          metadata?: Json | null;
          open_date?: string | null;
          opposing_org_id?: string | null;
          policy_references?: string[] | null;
          priority?: string | null;
          regulatory_jurisdiction?: string[] | null;
          risk_level?: string | null;
          status?: Database["public"]["Enums"]["matter_status"] | null;
          statute_of_limitations?: string | null;
          supervising_attorney_id?: string | null;
          template_id?: string | null;
          title?: string;
          updated_at?: string | null;
          team_member_ids?: string[];
        };
        Relationships: [
          {
            foreignKeyName: "matters_client_org_id_fkey";
            columns: ["client_org_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "matters_lead_attorney_id_fkey";
            columns: ["lead_attorney_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "matters_opposing_org_id_fkey";
            columns: ["opposing_org_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "matters_supervising_attorney_id_fkey";
            columns: ["supervising_attorney_id"];
            isOneToOne: false;
            referencedRelation: "profiles";
            referencedColumns: ["id"];
          }
        ];
      };
      oauth2_authorizations: {
        Row: {
          authorized_scopes: string[];
          client_id: string | null;
          created_at: string | null;
          id: string;
          is_active: boolean | null;
          user_id: string | null;
        };
        Insert: {
          authorized_scopes?: string[];
          client_id?: string | null;
          created_at?: string | null;
          id?: string;
          is_active?: boolean | null;
          user_id?: string | null;
        };
        Update: {
          authorized_scopes?: string[];
          client_id?: string | null;
          created_at?: string | null;
          id?: string;
          is_active?: boolean | null;
          user_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "oauth2_authorizations_client_id_fkey";
            columns: ["client_id"];
            isOneToOne: false;
            referencedRelation: "oauth2_clients";
            referencedColumns: ["id"];
          }
        ];
      };
      oauth2_clients: {
        Row: {
          allowed_scopes: string[];
          client_id: string;
          client_secret: string;
          created_at: string | null;
          id: string;
          is_active: boolean | null;
          name: string;
          organization_id: string | null;
          redirect_uris: string[];
        };
        Insert: {
          allowed_scopes?: string[];
          client_id: string;
          client_secret: string;
          created_at?: string | null;
          id?: string;
          is_active?: boolean | null;
          name: string;
          organization_id?: string | null;
          redirect_uris: string[];
        };
        Update: {
          allowed_scopes?: string[];
          client_id?: string;
          client_secret?: string;
          created_at?: string | null;
          id?: string;
          is_active?: boolean | null;
          name?: string;
          organization_id?: string | null;
          redirect_uris?: string[];
        };
        Relationships: [
          {
            foreignKeyName: "oauth2_clients_organization_id_fkey";
            columns: ["organization_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          }
        ];
      };
      organization_relationships: {
        Row: {
          created_at: string | null;
          end_date: string | null;
          id: string;
          is_active: boolean | null;
          metadata: Json | null;
          org_id: string;
          related_org_id: string;
          relationship_type: Database["public"]["Enums"]["org_relationship_type"];
          start_date: string | null;
          updated_at: string | null;
        };
        Insert: {
          created_at?: string | null;
          end_date?: string | null;
          id?: string;
          is_active?: boolean | null;
          metadata?: Json | null;
          org_id: string;
          related_org_id: string;
          relationship_type: Database["public"]["Enums"]["org_relationship_type"];
          start_date?: string | null;
          updated_at?: string | null;
        };
        Update: {
          created_at?: string | null;
          end_date?: string | null;
          id?: string;
          is_active?: boolean | null;
          metadata?: Json | null;
          org_id?: string;
          related_org_id?: string;
          relationship_type?: Database["public"]["Enums"]["org_relationship_type"];
          start_date?: string | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "organization_relationships_org_id_fkey";
            columns: ["org_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          },
          {
            foreignKeyName: "organization_relationships_related_org_id_fkey";
            columns: ["related_org_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          }
        ];
      };
      organizations: {
        Row: {
          address: string | null;
          created_at: string | null;
          id: string;
          logo_url: string | null;
          main_email: string | null;
          main_phone: string | null;
          metadata: Json | null;
          name: string;
          org_type: Database["public"]["Enums"]["org_type"];
          updated_at: string | null;
          website: string | null;
        };
        Insert: {
          address?: string | null;
          created_at?: string | null;
          id?: string;
          logo_url?: string | null;
          main_email?: string | null;
          main_phone?: string | null;
          metadata?: Json | null;
          name: string;
          org_type: Database["public"]["Enums"]["org_type"];
          updated_at?: string | null;
          website?: string | null;
        };
        Update: {
          address?: string | null;
          created_at?: string | null;
          id?: string;
          logo_url?: string | null;
          main_email?: string | null;
          main_phone?: string | null;
          metadata?: Json | null;
          name?: string;
          org_type?: Database["public"]["Enums"]["org_type"];
          updated_at?: string | null;
          website?: string | null;
        };
        Relationships: [];
      };
      profiles: {
        Row: {
          auth_user_id: string | null;
          avatar_url: string | null;
          contact_preferences: Json | null;
          created_at: string | null;
          date_joined: string | null;
          deleted_at: string | null;
          email: string;
          full_name: string;
          id: string;
          initials: string | null;
          is_active: boolean | null;
          last_login: string | null;
          metadata: Json | null;
          organization_id: string;
          out_of_office: boolean | null;
          out_of_office_until: string | null;
          phone: string | null;
          role: Database["public"]["Enums"]["user_role"];
          timezone: string | null;
          updated_at: string | null;
        };
        Insert: {
          auth_user_id?: string | null;
          avatar_url?: string | null;
          contact_preferences?: Json | null;
          created_at?: string | null;
          date_joined?: string | null;
          deleted_at?: string | null;
          email: string;
          full_name: string;
          id?: string;
          initials?: string | null;
          is_active?: boolean | null;
          last_login?: string | null;
          metadata?: Json | null;
          organization_id: string;
          out_of_office?: boolean | null;
          out_of_office_until?: string | null;
          phone?: string | null;
          role: Database["public"]["Enums"]["user_role"];
          timezone?: string | null;
          updated_at?: string | null;
        };
        Update: {
          auth_user_id?: string | null;
          avatar_url?: string | null;
          contact_preferences?: Json | null;
          created_at?: string | null;
          date_joined?: string | null;
          deleted_at?: string | null;
          email?: string;
          full_name?: string;
          id?: string;
          initials?: string | null;
          is_active?: boolean | null;
          last_login?: string | null;
          metadata?: Json | null;
          organization_id?: string;
          out_of_office?: boolean | null;
          out_of_office_until?: string | null;
          phone?: string | null;
          role?: Database["public"]["Enums"]["user_role"];
          timezone?: string | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: "profiles_organization_id_fkey";
            columns: ["organization_id"];
            isOneToOne: false;
            referencedRelation: "organizations";
            referencedColumns: ["id"];
          }
        ];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      can_delete_organization:
        | {
            Args: {
              org_id: string;
              requesting_user_id: string;
            };
            Returns: boolean;
          }
        | {
            Args: {
              org_id: string;
              requesting_user_id: string;
            };
            Returns: boolean;
          };
      create_organization_and_profile: {
        Args: {
          p_org_name: string;
          p_org_type: Database["public"]["Enums"]["org_type"];
          p_email: string;
          p_full_name: string;
          p_auth_user_id: string;
          p_org_email: string;
          p_phone: string;
          p_org_website: string;
          p_org_address: string;
        };
        Returns: Json;
      };
      create_organization_with_admin: {
        Args: {
          p_org_name: string;
          p_org_type: string;
          p_org_email: string;
          p_org_phone: string;
          p_org_website: string;
          p_org_address: string;
          p_email: string;
          p_full_name: string;
          p_phone: string;
          p_auth_user_id: string;
        };
        Returns: Json;
      };
      delete_organization_and_reassign:
        | {
            Args: {
              org_id: string;
              target_org_id: string;
              requesting_user_id: string;
            };
            Returns: Json;
          }
        | {
            Args: {
              org_id: string;
              target_org_id: string;
              requesting_user_id: string;
            };
            Returns: Json;
          };
      delete_organization_cascade: {
        Args: {
          org_id: string;
          requesting_user_id: string;
        };
        Returns: Json;
      };
      get_dashboard_data: {
        Args: {
          user_id: string;
        };
        Returns: Json;
      };
      get_user_organization_id: {
        Args: Record<PropertyKey, never>;
        Returns: string;
      };
      get_user_profile_id: {
        Args: Record<PropertyKey, never>;
        Returns: string;
      };
      is_admin: {
        Args: Record<PropertyKey, never>;
        Returns: boolean;
      };
    };
    Enums: {
      activity_priority: "low" | "medium" | "high" | "urgent";
      activity_type:
        | "task"
        | "email"
        | "phone_call"
        | "meeting"
        | "court_appearance"
        | "document_filed"
        | "note"
        | "status_change";
      matter_status:
        | "intake"
        | "open"
        | "discovery"
        | "negotiation"
        | "trial_prep"
        | "trial"
        | "appeal"
        | "closed"
        | "archived"
        | "draft"
        | "pending_review"
        | "active"
        | "on_hold"
        | "completed";
      matter_type:
        | "litigation"
        | "corporate"
        | "ip"
        | "employment"
        | "real_estate"
        | "regulatory"
        | "other"
        | "contract_review"
        | "compliance"
        | "corporate_governance"
        | "policy_review"
        | "litigation_management"
        | "intellectual_property"
        | "general_advice";
      org_relationship_type:
        | "partner"
        | "client"
        | "vendor"
        | "opposing_counsel"
        | "parent"
        | "subsidiary";
      org_type:
        | "legal_department"
        | "law_firm"
        | "external_law_firm"
        | "client";
      user_role:
        | "admin"
        | "partner"
        | "associate"
        | "paralegal"
        | "support_staff"
        | "external_contact"
        | "general_counsel"
        | "associate_counsel"
        | "legal_operations"
        | "business_contact"
        | "external_counsel";
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
};

type PublicSchema = Database[Extract<keyof Database, "public">];

export type Tables<
  PublicTableNameOrOptions extends
    | keyof (PublicSchema["Tables"] & PublicSchema["Views"])
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof (Database[PublicTableNameOrOptions["schema"]]["Tables"] &
        Database[PublicTableNameOrOptions["schema"]]["Views"])
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? (Database[PublicTableNameOrOptions["schema"]]["Tables"] &
      Database[PublicTableNameOrOptions["schema"]]["Views"])[TableName] extends {
      Row: infer R;
    }
    ? R
    : never
  : PublicTableNameOrOptions extends keyof (PublicSchema["Tables"] &
      PublicSchema["Views"])
  ? (PublicSchema["Tables"] &
      PublicSchema["Views"])[PublicTableNameOrOptions] extends {
      Row: infer R;
    }
    ? R
    : never
  : never;

export type TablesInsert<
  PublicTableNameOrOptions extends
    | keyof PublicSchema["Tables"]
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions["schema"]]["Tables"]
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions["schema"]]["Tables"][TableName] extends {
      Insert: infer I;
    }
    ? I
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema["Tables"]
  ? PublicSchema["Tables"][PublicTableNameOrOptions] extends {
      Insert: infer I;
    }
    ? I
    : never
  : never;

export type TablesUpdate<
  PublicTableNameOrOptions extends
    | keyof PublicSchema["Tables"]
    | { schema: keyof Database },
  TableName extends PublicTableNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicTableNameOrOptions["schema"]]["Tables"]
    : never = never
> = PublicTableNameOrOptions extends { schema: keyof Database }
  ? Database[PublicTableNameOrOptions["schema"]]["Tables"][TableName] extends {
      Update: infer U;
    }
    ? U
    : never
  : PublicTableNameOrOptions extends keyof PublicSchema["Tables"]
  ? PublicSchema["Tables"][PublicTableNameOrOptions] extends {
      Update: infer U;
    }
    ? U
    : never
  : never;

export type Enums<
  PublicEnumNameOrOptions extends
    | keyof PublicSchema["Enums"]
    | { schema: keyof Database },
  EnumName extends PublicEnumNameOrOptions extends { schema: keyof Database }
    ? keyof Database[PublicEnumNameOrOptions["schema"]]["Enums"]
    : never = never
> = PublicEnumNameOrOptions extends { schema: keyof Database }
  ? Database[PublicEnumNameOrOptions["schema"]]["Enums"][EnumName]
  : PublicEnumNameOrOptions extends keyof PublicSchema["Enums"]
  ? PublicSchema["Enums"][PublicEnumNameOrOptions]
  : never;

export type CompositeTypes<
  PublicCompositeTypeNameOrOptions extends
    | keyof PublicSchema["CompositeTypes"]
    | { schema: keyof Database },
  CompositeTypeName extends PublicCompositeTypeNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[PublicCompositeTypeNameOrOptions["schema"]]["CompositeTypes"]
    : never = never
> = PublicCompositeTypeNameOrOptions extends { schema: keyof Database }
  ? Database[PublicCompositeTypeNameOrOptions["schema"]]["CompositeTypes"][CompositeTypeName]
  : PublicCompositeTypeNameOrOptions extends keyof PublicSchema["CompositeTypes"]
  ? PublicSchema["CompositeTypes"][PublicCompositeTypeNameOrOptions]
  : never;

// Schema: public
// Enums
export enum ActivityPriority {
  low = "low",
  medium = "medium",
  high = "high",
  urgent = "urgent",
}

export enum ActivityType {
  task = "task",
  email = "email",
  phone_call = "phone_call",
  meeting = "meeting",
  court_appearance = "court_appearance",
  document_filed = "document_filed",
  note = "note",
  status_change = "status_change",
}

export enum MatterStatus {
  intake = "intake",
  open = "open",
  active = "active",
  discovery = "discovery",
  negotiation = "negotiation",
  trial_prep = "trial_prep",
  trial = "trial",
  appeal = "appeal",
  closed = "closed",
  archived = "archived",
  draft = "draft",
  pending_review = "pending_review",
  on_hold = "on_hold",
  completed = "completed"
}

export enum MatterType {
  litigation = "litigation",
  corporate = "corporate",
  ip = "ip",
  employment = "employment",
  real_estate = "real_estate",
  regulatory = "regulatory",
  other = "other",
  contract_review = "contract_review",
  compliance = "compliance",
  corporate_governance = "corporate_governance",
  policy_review = "policy_review",
  litigation_management = "litigation_management",
  intellectual_property = "intellectual_property",
  general_advice = "general_advice",
}

export enum OrgRelationshipType {
  partner = "partner",
  client = "client",
  vendor = "vendor",
  opposing_counsel = "opposing_counsel",
  parent = "parent",
  subsidiary = "subsidiary",
}

export enum OrgType {
  legal_department = "legal_department",
  law_firm = "law_firm",
  external_law_firm = "external_law_firm",
  client = "client",
}

export enum UserRole {
  admin = "admin",
  partner = "partner",
  associate = "associate",
  paralegal = "paralegal",
  support_staff = "support_staff",
  external_contact = "external_contact",
  general_counsel = "general_counsel",
  associate_counsel = "associate_counsel",
  legal_operations = "legal_operations",
  business_contact = "business_contact",
  external_counsel = "external_counsel",
}

// Tables
export type Activities = Database["public"]["Tables"]["activities"]["Row"];
export type InsertActivities =
  Database["public"]["Tables"]["activities"]["Insert"];
export type UpdateActivities =
  Database["public"]["Tables"]["activities"]["Update"];

export type ActivityComments =
  Database["public"]["Tables"]["activity_comments"]["Row"];
export type InsertActivityComments =
  Database["public"]["Tables"]["activity_comments"]["Insert"];
export type UpdateActivityComments =
  Database["public"]["Tables"]["activity_comments"]["Update"];

export type ActivityParticipants =
  Database["public"]["Tables"]["activity_participants"]["Row"];
export type InsertActivityParticipants =
  Database["public"]["Tables"]["activity_participants"]["Insert"];
export type UpdateActivityParticipants =
  Database["public"]["Tables"]["activity_participants"]["Update"];

export type Approvals = Database["public"]["Tables"]["approvals"]["Row"];
export type InsertApprovals =
  Database["public"]["Tables"]["approvals"]["Insert"];
export type UpdateApprovals =
  Database["public"]["Tables"]["approvals"]["Update"];

export type BusinessUnits =
  Database["public"]["Tables"]["business_units"]["Row"];
export type InsertBusinessUnits =
  Database["public"]["Tables"]["business_units"]["Insert"];
export type UpdateBusinessUnits =
  Database["public"]["Tables"]["business_units"]["Update"];

export type CalendarEvents =
  Database["public"]["Tables"]["calendar_events"]["Row"];
export type InsertCalendarEvents =
  Database["public"]["Tables"]["calendar_events"]["Insert"];
export type UpdateCalendarEvents =
  Database["public"]["Tables"]["calendar_events"]["Update"];

export type MatterTeamMembers =
  Database["public"]["Tables"]["matter_team_members"]["Row"];
export type InsertMatterTeamMembers =
  Database["public"]["Tables"]["matter_team_members"]["Insert"];
export type UpdateMatterTeamMembers =
  Database["public"]["Tables"]["matter_team_members"]["Update"];

export type MatterTemplates =
  Database["public"]["Tables"]["matter_templates"]["Row"];
export type InsertMatterTemplates =
  Database["public"]["Tables"]["matter_templates"]["Insert"];
export type UpdateMatterTemplates =
  Database["public"]["Tables"]["matter_templates"]["Update"];

export type Matters = Database["public"]["Tables"]["matters"]["Row"];
export type InsertMatters = Database["public"]["Tables"]["matters"]["Insert"];
export type UpdateMatters = Database["public"]["Tables"]["matters"]["Update"];

export type Oauth2Authorizations =
  Database["public"]["Tables"]["oauth2_authorizations"]["Row"];
export type InsertOauth2Authorizations =
  Database["public"]["Tables"]["oauth2_authorizations"]["Insert"];
export type UpdateOauth2Authorizations =
  Database["public"]["Tables"]["oauth2_authorizations"]["Update"];

export type Oauth2Clients =
  Database["public"]["Tables"]["oauth2_clients"]["Row"];
export type InsertOauth2Clients =
  Database["public"]["Tables"]["oauth2_clients"]["Insert"];
export type UpdateOauth2Clients =
  Database["public"]["Tables"]["oauth2_clients"]["Update"];

export type OrganizationRelationships =
  Database["public"]["Tables"]["organization_relationships"]["Row"];
export type InsertOrganizationRelationships =
  Database["public"]["Tables"]["organization_relationships"]["Insert"];
export type UpdateOrganizationRelationships =
  Database["public"]["Tables"]["organization_relationships"]["Update"];

export type Organizations =
  Database["public"]["Tables"]["organizations"]["Row"];
export type InsertOrganizations =
  Database["public"]["Tables"]["organizations"]["Insert"];
export type UpdateOrganizations =
  Database["public"]["Tables"]["organizations"]["Update"];

export type Profiles = Database["public"]["Tables"]["profiles"]["Row"];
export type InsertProfiles = Database["public"]["Tables"]["profiles"]["Insert"];
export type UpdateProfiles = Database["public"]["Tables"]["profiles"]["Update"];

// Functions
export type ArgsCanDeleteOrganization =
  Database["public"]["Functions"]["can_delete_organization"]["Args"];
export type ReturnTypeCanDeleteOrganization =
  Database["public"]["Functions"]["can_delete_organization"]["Returns"];

export type ArgsCreateOrganizationAndProfile =
  Database["public"]["Functions"]["create_organization_and_profile"]["Args"];
export type ReturnTypeCreateOrganizationAndProfile =
  Database["public"]["Functions"]["create_organization_and_profile"]["Returns"];

export type ArgsCreateOrganizationWithAdmin =
  Database["public"]["Functions"]["create_organization_with_admin"]["Args"];
export type ReturnTypeCreateOrganizationWithAdmin =
  Database["public"]["Functions"]["create_organization_with_admin"]["Returns"];

export type ArgsDeleteOrganizationAndReassign =
  Database["public"]["Functions"]["delete_organization_and_reassign"]["Args"];
export type ReturnTypeDeleteOrganizationAndReassign =
  Database["public"]["Functions"]["delete_organization_and_reassign"]["Returns"];

export type ArgsDeleteOrganizationCascade =
  Database["public"]["Functions"]["delete_organization_cascade"]["Args"];
export type ReturnTypeDeleteOrganizationCascade =
  Database["public"]["Functions"]["delete_organization_cascade"]["Returns"];

export type ArgsGetDashboardData =
  Database["public"]["Functions"]["get_dashboard_data"]["Args"];
export type ReturnTypeGetDashboardData =
  Database["public"]["Functions"]["get_dashboard_data"]["Returns"];

export type ArgsGetUserOrganizationId =
  Database["public"]["Functions"]["get_user_organization_id"]["Args"];
export type ReturnTypeGetUserOrganizationId =
  Database["public"]["Functions"]["get_user_organization_id"]["Returns"];

export type ArgsGetUserProfileId =
  Database["public"]["Functions"]["get_user_profile_id"]["Args"];
export type ReturnTypeGetUserProfileId =
  Database["public"]["Functions"]["get_user_profile_id"]["Returns"];

export type ArgsIsAdmin = Database["public"]["Functions"]["is_admin"]["Args"];
export type ReturnTypeIsAdmin =
  Database["public"]["Functions"]["is_admin"]["Returns"];

// Matter related types
export interface MatterWithRelations extends Tables<"matters"> {
  lead_attorney: Tables<"profiles">;
  activities: ActivityWithProfile[];
  team_members: TeamMemberWithProfile[];
}

export interface ActivityWithProfile extends Tables<"activities"> {
  assigned_to: Tables<"profiles">;
}

export interface TeamMemberWithProfile extends Tables<"matter_team_members"> {
  profile: Tables<"profiles">;
}

export type MatterStatusType = Database["public"]["Enums"]["matter_status"];

// Activity participant type
export interface ActivityParticipant {
  activity_id: string;
  profile: {
    full_name?: string | null;
    avatar_url?: string | null;
    initials?: string | null;
  };
}
