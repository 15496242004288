import { Tables } from "../types/schema";

type Activity = Tables<"activities"> & {
  assigned_to: Tables<"profiles">;
};

export const separateActivities = (activities: Activity[] = []) => {
  return {
    timelineActivities: activities.filter((a) => a.activity_type !== "task"),
    tasks: activities.filter((a) => a.activity_type === "task"),
  };
}; 