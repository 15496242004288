import React from "react";
import { List } from "@refinedev/mui";
import { useTable, useGetIdentity, useMany, useDelete } from "@refinedev/core";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  MoreVert,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Email,
  Phone,
  Language,
  Business,
} from "@mui/icons-material";
import { Organizations, Matters } from "../../types/schema";
import { useNavigate } from "react-router-dom";
import { AuthUser } from "../../types";

const EmptyState: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <Paper
      sx={{
        textAlign: "center",
        py: 8,
        px: 3,
        mt: 4,
        mx: "auto",
        maxWidth: 600,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Avatar
        sx={{
          mx: "auto",
          mb: 3,
          width: 72,
          height: 72,
          backgroundColor: (theme) => theme.palette.primary.light,
        }}
      >
        <Business sx={{ fontSize: 36, color: "primary.main" }} />
      </Avatar>
      <Typography variant="h5" gutterBottom>
        No External Counsel Yet
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Get started by adding your first external counsel. This will help you manage your legal matters more effectively.
      </Typography>
      <Button
        variant="contained"
        size="large"
        onClick={() => navigate("/organizations/create")}
        sx={{
          px: 4,
          py: 1.5,
          borderRadius: 2,
          textTransform: "none",
          fontSize: "1.1rem",
        }}
      >
        Add External Counsel
      </Button>
    </Paper>
  );
};

export const OrganizationList: React.FC = () => {
  const navigate = useNavigate();
  const { data: identity } = useGetIdentity<AuthUser>();
  const { mutate: deleteOrg } = useDelete();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [orgToDelete, setOrgToDelete] = React.useState<string | null>(null);

  // Fetch organizations
  const { tableQuery } = useTable<Organizations>({
    resource: "organizations",
    pagination: { pageSize: 12 },
    sorters: { initial: [{ field: "name", order: "asc" }] },
    filters: {
      initial: [
        { field: "org_type", operator: "eq", value: "external_law_firm" },
        { field: "id", operator: "ne", value: identity?.organizationId },
      ],
    },
  });

  // Fetch related matters for all organizations
  const { data: mattersData } = useMany<Matters>({
    resource: "matters",
    ids: tableQuery.data?.data.map((org) => org.id) || [],
    queryOptions: {
      enabled: !!tableQuery.data?.data.length,
    },
    meta: {
      select: "*",
      filters: [
        {
          field: "opposing_org_id",
          operator: "in",
          value: tableQuery.data?.data.map((org) => org.id) || [],
        },
      ],
    },
  });

  // Group matters by organization
  const orgMattersMap = React.useMemo(() => {
    if (!mattersData?.data) return {};
    return mattersData.data.reduce((acc, matter) => {
      const orgId = matter.opposing_org_id;
      if (!orgId) return acc;
      if (!acc[orgId]) acc[orgId] = [];
      acc[orgId].push(matter);
      return acc;
    }, {} as Record<string, Matters[]>);
  }, [mattersData?.data]);

  const [expandedMatters, setExpandedMatters] = React.useState<
    Record<string, boolean>
  >({});
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<
    Record<string, HTMLElement | null>
  >({});

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    orgId: string
  ) => {
    setMenuAnchorEl((prev) => ({ ...prev, [orgId]: event.currentTarget }));
  };

  const handleMenuClose = (orgId: string) => {
    setMenuAnchorEl((prev) => ({ ...prev, [orgId]: null }));
  };

  const handleMattersToggle = (orgId: string) => {
    setExpandedMatters((prev) => ({ ...prev, [orgId]: !prev[orgId] }));
  };

  const handleDeleteClick = (orgId: string) => {
    setOrgToDelete(orgId);
    setDeleteDialogOpen(true);
    handleMenuClose(orgId);
  };

  const handleDeleteConfirm = () => {
    if (orgToDelete) {
      deleteOrg({
        resource: "organizations",
        id: orgToDelete,
      });
      setDeleteDialogOpen(false);
      setOrgToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setOrgToDelete(null);
  };

  return (
    <List>
      <Box sx={{ p: { xs: 1, md: 2, lg: 3 } }}>
      
        {(!tableQuery.data?.data || tableQuery.data.data.length === 0) ? (
          <EmptyState />
        ) : (
          <Grid container spacing={3}>
            {tableQuery.data.data.map((org) => {
              const orgMatters = orgMattersMap[org.id] || [];
              const displayMatters = expandedMatters[org.id]
                ? orgMatters
                : orgMatters.slice(0, 3);

              return (
                <Grid item xs={12} sm={6} md={4} key={org.id}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      transition: "all 0.2s ease",
                      "&:hover": {
                        boxShadow: (theme) => theme.shadows[4],
                      },
                      borderRadius: 2,
                    }}
                  >
                    <CardContent sx={{ p: 3 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          mb: 2.5,
                        }}
                      >
                        <Avatar
                          src={org.logo_url || undefined}
                          sx={{
                            width: 64,
                            height: 64,
                            mr: 2,
                            bgcolor: (theme) => theme.palette.primary.main,
                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                          }}
                        >
                          {org.name.charAt(0).toUpperCase()}
                        </Avatar>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              fontWeight: 600,
                              mb: 0.5,
                              lineHeight: 1.2,
                            }}
                          >
                            {org.name}
                          </Typography>
                          <Chip
                            size="small"
                            label="External Counsel"
                            color="primary"
                            sx={{
                              borderRadius: 1,
                              "& .MuiChip-label": {
                                px: 1,
                                fontWeight: 500,
                              },
                            }}
                          />
                        </Box>
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuClick(e, org.id)}
                          sx={{
                            ml: 1,
                            "&:hover": {
                              backgroundColor: "action.hover",
                            },
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          anchorEl={menuAnchorEl[org.id]}
                          open={Boolean(menuAnchorEl[org.id])}
                          onClose={() => handleMenuClose(org.id)}
                          PaperProps={{
                            elevation: 3,
                            sx: { minWidth: 150 },
                          }}
                        >
                          <MenuItem
                            onClick={() =>
                              navigate(`/organizations/show/${org.id}`)
                            }
                          >
                            View Details
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(`/organizations/edit/${org.id}`)
                            }
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDeleteClick(org.id)}
                            sx={{ color: 'error.main' }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </Box>

                      <Stack
                        spacing={1.5}
                        sx={{
                          mb: 3,
                          "& .MuiBox-root": {
                            transition: "all 0.2s ease",
                            "&:hover": {
                              transform: "translateX(4px)",
                            },
                          },
                        }}
                      >
                        {org.main_email && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Email
                              sx={{
                                mr: 1.5,
                                fontSize: 20,
                                color: "primary.main",
                                opacity: 0.8,
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {org.main_email}
                            </Typography>
                          </Box>
                        )}
                        {org.main_phone && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Phone
                              sx={{
                                mr: 1.5,
                                fontSize: 20,
                                color: "primary.main",
                                opacity: 0.8,
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {org.main_phone}
                            </Typography>
                          </Box>
                        )}
                        {org.website && (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Language
                              sx={{
                                mr: 1.5,
                                fontSize: 20,
                                color: "primary.main",
                                opacity: 0.8,
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {org.website}
                            </Typography>
                          </Box>
                        )}
                      </Stack>

                      <Box sx={{ mt: "auto" }}>
                        <Typography
                          variant="body2"
                          sx={{ mb: 1.5, fontWeight: 500 }}
                        >
                          Active Matters: {orgMatters.length}
                        </Typography>

                        {displayMatters.map((matter) => (
                          <Button
                            key={matter.id}
                            onClick={() => navigate(`/matters/show/${matter.id}`)}
                            sx={{
                              display: "block",
                              width: "100%",
                              textAlign: "left",
                              textTransform: "none",
                              mb: 0.75,
                              px: 1.5,
                              py: 0.75,
                              borderRadius: 1,
                              color: "text.primary",
                              "&:hover": {
                                backgroundColor: "action.hover",
                              },
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {matter.title}
                            </Typography>
                          </Button>
                        ))}

                        {orgMatters.length > 3 && (
                          <Button
                            onClick={() => handleMattersToggle(org.id)}
                            startIcon={
                              expandedMatters[org.id] ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )
                            }
                            sx={{
                              width: "100%",
                              mt: 1,
                              color: "primary.main",
                              fontWeight: 500,
                              "&:hover": {
                                backgroundColor: "primary.main",
                                color: "white",
                              },
                            }}
                          >
                            {expandedMatters[org.id]
                              ? "Show Less"
                              : `Show ${orgMatters.length - 3} More`}
                          </Button>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this organization? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
};
