import React from "react";
import {
  Avatar,
  AvatarGroup,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useGo } from "@refinedev/core";
import { MatterType } from "../../types/schema";
import { MatterWithRelations } from "../../types/matter";
import { LazyImage } from "../common/LazyImage";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 1.5,
  boxShadow: "0 2px 12px -4px rgba(0,0,0,0.1)",
  transition: "all 0.2s ease-in-out",
  cursor: "pointer",
  minWidth: 300,
  minHeight: 300,
  position: "relative",
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 12px 24px -10px rgba(0,0,0,0.15)",
    borderColor: theme.palette.primary.light,
  },
  "&:focus-visible": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "2px",
  },
}));

const DescriptionTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minHeight: "4.5em",
});

interface MatterCardProps {
  matter: MatterWithRelations;
  key: string;
}

const formatMatterType = (type: MatterType): string => {
  const typeMap: Record<MatterType, string> = {
    [MatterType.litigation]: "Litigation",
    [MatterType.corporate]: "Corporate",
    [MatterType.ip]: "Intellectual Property",
    [MatterType.employment]: "Employment",
    [MatterType.real_estate]: "Real Estate",
    [MatterType.regulatory]: "Regulatory",
    [MatterType.other]: "Other",
    [MatterType.contract_review]: "Contract Review",
    [MatterType.compliance]: "Compliance",
    [MatterType.corporate_governance]: "Corporate Governance",
    [MatterType.policy_review]: "Policy Review",
    [MatterType.litigation_management]: "Litigation Management",
    [MatterType.intellectual_property]: "Intellectual Property",
    [MatterType.general_advice]: "General Advice",
  };
  return typeMap[type] || type;
};

export const MatterCard: React.FC<MatterCardProps> = ({ matter, key }) => {
  const go = useGo();

  const getMatterTypeColor = (
    type: MatterType
  ): "primary" | "secondary" | "error" | "warning" | "info" | "success" => {
    const colorMap: Record<
      MatterType,
      "primary" | "secondary" | "error" | "warning" | "info" | "success"
    > = {
      [MatterType.litigation]: "error",
      [MatterType.corporate]: "primary",
      [MatterType.ip]: "secondary",
      [MatterType.employment]: "warning",
      [MatterType.real_estate]: "success",
      [MatterType.regulatory]: "info",
      [MatterType.other]: "primary",
      [MatterType.contract_review]: "primary",
      [MatterType.compliance]: "info",
      [MatterType.corporate_governance]: "primary",
      [MatterType.policy_review]: "info",
      [MatterType.litigation_management]: "error",
      [MatterType.intellectual_property]: "secondary",
      [MatterType.general_advice]: "primary",
    };
    return colorMap[type] || "primary";
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      go({ to: `/matters/show/${matter.id}` });
    }
  };

  return (
    <StyledCard
      onClick={() => go({ to: `/matters/show/${matter.id}` })}
      onKeyPress={handleKeyPress}
      tabIndex={0}
      role="button"
      aria-label={`View matter: ${matter.title}`}
      key={key}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 2.5,
          "&:last-child": {
            pb: 2.5,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 1 }}>
          <Chip
            size="small"
            label={formatMatterType(matter.matter_type as MatterType)}
            color={getMatterTypeColor(matter.matter_type as MatterType)}
            sx={{
              py: 0.5,
              fontWeight: 500,
              letterSpacing: "0.2px",
            }}
          />
          {matter.matter_number && (
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                bgcolor: "action.hover",
                px: 1,
                py: 0.5,
                borderRadius: 1,
                fontFamily: "monospace",
              }}
            >
              #{matter.matter_number}
            </Typography>
          )}
        </Box>

        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontSize: "1.1rem",
            lineHeight: 1.4,
            color: "text.primary",
            mb: 1,
          }}
        >
          {matter.title}
        </Typography>

        <DescriptionTypography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            lineHeight: 1.5,
            mb: "auto",
          }}
        >
          {matter?.description || "No description available"}
        </DescriptionTypography>

        <Box
          sx={{
            mt: 2,
            pt: 2,
            borderTop: "1px solid",
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AvatarGroup
            max={3}
            sx={{
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                fontSize: "0.875rem",
                border: "2px solid #fff",
                "&:last-child": {
                  marginLeft: -1,
                },
              },
            }}
          >
            {matter.team_members?.map(({ profile }) => (
              <Tooltip key={profile.id} title={profile.full_name || ""} arrow>
                {profile.avatar_url ? (
                  <Avatar>
                    <LazyImage
                      src={profile.avatar_url}
                      alt={profile.full_name || ""}
                      width={30}
                      height={30}
                    />
                  </Avatar>
                ) : (
                  <Avatar>{profile.initials}</Avatar>
                )}
              </Tooltip>
            ))}
          </AvatarGroup>

          {matter.client_org && (
            <Tooltip title={`Client: ${matter.client_org.name}`} arrow>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: "background.paper",
                  border: "1px solid",
                  borderColor: "divider",
                  "& img": {
                    objectFit: "contain",
                    p: 0.5,
                  },
                }}
              >
                {matter.client_org.logo_url ? (
                  <LazyImage
                    src={matter.client_org.logo_url}
                    alt={matter.client_org.name}
                    width={24}
                    height={24}
                  />
                ) : (
                  matter.client_org.name.charAt(0)
                )}
              </Avatar>
            </Tooltip>
          )}
        </Box>
      </CardContent>
    </StyledCard>
  );
};
