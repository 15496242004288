export interface AuthError extends Error {
  type: 'AUTH' | 'SESSION' | 'PROFILE' | 'UNKNOWN';
  source: string;
  timestamp: string;
  originalError?: unknown;
}

export class DetailedAuthError extends Error implements AuthError {
  type: 'AUTH' | 'SESSION' | 'PROFILE' | 'UNKNOWN';
  source: string;
  timestamp: string;
  originalError?: unknown;

  constructor(message: string, type: AuthError['type'], source: string, originalError?: unknown) {
    super(message);
    this.name = 'DetailedAuthError';
    this.type = type;
    this.source = source;
    this.timestamp = new Date().toISOString();
    this.originalError = originalError;
  }
} 