import React from "react";
import {
  useDataGrid,
  EditButton,
  List,
  EmailField,
  CreateButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Tables } from "../../types";
import { useCan, useGetIdentity } from "@refinedev/core";
import { Chip, Box, Typography, IconButton, Tooltip } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface AuthUser {
  id: string;
  email?: string;
  profileId: string;
  organizationId: string;
  full_name: string;
  avatar_url: string;
  role: string;
}

type UserProfiles = Tables<"profiles">;
export const UserList: React.FC = () => {
  const { data: identity } = useGetIdentity<AuthUser>();
  const { dataGridProps } = useDataGrid<UserProfiles>({
    filters: {
      permanent: [
        {
          field: "organization_id",
          operator: "eq",
          value: identity?.organizationId,
        },
      ],
    },
  });
  const { data: canEdit } = useCan({ action: "edit", resource: "profiles" });
  const navigate = useNavigate();

  const columns = React.useMemo<GridColDef<UserProfiles>[]>(
    () => [
      {
        field: "full_name",
        headerName: "User",
        flex: 2,
        minWidth: 250,
        renderCell: (params) => (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <Typography variant="body1" fontWeight="500">
                {params.value}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <EmailField
                  value={params.row.email}
                  sx={{
                    color: "text.secondary",
                    fontSize: "0.875rem",
                  }}
                />
                {!params.row.auth_user_id && (
                  <Chip
                    label="No Account"
                    size="small"
                    color="warning"
                    variant="outlined"
                    sx={{ height: 20 }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ),
      },
      {
        field: "role",
        headerName: "Status",
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Chip
              label={params.row.is_active ? "Active" : "Inactive"}
              color={params.row.is_active ? "success" : "default"}
              size="small"
              variant={params.row.is_active ? "filled" : "outlined"}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        align: "right",
        headerAlign: "right",
        renderCell: function render({ row }) {
          return (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              sx={{
                opacity: 0.7,
                transition: "opacity 0.2s",
                ".MuiDataGrid-row:hover &": {
                  opacity: 1,
                },
              }}
            >
              <Tooltip title="View Profile">
                <IconButton
                  color="primary"
                  onClick={() => navigate(`/profiles/show/${row.id}`)}
                  size="small"
                >
                  <Visibility fontSize="small" />
                </IconButton>
              </Tooltip>
              {canEdit?.can && (
                <Tooltip title="Edit User">
                  <span>
                    <EditButton
                      hideText
                      recordItemId={row.id}
                      sx={{
                        minWidth: "auto",
                        padding: "4px",
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </Box>
          );
        },
        width: 120,
      },
    ],
    [canEdit, navigate]
  );

  return (
    <List
      headerButtons={({ createButtonProps }) => (
        <>
          {createButtonProps && (
            <CreateButton {...createButtonProps}> Invite User </CreateButton>
          )}
        </>
      )}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        sx={{
          "& .MuiDataGrid-cell": {
            padding: "16px",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            transition: "background-color 0.2s",
            "&:hover": {
              backgroundColor: "action.hover",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "background.neutral",
            borderBottom: "1px solid",
            borderColor: "divider",
          },
          border: "none",
          borderRadius: 1,
        }}
        onRowClick={(params) => navigate(`/profiles/show/${params.id}`)}
      />
    </List>
  );
};
