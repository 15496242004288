import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Gavel as GavelIcon,
  AssignmentLate as TasksIcon,
} from "@mui/icons-material";

const StatsCard = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2.5),
  textAlign: "center",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  transition: "all 0.3s ease",
  cursor: "pointer",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    "&::after": {
      transform: "scale(1.1)",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "radial-gradient(circle at center, rgba(0,0,0,0.03) 0%, rgba(0,0,0,0) 70%)",
    transition: "transform 0.3s ease",
    zIndex: 0,
  },
  "& > *": {
    position: "relative",
    zIndex: 1,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 32,
    marginBottom: theme.spacing(1),
    transition: "transform 0.3s ease",
  },
  "&:hover .MuiSvgIcon-root": {
    transform: "scale(1.1)",
  },
}));

interface DashboardStatsProps {
  activeMattersCount: number;
  pendingTasksCount: number;
}

export const DashboardStats: React.FC<DashboardStatsProps> = ({
  activeMattersCount,
  pendingTasksCount,
}) => {
  const stats = [
    {
      icon: <GavelIcon color="primary" />,
      title: "Active Matters",
      count: activeMattersCount,
      tooltip: "Total number of matters currently in progress",
    },
    {
      icon: <TasksIcon color="secondary" />,
      title: "Pending Tasks",
      count: pendingTasksCount,
      tooltip: "Tasks awaiting completion across all matters",
    },
  ];

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
      {stats.map((stat, index) => (
        <Tooltip key={index} title={stat.tooltip} arrow placement="top">
          <StatsCard>
            {stat.icon}
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                mb: 0.5,
                color: "primary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.5,
              }}
            >
              {stat.count}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 500,
                letterSpacing: "0.5px",
              }}
            >
              {stat.title}
            </Typography>
          </StatsCard>
        </Tooltip>
      ))}
    </Box>
  );
};
