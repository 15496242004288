import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Assignment as TaskIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useGo, useDelete } from "@refinedev/core";
import { format } from "date-fns";
import { ActivityWithRelations, TimeframeOption } from "../../types/activity";
import { useActivityFilters } from "../../hooks/useActivityFilters";
import { ActivityTimelineItem } from "./ActivityTimelineItem";
import { EditActivityModal } from "../edit-activity-modal";
import { Activities, Enums } from "../../types/schema";

const FiltersContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
  transition: "box-shadow 0.2s ease",
  "&:hover": {
    boxShadow: "0 2px 6px 0 rgba(0,0,0,0.12)",
  },
  "& .MuiFormControl-root": {
    position: "relative",
  },
  "& .MuiSelect-root": {
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

export const ActivityTimeline: React.FC = () => {
  const go = useGo();
  const { mutate: deleteActivity } = useDelete();
  const [editingActivity, setEditingActivity] =
    React.useState<ActivityWithRelations | null>(null);

  const {
    data: activities,
    filters,
    showAll,
    setShowAll: toggleShowAll,
    filterOptions,
    isLoading,
    error,
    total,
    resetFilters,
  } = useActivityFilters();

  console.log('Activities:', activities);
  console.log('Loading:', isLoading);
  console.log('Error:', error);
  console.log('Filters:', filters);

  const handleActivityTypeChange = useCallback((e: SelectChangeEvent) => {
    const value = e.target.value as Enums<"activity_type"> | "all";
    filters.activityType.onChange(value);
  }, [filters.activityType]);

  const handleAssigneeChange = useCallback((e: SelectChangeEvent) => {
    filters.assignee.onChange(e.target.value);
  }, [filters.assignee]);

  const handleTimeframeChange = useCallback((e: SelectChangeEvent) => {
    filters.timeframe.onChange(e.target.value as TimeframeOption);
  }, [filters.timeframe]);

  const handleDelete = useCallback((activityId: Activities["id"]) => {
    deleteActivity({
      resource: "activities",
      id: activityId,
      successNotification: {
        message: "Activity successfully deleted",
        type: "success",
      },
    });
  }, [deleteActivity]);

  const handleEdit = useCallback((activity: ActivityWithRelations) => {
    setEditingActivity(activity);
  }, []);

  const handleEditClose = useCallback(() => {
    setEditingActivity(null);
  }, []);

  const groupActivitiesByDate = (
    activities: ActivityWithRelations[]
  ): Record<string, ActivityWithRelations[]> => {
    return activities.reduce((groups, activity) => {
      const date = format(new Date(activity.created_at ?? ""), "yyyy-MM-dd");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(activity);
      return groups;
    }, {} as Record<string, ActivityWithRelations[]>);
  };

  const filtersSection = useMemo(() => (
    <FiltersContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          Filters
        </Typography>
        <Button
          size="small"
          color="primary"
          onClick={resetFilters}
        >
          Reset All
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(auto-fit, minmax(200px, 1fr))",
          },
          gap: 2,
          width: "100%",
        }}
      >
        <FormControl size="small" fullWidth>
          <InputLabel>Activity Type</InputLabel>
          <Select
            value={filters.activityType.value}
            label="Activity Type"
            onChange={handleActivityTypeChange}
            startAdornment={<TaskIcon sx={{ mr: 1, color: "primary.main" }} />}
          >
            <MenuItem value="all">All Types</MenuItem>
            {filterOptions.activityTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth>
          <InputLabel>Assignee</InputLabel>
          <Select
            value={filters.assignee.value}
            label="Assignee"
            onChange={handleAssigneeChange}
            startAdornment={
              <PersonIcon sx={{ mr: 1, color: "primary.main" }} />
            }
          >
            {filters.assignee.options.map((assignee) => (
              <MenuItem key={assignee.id} value={assignee.id}>
                {assignee.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" fullWidth>
          <InputLabel>Time</InputLabel>
          <Select
            value={filters.timeframe.value}
            label="Time"
            onChange={handleTimeframeChange}
            startAdornment={
              <CalendarTodayIcon sx={{ mr: 1, color: "primary.main" }} />
            }
          >
            {filterOptions.timeframes.map((timeframe) => (
              <MenuItem key={timeframe} value={timeframe}>
                {timeframe}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </FiltersContainer>
  ), [
    filters.activityType.value,
    filters.assignee.value,
    filters.assignee.options,
    filters.timeframe.value,
    filterOptions.activityTypes,
    filterOptions.timeframes,
    handleActivityTypeChange,
    handleAssigneeChange,
    handleTimeframeChange,
    resetFilters,
  ]);

  if (isLoading) {
    return (
      <Box sx={{ maxWidth: "100%", overflowX: "hidden" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Recent Activities
        </Typography>
        {filtersSection}
        <Box sx={{ p: 2, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: "100%", overflowX: "hidden" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Recent Activities
        </Typography>
        {filtersSection}
        <Box sx={{ p: 2, textAlign: "center", color: "error.main" }}>
          <Typography>Error loading activities</Typography>
        </Box>
      </Box>
    );
  }

  const groupedActivities = groupActivitiesByDate(activities);
  const dates = Object.keys(groupedActivities).sort((a, b) =>
    b.localeCompare(a)
  );

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h6">Recent Activities</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            size="small"
            onClick={() => toggleShowAll(!showAll)}
            variant="outlined"
            color="primary"
          >
            {showAll ? "Show Less" : "Show More"}
          </Button>
          <Button
            size="small"
            onClick={() => go({ to: "/activities/create" })}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            New Activity
          </Button>
        </Box>
      </Box>

      {filtersSection}

      {activities.length === 0 ? (
        <Box
          sx={{
            p: 3,
            textAlign: "center",
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Typography color="text.secondary">No activities found</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Showing {activities.length} of {total} activities
            </Typography>
          </Box>
          {dates.map((date) => (
            <Box key={date} sx={{ mb: 4 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  mb: 2,
                  color: "text.secondary",
                  textTransform: "uppercase",
                }}
              >
                {format(new Date(date), "MMMM d, yyyy")}
              </Typography>
              {groupedActivities[date].map((activity) => (
                <ActivityTimelineItem
                  key={activity.id}
                  activity={activity}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))}
            </Box>
          ))}
        </>
      )}

      {editingActivity && (
        <EditActivityModal
          activity={editingActivity}
          open={!!editingActivity}
          onClose={handleEditClose}
        />
      )}
    </Box>
  );
};
