import React from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  AccessTime as ClockIcon,
  Event as EventIcon,
  MoreVert as MoreVertIcon,
  Business as BusinessIcon,
} from "@mui/icons-material";
import { useGo, useDelete, useNotification } from "@refinedev/core";
import { formatDistanceToNow } from "date-fns";
import {
  Activities,
  Profiles,
  Matters,
  Organizations,
  Enums,
} from "../../types/schema";

interface ActivityWithRelations extends Activities {
  matter?: Pick<Matters, "id" | "title" | "status" | "matter_number"> & {
    client_org?: Pick<Organizations, "id" | "name">;
  };
  assigned_to?: Pick<Profiles, "id" | "full_name" | "avatar_url" | "initials"> & {
    organization?: Pick<Organizations, "id" | "name">;
  };
  created_by?: Pick<Profiles, "id" | "full_name" | "avatar_url" | "initials"> & {
    organization?: Pick<Organizations, "id" | "name">;
  };
  participants?: Array<{
    profile: Pick<Profiles, "id" | "full_name" | "avatar_url" | "initials"> & {
      organization?: Pick<Organizations, "id" | "name">;
    };
  }>;
}

interface ActivityTimelineItemProps {
  activity: ActivityWithRelations;
  isLast?: boolean;
  onEdit: (activity: Activities) => void;
  onDelete: (id: Activities["id"]) => void;
}

const getRelativeTimeString = (date: string) => {
  if (!date) return "No date";

  const then = new Date(date);
  return formatDistanceToNow(then, { addSuffix: true });
};

const getPriorityColor = (
  priority: Enums<"activity_priority"> | null
): "error" | "warning" | "info" | "default" => {
  switch (priority) {
    case "urgent":
      return "error";
    case "high":
      return "warning";
    case "medium":
      return "info";
    default:
      return "default";
  }
};

export const ActivityTimelineItem: React.FC<ActivityTimelineItemProps> = ({
  activity,
  isLast = false,
  onEdit,
  onDelete,
}) => {
  const go = useGo();
  const { mutate: deleteActivity } = useDelete();
  const { open: openNotification } = useNotification();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    handleClose();

    try {
      await deleteActivity({
        resource: "activities",
        id: activity.id,
        successNotification: {
          message: "Activity successfully deleted",
          type: "success",
        },
      });

      onDelete(activity.id);
    } catch (error) {
      openNotification?.({
        message: "Error deleting activity",
        type: "error",
      });
    }
  };

  return (
    <Box
      onClick={() => activity.matter?.id && go({ to: `/matters/show/${activity.matter.id}` })}
      sx={{
        position: "relative",
        mb: isLast ? 0 : 3,
        ml: 3,
        p: 2.5,
        bgcolor: "background.paper",
        borderRadius: 1,
        cursor: "pointer",
        transition: "all 0.24s cubic-bezier(0.4, 0, 0.2, 1)",
        boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
        "&:hover": {
          bgcolor: "action.hover",
          transform: "translateY(-2px)",
          boxShadow: "0 4px 8px rgba(0,0,0,0.12)",
        },
        "&:active": {
          transform: "translateY(0)",
        },
      }}
    >
      {/* Matter Title and Organization */}
      <Box sx={{ mb: 1.5 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 0.5 }}>
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{
              cursor: "pointer",
              fontWeight: 600,
              "&:hover": {
                textDecoration: "underline",
                color: "primary.dark",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              go({ to: `/matters/show/${activity.matter?.id}` });
            }}
          >
            {activity.matter?.title}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            #{activity.matter?.matter_number}
          </Typography>
        </Box>
        {activity.matter?.client_org && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <BusinessIcon sx={{ fontSize: 16, color: "text.secondary" }} />
            <Typography variant="caption" color="text.secondary">
              {activity.matter.client_org.name}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Activity Context with better spacing */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1.5,
          gap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "1rem", fontWeight: 600, mb: 0.5 }}
          >
            {activity.title}
          </Typography>
          {activity.created_by && (
            <Typography variant="caption" color="text.secondary">
              Created by {activity.created_by.full_name}
              {activity.created_by.organization &&
                ` • ${activity.created_by.organization.name}`}
            </Typography>
          )}
        </Box>

        <Chip
          size="small"
          label={activity.priority || "normal"}
          color={getPriorityColor(activity.priority)}
          sx={{
            textTransform: "capitalize",
            ml: 2,
            alignSelf: "flex-start",
            fontWeight: 500,
            letterSpacing: "0.02em",
          }}
        />
      </Box>

      {/* Description with fade effect */}
      {activity.description && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            lineHeight: 1.6,
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "25%",
              height: "1.6em",
              background: "linear-gradient(to right, transparent, #ffffff)",
            },
          }}
        >
          {activity.description}
        </Typography>
      )}

      {/* Footer with improved layout */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 1.5,
          pt: 1,
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {activity.assigned_to && (
            <Tooltip
              title={`${activity.assigned_to.full_name}${
                activity.assigned_to.organization
                  ? ` • ${activity.assigned_to.organization.name}`
                  : ""
              }`}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  src={activity.assigned_to.avatar_url || ""}
                  sx={{
                    width: 28,
                    height: 28,
                    border: "2px solid",
                    borderColor: "background.paper",
                  }}
                >
                  {activity.assigned_to.initials}
                </Avatar>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ fontWeight: 500 }}
                >
                  {activity.assigned_to.full_name}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {activity.participants && activity.participants.length > 0 && (
            <AvatarGroup
              max={3}
              sx={{
                "& .MuiAvatar-root": {
                  width: 28,
                  height: 28,
                  fontSize: 13,
                  border: "2px solid",
                  borderColor: "background.paper",
                },
              }}
            >
              {activity.participants.map(({ profile }) => (
                <Tooltip
                  key={profile.id}
                  title={`${profile.full_name}${
                    profile.organization
                      ? ` • ${profile.organization.name}`
                      : ""
                  }`}
                >
                  <Avatar src={profile.avatar_url || ""}>
                    {profile.initials}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            color: "text.secondary",
            typography: "body2",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              color: "text.secondary",
              "&:hover": { color: "text.primary" },
            }}
          >
            <ClockIcon sx={{ fontSize: 16 }} />
            {getRelativeTimeString(activity.created_at || "")}
          </Box>
          {activity.due_date && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                color:
                  new Date(activity.due_date) < new Date()
                    ? "error.main"
                    : "text.secondary",
                "&:hover": {
                  color:
                    new Date(activity.due_date) < new Date()
                      ? "error.dark"
                      : "text.primary",
                },
              }}
            >
              <EventIcon sx={{ fontSize: 16 }} />
              Due {getRelativeTimeString(activity.due_date)}
            </Box>
          )}
        </Box>
      </Box>

      {/* Action Menu with improved hover state */}
      <IconButton
        size="small"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          opacity: 0,
          transition: "opacity 0.2s ease",
          ".MuiBox-root:hover &": {
            opacity: 1,
          },
          "&:hover": {
            bgcolor: "action.hover",
          },
        }}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
            onEdit(activity);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
