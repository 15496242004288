import { useState, useMemo, useCallback, useEffect } from "react";
import { useList, CrudFilter, useGetIdentity } from "@refinedev/core";
import { Tables, Enums, Profiles } from "../types/schema";
import { subDays, startOfDay, endOfDay } from "date-fns";
import { ActivityWithRelations } from "../types/activity";
import { AuthUser } from "../types";

interface Assignee {
  id: Profiles["id"];
  name: Profiles["full_name"];
  avatarUrl?: Profiles["avatar_url"];
  initials?: Profiles["initials"];
}

interface ActivityFilterType {
  activityType: {
    value: Enums<"activity_type"> | "all";
    onChange: (value: Enums<"activity_type"> | "all") => void;
  };
  assignee: {
    value: Assignee["id"] | "all";
    onChange: (value: Assignee["id"] | "all") => void;
  };
  timeframe: {
    value: TimeframeOption;
    onChange: (value: TimeframeOption) => void;
  };
}

type TimeframeOption =
  | "All time"
  | "Today"
  | "Yesterday"
  | "Last 7 days"
  | "Last 30 days";

const ACTIVITY_TYPES = [
  { id: "task", title: "Task" },
  { id: "email", title: "Email" },
  { id: "phone_call", title: "Phone Call" },
  { id: "meeting", title: "Meeting" },
  { id: "court_appearance", title: "Court Appearance" },
  { id: "document_filed", title: "Document Filed" },
  { id: "note", title: "Note" },
  { id: "status_change", title: "Status Change" },
] as const;

const TIME_FRAMES = [
  "All time",
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 30 days",
] as const;

export const useActivityFilters = () => {
  // State
  const [showAll, setShowAll] = useState(false);
  const [activityType, setActivityType] = useState<ActivityFilterType["activityType"]["value"]>("all");
  const [assignee, setAssignee] = useState<string>("all");
  const [timeframe, setTimeframe] = useState<TimeframeOption>("All time");

  // Get user identity
  const { data: identity } = useGetIdentity<AuthUser>();

  // Fetch organization members
  const { data: assigneesData, isLoading: isLoadingAssignees } = useList<Tables<"profiles">>({
    resource: "profiles",
    filters: identity?.organizationId ? [
      {
        field: "organization_id",
        operator: "eq",
        value: identity.organizationId,
      },
    ] : undefined,
    pagination: { mode: "server", pageSize: 100 },
    sorters: [{ field: "full_name", order: "asc" }],
    meta: {
      select: "id, full_name, avatar_url, initials",
    },
    queryOptions: {
      enabled: !!identity?.organizationId,
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
      cacheTime: 10 * 60 * 1000, // Keep in cache for 10 minutes
    }
  });

  // Filter options
  const filterOptions = useMemo(() => ({
    activityTypes: [
      { id: "all", title: "All Types" },
      ...ACTIVITY_TYPES,
    ],
    assignees: [
      { id: "all", name: "All Assignees" },
      ...(assigneesData?.data?.map((profile) => ({
        id: profile.id,
        name: profile.full_name || "Unnamed User",
        avatarUrl: profile.avatar_url,
        initials: profile.initials,
      })) || []),
    ],
    timeframes: TIME_FRAMES,
  }), [assigneesData?.data]);

  // Date filter
  const dateFilter = useMemo(() => {
    const now = new Date();
    switch (timeframe) {
      case "Today":
        return {
          field: "created_at",
          operator: "between" as const,
          value: [startOfDay(now).toISOString(), endOfDay(now).toISOString()],
        };
      case "Yesterday": {
        const yesterday = subDays(now, 1);
        return {
          field: "created_at",
          operator: "between" as const,
          value: [startOfDay(yesterday).toISOString(), endOfDay(yesterday).toISOString()],
        };
      }
      case "Last 7 days":
        return {
          field: "created_at",
          operator: "between" as const,
          value: [startOfDay(subDays(now, 7)).toISOString(), endOfDay(now).toISOString()],
        };
      case "Last 30 days":
        return {
          field: "created_at",
          operator: "between" as const,
          value: [startOfDay(subDays(now, 30)).toISOString(), endOfDay(now).toISOString()],
        };
      default:
        return null;
    }
  }, [timeframe]);

  // Build query filters
  const queryFilters: CrudFilter[] = useMemo(() => [
    // Activity type filter
    ...(activityType !== "all" ? [{
      field: "activity_type",
      operator: "eq" as const,
      value: activityType,
    }] : []),
    // Assignee filter
    ...(assignee !== "all" ? [{
      field: "assigned_to_id",
      operator: "eq" as const,
      value: assignee,
    }] : []),
    // Date filter
    ...(dateFilter ? [dateFilter] : []),
  ], [activityType, assignee, dateFilter]);

  // Fetch activities
  const { data, isLoading, error } = useList<ActivityWithRelations>({
    resource: "activities",
    filters: queryFilters,
    pagination: {
      pageSize: showAll ? 100 : 10,
      mode: "server",
    },
    sorters: [
      { field: "created_at", order: "desc" },
      { field: "id", order: "desc" },
    ],
    meta: {
      select: `
        *,
        matter:matters!activities_matter_id_fkey!inner(
          id,
          title,
          status,
          matter_number,
          lead_attorney_id,
          client_org:organizations!matters_client_org_id_fkey(
            id,
            name
          ),
          team_members:matter_team_members!inner(
            profile:profiles!inner(
              id,
              auth_user_id,
              organization_id
            )
          )
        ),
        assigned_to:profiles!activities_assigned_to_id_fkey(
          id,
          full_name,
          avatar_url,
          initials
        ),
        created_by:profiles!activities_created_by_id_fkey(
          id,
          full_name,
          avatar_url,
          initials
        )
      `,
      // Use PostgREST filter for access control
      filter: identity?.profileId && identity?.organizationId 
        ? `or(matter.team_members.profile.organization_id.eq.${identity.organizationId},matter.lead_attorney_id.eq.${identity.profileId})`
        : undefined,
    },
    queryOptions: {
      enabled: !!identity?.profileId && !!identity?.organizationId,
      retry: 2,
    }
  });

  // Debug activities query
  useEffect(() => {
  }, [data, error, identity]);

  return {
    data: data?.data || [],
    filters: {
      activityType: {
        value: activityType,
        onChange: setActivityType,
      },
      assignee: {
        value: assignee,
        onChange: setAssignee,
        selected: filterOptions.assignees.find(a => a.id === assignee),
        options: filterOptions.assignees,
      },
      timeframe: {
        value: timeframe,
        onChange: setTimeframe,
      },
    },
    showAll,
    setShowAll,
    filterOptions,
    isLoading: isLoading || isLoadingAssignees,
    error,
    total: data?.total || 0,
    resetFilters: useCallback(() => {
      setActivityType("all");
      setAssignee("all");
      setTimeframe("All time");
    }, []),
  };
};
