import { useState } from 'react';
import { ActivityType } from '../types/schema';

interface UseActivityModalReturn {
  isOpen: boolean;
  activityType: ActivityType | undefined;
  openModal: (type?: ActivityType) => void;
  closeModal: () => void;
}

export const useActivityModal = (): UseActivityModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [activityType, setActivityType] = useState<ActivityType>();

  const openModal = (type?: ActivityType) => {
    setActivityType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActivityType(undefined);
  };

  return {
    isOpen,
    activityType,
    openModal,
    closeModal,
  };
}; 