import React from "react";
import { Box, Card, CardContent, Chip, Stack, Typography } from "@mui/material";

interface MetadataRendererProps {
  metadata: unknown;
  variant?: "card" | "inline";
  label?: string;
}

export const MetadataRenderer: React.FC<MetadataRendererProps> = ({
  metadata,
  variant = "card",
  label,
}) => {
  if (!metadata || typeof metadata !== "object") return null;

  const renderValue = (value: unknown): React.ReactNode => {
    if (Array.isArray(value)) {
      return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {value.map((item, index) => (
            <Chip
              key={index}
              label={String(item)}
              size="small"
              variant="outlined"
            />
          ))}
        </Stack>
      );
    }

    if (typeof value === "object" && value !== null) {
      return (
        <Stack spacing={1}>
          {Object.entries(value).map(([key, val]) => (
            <Box key={key}>
              <Typography variant="caption" color="text.secondary">
                {key.replace(/_/g, " ")}
              </Typography>
              {renderValue(val)}
            </Box>
          ))}
        </Stack>
      );
    }

    return (
      <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
        {String(value)}
      </Typography>
    );
  };

  const content = (
    <Stack spacing={2}>
      {label && (
        <Typography variant="subtitle1" color="primary">
          {label}
        </Typography>
      )}
      {Object.entries(metadata as object).map(([key, value]) => (
        <Box key={key}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ mb: 0.5 }}
          >
            {key.replace(/_/g, " ").toUpperCase()}
          </Typography>
          {renderValue(value)}
        </Box>
      ))}
    </Stack>
  );

  if (variant === "inline") return content;

  return (
    <Card variant="outlined">
      <CardContent>{content}</CardContent>
    </Card>
  );
};
