import { useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useList,
  useNotification,
  useCreate,
  useGetIdentity,
  HttpError,
} from "@refinedev/core";
import { Profiles } from "../../types/schema";
import { useForm } from "@refinedev/react-hook-form";
import { Controller, FieldValues, SubmitHandler } from "react-hook-form";
import { Database } from "../../types/schema";
import { AuthUser } from "../../types";

// Add type for activity status
type ActivityStatus = "pending" | "in_progress" | "completed";

interface CreateActivityModalProps {
  open: boolean;
  onClose: () => void;
  matterId: string;
  onSuccess?: () => void;
  defaultActivityType?: "task" | "activity";
  organizationId?: string;
}

interface ActivityFormValues {
  title: string;
  description?: string;
  activity_type: Database["public"]["Enums"]["activity_type"];
  matter_id: string;
  due_date: string | null;
  status: ActivityStatus;
  priority: Database["public"]["Enums"]["activity_priority"];
  assigned_to_id?: string | null;
  metadata: {
    notes?: string;
    created_via: string;
    last_updated: string;
    [key: string]: unknown;
  };
  email_hash?: string | null;
  completed_at?: string | null;
  parent_activity_id?: string | null;
  created_by_id: string;
}

export const CreateActivityModal: React.FC<CreateActivityModalProps> = ({
  open,
  onClose,
  matterId,
  onSuccess,
  defaultActivityType = "activity",
  organizationId,
}) => {
  // Explicitly type the mutation
  const { mutate } = useCreate<ActivityFormValues, HttpError>();
  const { open: openNotification } = useNotification();
  const { data: identity } = useGetIdentity<AuthUser>();

  const { data: profilesData, isLoading: isLoadingProfiles } = useList<Profiles>(
    {
      resource: "profiles",
      pagination: { mode: "server", current: 1, pageSize: 100 },
      filters: organizationId
        ? [{ field: "organization_id", operator: "eq", value: organizationId }]
        : [],
    }
  );

  const { data: parentActivities } = useList({
    resource: "activities",
    filters: [{ field: "matter_id", operator: "eq", value: matterId }],
  });

  const defaultValues = useMemo(
    () => ({
      matter_id: matterId,
      activity_type: defaultActivityType === "task" ? "task" : "note",
      status: "pending",
      priority: "medium",
      due_date: null,
      metadata: {
        created_via: "modal",
        last_updated: new Date().toISOString(),
      },
      assigned_to_id: null,
      description: "",
      email_hash: null,
      completed_at: null,
      parent_activity_id: null,
      created_by_id: identity?.id || "",
    }),
    [matterId, defaultActivityType, identity?.id]
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ActivityFormValues>({
    refineCoreProps: {
      action: "create",
      resource: "activities",
      redirect: false,
      onMutationSuccess: () => {
        openNotification?.({
          type: "success",
          message: "Activity created successfully",
          description: "The activity has been created and saved.",
        });
        reset();
        onClose();
        onSuccess?.();
      },
      onMutationError: (error) => {
        openNotification?.({
          type: "error",
          message: "Error creating activity",
          description: error?.message || "An unexpected error occurred",
        });
      },
    },
    defaultValues,
  });

  const profiles = profilesData?.data || [];
  const activityType = watch("activity_type");

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, reset, matterId, defaultActivityType, identity?.id, defaultValues]);

  const handleFormSubmit = async (data: ActivityFormValues) => {
    if (!identity?.id) {
      openNotification?.({
        type: "error",
        message: "Authentication Error",
        description: "User identity not found. Please try logging in again.",
      });
      return;
    }

    const values: ActivityFormValues = {
      ...data,
      assigned_to_id: data.assigned_to_id || null,
      parent_activity_id: data.parent_activity_id || null,
      metadata: {
        ...data.metadata,
        notes: data.metadata?.notes || "",
        created_via: "modal",
        last_updated: new Date().toISOString(),
      },
      created_by_id: identity.id,
    };

    mutate({
      resource: "activities",
      values,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="create-activity-dialog-title"
    >
      {/* Explicitly type the form submission handler */}
      <form
        onSubmit={handleSubmit(handleFormSubmit as SubmitHandler<FieldValues>)}
      >
        <DialogTitle id="create-activity-dialog-title">
          Create New {defaultActivityType === "task" ? "Task" : "Activity"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Controller
              control={control}
              name="title"
              rules={{ required: "Title is required" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Title"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  disabled={isSubmitting}
                />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                />
              )}
            />

            <Controller
              control={control}
              name="activity_type"
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Activity Type</InputLabel>
                  <Select {...field} label="Activity Type">
                    <MenuItem value="note">Note</MenuItem>
                    <MenuItem value="task">Task</MenuItem>
                    <MenuItem value="meeting">Meeting</MenuItem>
                    <MenuItem value="document_filed">Document Filed</MenuItem>
                    <MenuItem value="court_appearance">
                      Court Appearance
                    </MenuItem>
                    <MenuItem value="status_change">Status Change</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="due_date"
              render={({ field }) => (
                <DatePicker
                  label="Due Date"
                  value={field.value ? new Date(field.value) : null}
                  onChange={(date: Date | null) => {
                    field.onChange(date?.toISOString() || null);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.due_date,
                      helperText: errors.due_date?.message as string,
                    },
                  }}
                />
              )}
            />

            {activityType === "task" && (
              <Stack spacing={3}>
                <Controller
                  control={control}
                  name="priority"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>Priority</InputLabel>
                      <Select {...field} label="Priority">
                        <MenuItem value="high">High</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select {...field} label="Status">
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="in_progress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="assigned_to_id"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <InputLabel>Assigned To</InputLabel>
                      <Select
                        {...field}
                        label="Assigned To"
                        disabled={isLoadingProfiles || isSubmitting}
                      >
                        <MenuItem value="">Not Assigned</MenuItem>
                        {isLoadingProfiles ? (
                          <MenuItem disabled>Loading profiles...</MenuItem>
                        ) : (
                          profiles.map((profile) => (
                            <MenuItem key={profile.id} value={profile.id}>
                              {profile.full_name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="completed_at"
                  render={({ field }) => (
                    <DatePicker
                      label="Completion Date"
                      value={field.value ? new Date(field.value) : null}
                      onChange={(date: Date | null) => {
                        field.onChange(date?.toISOString() || null);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.completed_at,
                          helperText: errors.completed_at?.message as string,
                        },
                      }}
                    />
                  )}
                />
              </Stack>
            )}

            <Controller
              control={control}
              name="metadata"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Additional Notes"
                  multiline
                  rows={2}
                  fullWidth
                  value={field.value?.notes || ""}
                  onChange={(e) =>
                    field.onChange({
                      ...field.value,
                      notes: e.target.value,
                    })
                  }
                  sx={{ display: "none" }}
                />
              )}
            />

            <Controller
              control={control}
              name="parent_activity_id"
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Parent Activity</InputLabel>
                  <Select
                    {...field}
                    value={field.value || ""}
                    label="Parent Activity"
                  >
                    <MenuItem value="">None</MenuItem>
                    {parentActivities?.data?.map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            {activityType === "email" && (
              <Controller
                control={control}
                name="email_hash"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email Reference"
                    fullWidth
                    helperText="Reference ID for the related email"
                  />
                )}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {isSubmitting ? "Creating..." : "Create"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
