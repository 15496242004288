import { MatterStatus } from "../../../types/schema";
import { getStatusColor } from "../../../utils/statusColors";

export const cardStyles = {
  header: {
    borderBottom: 1,
    borderColor: "divider",
    bgcolor: "background.default",
    px: 3,
    py: 2,
    "& .MuiTypography-h6": {
      fontSize: "1.125rem",
      fontWeight: 600,
    },
  },
  content: {
    p: 3,
    "&:last-child": {
      pb: 3,
    },
  },
  card: {
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
    },
  },
  button: {
    px: 3,
    py: 1,
    bgcolor: "primary.main",
    color: "white",
    "&:hover": {
      bgcolor: "primary.dark",
      transform: "translateY(-1px)",
      boxShadow: "0 4px 12px 0 rgba(0,0,0,0.2)",
    },
    transition: "all 0.2s ease-in-out",
  },
  labelText: {
    color: "text.secondary",
    fontWeight: 500,
    fontSize: "0.75rem",
    letterSpacing: "0.5px",
    mb: 1,
  },
  valueText: {
    color: "text.primary",
    fontWeight: 500,
    fontSize: "0.975rem",
  },
} as const;

export const getStatusChipStyles = (status: MatterStatus) => ({
  borderRadius: "6px",
  height: "24px",
  px: 1,
  fontSize: "0.75rem",
  fontWeight: 600,
  bgcolor: `${getStatusColor(status)}20`,
  color: getStatusColor(status),
  "& .MuiChip-label": {
    px: 1,
  },
}); 