import React from "react";
import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

const SkeletonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "center",
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  overflowX: "hidden",
}));

export const DashboardSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      {/* Welcome Section */}
      <Box sx={{ textAlign: "center" }}>
        <Skeleton
          variant="text"
          width={300}
          height={40}
          sx={{ mx: "auto", mb: 1 }}
        />
        <Skeleton variant="text" width={200} height={24} sx={{ mx: "auto" }} />
      </Box>

      {/* Stats Cards */}
      <StatsContainer>
        {[1, 2].map((index) => (
          <Box
            key={index}
            sx={{
              flex: 1,
              p: 2.5,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Skeleton
              variant="circular"
              width={32}
              height={32}
              sx={{ mb: 1 }}
            />
            <Skeleton variant="text" width={80} height={40} sx={{ mb: 0.5 }} />
            <Skeleton variant="text" width={100} height={24} />
          </Box>
        ))}
      </StatsContainer>

      {/* Matters Carousel */}
      <Box>
        <Skeleton variant="text" width={150} height={32} sx={{ mb: 2 }} />
        <CarouselContainer>
          {[1, 2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                minWidth: 300,
                p: 2,
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <Skeleton variant="text" width={200} height={24} sx={{ mb: 1 }} />
              <Skeleton variant="text" width={100} height={24} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" height={60} sx={{ mb: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  {[1, 2, 3].map((avatar) => (
                    <Skeleton
                      key={avatar}
                      variant="circular"
                      width={30}
                      height={30}
                    />
                  ))}
                </Box>
                <Skeleton variant="text" width={80} height={32} />
              </Box>
            </Box>
          ))}
        </CarouselContainer>
      </Box>

      {/* Activities Timeline */}
      <Box>
        <Skeleton variant="text" width={180} height={32} sx={{ mb: 2 }} />
        {[1, 2, 3].map((activity) => (
          <Box
            key={activity}
            sx={{
              p: 2,
              mb: 2,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Skeleton
                  variant="text"
                  width={200}
                  height={24}
                  sx={{ mb: 1 }}
                />
                <Skeleton variant="text" width={150} height={20} />
              </Box>
              <Skeleton variant="rectangular" width={60} height={24} />
            </Box>
            <Skeleton variant="text" width="100%" height={40} sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton variant="text" width={100} height={24} />
              </Box>
              <Skeleton variant="text" width={80} height={24} />
            </Box>
          </Box>
        ))}
      </Box>
    </SkeletonContainer>
  );
};
