import React, { useState } from "react";
import { Box, TextField, IconButton, Chip, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface ListInputProps {
  value: string[];
  onChange: (values: string[]) => void;
}

export const ListInput: React.FC<ListInputProps> = ({
  value = [],
  onChange,
}) => {
  const [newItem, setNewItem] = useState("");

  const handleAddItem = () => {
    if (newItem.trim()) {
      const updatedList = [...value, newItem.trim()];
      onChange(updatedList);
      setNewItem("");
    }
  };

  const handleRemoveItem = (index: number) => {
    const updatedList = value.filter((_, i) => i !== index);
    onChange(updatedList);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem();
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
        <TextField
          size="small"
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Add item"
          fullWidth
        />
        <IconButton
          onClick={handleAddItem}
          size="small"
          color="primary"
          disabled={!newItem.trim()}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {value.map((item, index) => (
          <Chip
            key={index}
            label={item}
            onDelete={() => handleRemoveItem(index)}
            size="small"
            sx={{ mb: 1 }}
          />
        ))}
      </Stack>
    </Box>
  );
};
