import React from "react";
import { Box, Typography, Button } from "@mui/material";

interface EmptyStateProps {
  title: string;
  message: string;
  icon?: React.ComponentType<unknown>;
  action?: {
    label: string;
    onClick: () => void;
  };
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  message,
  action,
}) => {
  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        bgcolor: "background.paper",
        borderRadius: 1,
        border: "1px dashed",
        borderColor: "divider",
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        {message}
      </Typography>
      {action && (
        <Button
          variant="contained"
          onClick={action.onClick}
          sx={{
            px: 4,
          }}
        >
          {action.label}
        </Button>
      )}
    </Box>
  );
};
