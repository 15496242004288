import React, { useMemo, useState, useEffect } from "react";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useList, useMany, useOne } from "@refinedev/core";
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Autocomplete,
  InputAdornment,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, FieldError } from "react-hook-form";
import { IResourceComponentsProps } from "@refinedev/core";
import { Tables, MatterStatus, MatterType, UserRole } from "../../types/schema";
import { MetadataEditor } from "../../components/metadata-editor";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useParams, useNavigate } from "react-router-dom";

type TeamMemberNote = {
  memberId: string;
  note: string;
};

type MatterFormType = Tables<"matters">;

// Helper function to safely get error message
const getErrorMessage = (error: FieldError | undefined): string | undefined => {
  if (error && typeof error === 'object' && 'message' in error) {
    return String(error.message);
  }
  return undefined;
};

// Helper function to safely handle API errors
const getApiErrorMessage = (error: unknown): string => {
  if (error && typeof error === 'object') {
    if ('message' in error) {
      return String(error.message);
    }
    if ('error' in error) {
      return String(error.error);
    }
  }
  return "An unexpected error occurred. Please try again.";
};

interface TeamMember {
  id: string;
  full_name: string;
  role: UserRole;
}

export const MatterEdit: React.FC<IResourceComponentsProps> = () => {
  const [expanded, setExpanded] = useState(false);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<{ id: string; note: string } | null>(null);
  const [teamMemberNotes, setTeamMemberNotes] = useState<Record<string, string>>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Fetch current matter data
  const { data: matterData, isLoading: isMatterLoading } = useOne({
    resource: "matters",
    id: id || "",
    queryOptions: {
      enabled: !!id,
    },
  });

  // Fetch client organization data
  const { data: clientOrgData, isLoading: isClientOrgLoading } = useOne({
    resource: "organizations",
    id: matterData?.data?.client_org_id || "",
    queryOptions: {
      enabled: !!matterData?.data?.client_org_id,
    },
  });

  // Fetch team members data using useList instead of useOne
  const { data: teamMembersData, isLoading: isTeamMembersLoading } = useList({
    resource: "matter_team_members",
    filters: [
      {
        field: "matter_id",
        operator: "eq",
        value: id,
      },
    ],
    queryOptions: {
      enabled: !!id,
    },
  });

  // Fetch profiles data for team members
  const teamMemberIds = teamMembersData?.data?.map(member => member.profile_id) || [];
  const { data: profilesData, isLoading: isProfilesLoading } = useMany({
    resource: "profiles",
    ids: teamMemberIds,
    queryOptions: {
      enabled: teamMemberIds.length > 0,
    },
  });

  const {
    saveButtonProps,
    register,
    control,
    watch,
    formState: { errors, isSubmitting, isDirty },
    getValues,
    reset,
    setValue,
  } = useForm<MatterFormType>();

  // Load existing team member notes and data when matter data changes
  useEffect(() => {
    if (matterData?.data) {
      const metadata = matterData.data.metadata as Record<string, any> | null;
      if (metadata?.teamMemberNotes) {
        setTeamMemberNotes(metadata.teamMemberNotes);
      }

      // Set the form data
      reset(matterData.data);
    }
  }, [matterData, reset]);

  // Set team members when team members data loads
  useEffect(() => {
    if (teamMembersData?.data) {
      const teamMembers = teamMembersData.data;
      const teamMemberIds = teamMembers.map(member => member.profile_id);
      setValue('team_member_ids', teamMemberIds);

      // Load notes from team members data if they exist
      const newNotes: Record<string, string> = {};
      teamMembers.forEach(member => {
        if (member.notes) {
          newNotes[member.profile_id] = member.notes;
        }
      });
      setTeamMemberNotes(prev => ({...prev, ...newNotes}));
    }
  }, [teamMembersData, setValue]);

  // Add team members autocomplete with proper loading state
  const { autocompleteProps: teamMembersAutocompleteProps } = useAutocomplete({
    resource: "profiles",
    defaultValue: watch("team_member_ids"),
    filters: [
      {
        field: "role",
        operator: "in",
        value: [
          UserRole.partner,
          UserRole.associate,
          UserRole.paralegal,
          UserRole.support_staff,
          UserRole.external_counsel,
        ],
      },
    ],
    onSearch: (value) => [
      {
        field: "full_name",
        operator: "contains",
        value,
      },
    ],
  });

  // Format role for display
  const formatRole = (role: string) => {
    return role
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Memoized field configurations for better performance
  const MATTER_STATUS_OPTIONS = Object.values(MatterStatus).map((status) => ({
    value: status,
    label: status.charAt(0).toUpperCase() + status.slice(1).replace(/_/g, " "),
  }));

  const MATTER_TYPE_OPTIONS = Object.values(MatterType).map((type) => ({
    value: type,
    label: type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, " "),
  }));

  // Memoize autocomplete configurations
  const { autocompleteProps: clientOrgAutocompleteProps } = useAutocomplete({
    resource: "organizations",
    defaultValue: watch("client_org_id"),
    filters: [{ field: "org_type", operator: "eq", value: "client" }],
  });

  const { autocompleteProps: leadAttorneyAutocompleteProps } = useAutocomplete({
    resource: "profiles",
    defaultValue: watch("lead_attorney_id"),
    filters: [
      {
        field: "role",
        operator: "in",
        value: [UserRole.partner, UserRole.associate],
      },
    ],
  });

  // Optional fields' autocomplete props - only fetch when needed
  const { autocompleteProps: opposingOrgAutocompleteProps } = useAutocomplete({
    resource: "organizations",
    defaultValue: watch("opposing_org_id"),
    queryOptions: { enabled: !!watch("opposing_org_id") },
  });

  const { autocompleteProps: supervisingAttorneyAutocompleteProps } =
    useAutocomplete({
      resource: "profiles",
      defaultValue: watch("supervising_attorney_id"),
      filters: [
        {
          field: "role",
          operator: "in",
          value: [UserRole.partner, UserRole.associate],
        },
      ],
      queryOptions: { enabled: !!watch("supervising_attorney_id") },
    });

  const handleOpenNoteDialog = (memberId: string) => {
    setSelectedMember({
      id: memberId,
      note: teamMemberNotes[memberId] || ""
    });
    setNoteDialogOpen(true);
  };

  const handleSaveNote = () => {
    if (selectedMember) {
      const newNotes = {
        ...teamMemberNotes,
        [selectedMember.id]: selectedMember.note.trim()
      };
      // Remove empty notes
      if (!selectedMember.note.trim()) {
        delete newNotes[selectedMember.id];
      }
      setTeamMemberNotes(newNotes);
      showSnackbar("Note saved successfully", "success");
    }
    setNoteDialogOpen(false);
  };

  // Memoize main fields
  const mainFields = useMemo(
    () => (
      <>
        <Grid item xs={12}>
          <TextField
            {...register("title", {
              required: "Title is required",
              maxLength: {
                value: 255,
                message: "Title cannot exceed 255 characters",
              },
            })}
            error={!!errors?.title}
            helperText={errors?.title?.message as string}
            label="Matter Title*"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="matter_type"
            rules={{ required: "Matter type is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Matter Type*"
                InputLabelProps={{ shrink: true }}
                fullWidth
                error={!!errors?.matter_type}
                helperText={errors?.matter_type?.message as string}
              >
                {MATTER_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="client_org_id"
            rules={{ required: "Client organization is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Client Organization"
                value={clientOrgData?.data?.name ?? ""}
                InputProps={{
                  readOnly: true,
                  startAdornment: isClientOrgLoading && (
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                  ),
                }}
                variant="filled"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="lead_attorney_id"
            rules={{ required: "Lead attorney is required" }}
            render={({ field }) => (
              <Autocomplete
                {...leadAttorneyAutocompleteProps}
                value={
                  leadAttorneyAutocompleteProps.options?.find(
                    (option) => option.id === field.value
                  ) || null
                }
                onChange={(_, value) => field.onChange(value?.id ?? null)}
                getOptionLabel={(item) => item?.full_name ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Lead Attorney*"
                    error={!!errors?.lead_attorney_id}
                    helperText={errors?.lead_attorney_id?.message as string}
                    required
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="status"
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Matter Status"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                {MATTER_STATUS_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                multiline
                rows={4}
                fullWidth
              />
            )}
          />
        </Grid>
      </>
    ),
    [register, control, errors, clientOrgData, isClientOrgLoading, leadAttorneyAutocompleteProps]
  );

  // Memoize team members section
  const teamMembersSection = useMemo(
    () => (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1">Team Members</Typography>
            {Object.keys(teamMemberNotes).length > 0 && (
              <Typography variant="body2" color="textSecondary">
                {Object.keys(teamMemberNotes).length} member{Object.keys(teamMemberNotes).length !== 1 ? 's' : ''} with notes
              </Typography>
            )}
          </Box>
          <Divider sx={{ my: 1 }} />
        </Grid>
        
        <Grid item xs={12}>
          <Controller
            control={control}
            name="team_member_ids"
            render={({ field }) => (
              <Autocomplete
                {...teamMembersAutocompleteProps}
                multiple
                loading={teamMembersAutocompleteProps.loading}
                loadingText="Loading team members..."
                noOptionsText="No team members found"
                value={
                  teamMembersAutocompleteProps.options?.filter((option) =>
                    field.value?.includes(option.id)
                  ) || []
                }
                onChange={(_, value) => {
                  field.onChange(value.map((item) => item.id));
                }}
                getOptionLabel={(item: TeamMember) => {
                  if (!item?.full_name) return "Loading...";
                  return `${item.full_name} (${formatRole(item.role)})`;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(value, getTagProps) =>
                  value.map((option: TeamMember, index) => (
                    <Box key={option.id} sx={{ display: 'inline-flex', alignItems: 'center', m: 0.5 }}>
                      <Tooltip 
                        title={teamMemberNotes[option.id] ? teamMemberNotes[option.id] : "Add notes"}
                        arrow
                      >
                        <Chip
                          label={option.full_name ? `${option.full_name} (${formatRole(option.role)})` : "Loading..."}
                          {...getTagProps({ index })}
                          onDelete={undefined}
                          icon={teamMemberNotes[option.id] ? <CommentIcon fontSize="small" /> : undefined}
                          sx={{
                            '& .MuiChip-icon': {
                              color: teamMemberNotes[option.id] ? 'primary.main' : undefined,
                            },
                          }}
                        />
                      </Tooltip>
                      <IconButton 
                        size="small" 
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleOpenNoteDialog(option.id);
                        }}
                        sx={{ ml: 0.5 }}
                        color={teamMemberNotes[option.id] ? "primary" : "default"}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team Members"
                    placeholder={teamMembersAutocompleteProps.loading ? "Loading..." : "Add team members"}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.team_member_ids}
                    helperText={getErrorMessage(errors?.team_member_ids as FieldError)}
                  />
                )}
                renderOption={(props, option: TeamMember) => (
                  <li {...props}>
                    <Typography>
                      {option.full_name} ({formatRole(option.role)})
                    </Typography>
                  </li>
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="supervising_attorney_id"
            render={({ field }) => (
              <Autocomplete
                {...supervisingAttorneyAutocompleteProps}
                {...field}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Supervising Attorney"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors?.supervising_attorney_id}
                    helperText={getErrorMessage(errors?.supervising_attorney_id as FieldError)}
                  />
                )}
                getOptionLabel={(item) => item?.full_name ?? ""}
                onChange={(_, value) => field.onChange(value?.id ?? null)}
              />
            )}
          />
        </Grid>

        {/* Notes Dialog */}
        <Dialog 
          open={noteDialogOpen} 
          onClose={() => setNoteDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {teamMembersAutocompleteProps.options?.find(
              (option) => option.id === selectedMember?.id
            )?.full_name || "Team Member"} Notes
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Notes"
              fullWidth
              multiline
              rows={4}
              value={selectedMember?.note || ""}
              onChange={(e) => setSelectedMember(prev => prev ? { ...prev, note: e.target.value } : null)}
              variant="outlined"
              placeholder="Add notes about this team member's role or responsibilities..."
              inputProps={{ maxLength: 500 }}
              helperText={`${selectedMember?.note?.length || 0}/500 characters`}
            />
          </DialogContent>
          <DialogActions>
            {selectedMember?.note && (
              <Button 
                onClick={() => {
                  setSelectedMember(prev => prev ? { ...prev, note: "" } : null);
                }}
                color="error"
                sx={{ mr: 'auto' }}
              >
                Clear Notes
              </Button>
            )}
            <Button onClick={() => setNoteDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveNote} variant="contained">Save</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    ),
    [
      control,
      teamMembersAutocompleteProps,
      noteDialogOpen,
      selectedMember,
      teamMemberNotes,
      errors,
    ]
  );

  // Memoize optional fields
  const optionalFields = useMemo(
    () => (
      <>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="estimated_value"
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label="Estimated Value (Optional)"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("court_case_number")}
            label="Court Case Number (Optional)"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...register("jurisdiction")}
            label="Jurisdiction (Optional)"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="metadata"
            render={({ field }) => (
              <MetadataEditor value={field.value} onChange={field.onChange} />
            )}
          />
        </Grid>
      </>
    ),
    [register, control]
  );

  // Memoize boolean fields
  const booleanFields = useMemo(
    () => (
      <>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="is_high_priority"
            render={({ field: { value, ...field } }) => (
              <TextField
                {...field}
                select
                label="Priority (Optional)"
                value={value ? "true" : "false"}
                onChange={(e) => field.onChange(e.target.value === "true")}
                fullWidth
              >
                <MenuItem value="false">Normal Priority</MenuItem>
                <MenuItem value="true">High Priority</MenuItem>
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="billable"
            render={({ field: { value, ...field } }) => (
              <TextField
                {...field}
                select
                label="Billable Status (Optional)"
                value={value ? "true" : "false"}
                onChange={(e) => field.onChange(e.target.value === "true")}
                fullWidth
              >
                <MenuItem value="true">Billable</MenuItem>
                <MenuItem value="false">Non-Billable</MenuItem>
              </TextField>
            )}
          />
        </Grid>
      </>
    ),
    [control]
  );

  // Update save handler to handle team members correctly
  const { saveButtonPropsWithConfirm, ConfirmDialog } = useConfirmDialog({
    originalProps: {
      ...saveButtonProps,
      onClick: async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (saveButtonProps.onClick) {
          try {
            // Get the form values without team_member_ids
            const formValues = getValues();
            const { team_member_ids, ...matterValues } = formValues;

            // Create a new onClick event handler that uses the filtered values
            const matterSaveHandler = {
              ...saveButtonProps,
              onClick: async (e: React.MouseEvent<HTMLButtonElement>) => {
                const originalOnClick = saveButtonProps.onClick;
                if (originalOnClick) {
                  // Override form data with filtered values
                  const event = {
                    ...e,
                    target: {
                      ...e.target,
                      value: matterValues,
                    },
                  };
                  return originalOnClick(event);
                }
                return Promise.resolve();
              },
            };

            // First save the matter without team_member_ids
            await matterSaveHandler.onClick(e);
            
            // Get current and new team member IDs
            const currentTeamMembers = teamMembersData?.data || [];
            const newTeamMemberIds = team_member_ids as string[] || [];
            
            // Delete removed team members
            const removedMembers = currentTeamMembers.filter(
              member => !newTeamMemberIds.includes(member.profile_id)
            );
            
            const deletePromises = removedMembers.map(member => 
              fetch(`/api/matter_team_members`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  matter_id: id,
                  profile_id: member.profile_id,
                }),
              })
            );

            // Update or create team members
            const upsertPromises = newTeamMemberIds.map(profileId => 
              fetch(`/api/matter_team_members`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  matter_id: id,
                  profile_id: profileId,
                  notes: teamMemberNotes[profileId] || null,
                  role: profilesData?.data?.find(p => p.id === profileId)?.role || 'team_member',
                }),
              })
            );

            await Promise.all([...deletePromises, ...upsertPromises]);
            showSnackbar("Matter and team members updated successfully", "success");
            navigate(`/matters/show/${id}`);
            return true;
          } catch (error: unknown) {
            console.error("Error saving matter:", error);
            showSnackbar(getApiErrorMessage(error), "error");
            return false;
          }
        }
        return false;
      },
    },
    title: "Save Changes",
    content: (
      <Box>
        <Typography>Are you sure you want to save these changes to this matter?</Typography>
        {isDirty && (
          <Alert severity="info" sx={{ mt: 2 }}>
            You have unsaved changes that will be saved.
          </Alert>
        )}
      </Box>
    ),
  });

  if (isMatterLoading || isTeamMembersLoading || isProfilesLoading || isClientOrgLoading) {
    return (
      <Box sx={{ width: '100%', p: 2, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Loading matter details...</Typography>
      </Box>
    );
  }

  return (
    <>
      <Edit
        saveButtonProps={saveButtonPropsWithConfirm}
        isLoading={isSubmitting}
        goBack={false}
      >
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}
          autoComplete="off"
        >
          <Grid container spacing={2}>
            {/* Main Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Matter Information</Typography>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {mainFields}

            {/* Team Members Section */}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                {teamMembersSection}
              </Paper>
            </Grid>

            {/* Additional Information Section */}
            <Grid item xs={12}>
              <Accordion
                expanded={expanded}
                onChange={(_, isExpanded) => setExpanded(isExpanded)}
                sx={{ mt: 2 }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Additional Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">Status Information</Typography>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                    {booleanFields}

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" sx={{ mt: 2 }}>
                        Optional Details
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                    {optionalFields}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Edit>
      <ConfirmDialog />
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
