import {
  Refine,
  useLogout,
  Authenticated,
  useGetIdentity,
} from "@refinedev/core";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import {
  ThemedLayoutV2,
  RefineSnackbarProvider,
  HamburgerMenu,
  AuthPage,
  useNotificationProvider,
  ThemedSiderV2,
} from "@refinedev/mui";
import { RefineKbarProvider } from "@refinedev/kbar";
import {
  CircularProgress,
  ThemeProvider,
  CssBaseline,
  useTheme,
} from "@mui/material";
import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import routerBindings, { NavigateToResource } from "@refinedev/react-router-v6";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import FolderIcon from "@mui/icons-material/Folder";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import { theme } from "./theme";
import { supabaseClient } from "./utility";
import { authProvider } from "./authProvider";
import { accessControlProvider } from "./accessControlProvider";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { Dashboard } from "./pages/dashboard";
import { SettingsPage } from "./pages/settings";
import { OrganizationList } from "./pages/organizations/list";
import { OrganizationCreate } from "./pages/organizations/create";
import { OrganizationShow } from "./pages/organizations/show";
import { OrganizationEdit } from "./pages/organizations/edit";
import { MatterList } from "./pages/matters/list";
import { MatterShow } from "./pages/matters/show";
import { MatterCreate } from "./pages/matters/create";
import { MatterEdit } from "./pages/matters/edit";
import { UserList } from "./pages/users/list";
import { UserShow } from "./pages/users/show";
import { UserCreate } from "./pages/users/create";
import { useState } from "react";
import { Login } from "./pages/auth/login";
import { ErrorBoundary } from "./components/error-boundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DevtoolsProvider, DevtoolsPanel } from "@refinedev/devtools";
import Registration from "./pages/auth/register";
import { NotFound } from "./components/not-found";
import { AuthUser } from "./types";  
import logo from "../public/logo.png";
import { ForgotPasswordPage } from "./pages/auth/forgot-password";
import { ResetPasswordPage } from "./pages/auth/reset-password";

const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};

const stringAvatar = (name: string) => ({
  sx: {
    bgcolor: stringToColor(name),
    cursor: "pointer",
    width: 40,
    height: 40,
  },
  children:
    name.split(" ").length > 1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name[0],
});
// hell
const Header = () => {
  const { data: user, isLoading: identityIsLoading } = useGetIdentity<AuthUser>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { mutate: logout } = useLogout();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleProfileClick = () => {
    setAnchorEl(null);
    if (user?.id) {
      navigate(`/profiles/show/${user.id}`);
    }
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  return (
    <Box
      component="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 3,
        height: 64,
        bgcolor: "background.paper",
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <HamburgerMenu />
        <Box
          component="img"
          sx={{
            height: 40,
            width: "auto",
            marginRight: 2,
          }}
          alt="LegalEase Logo"
          src={logo}
        />
        {/* <Typography variant="h6" color="primary.main" fontWeight="medium">
          LegalEase
        </Typography> */}
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        {identityIsLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Avatar
            src={user?.avatar_url}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            {...(!user?.avatar_url && stringAvatar(user?.full_name || "U"))}
            sx={{
              cursor: "pointer",
              transition: "transform 0.2s",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          />
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            elevation: 2,
            sx: { minWidth: 200, mt: 1 },
          }}
        >
          <MenuItem onClick={handleProfileClick}>
            <Box display="flex" alignItems="center" gap={1}>
              Profile
            </Box>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Box display="flex" alignItems="center" gap={1} color="error.main">
              Logout
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem sx={{ pointerEvents: "none" }}>
            <Typography
              variant="caption"
              sx={{
                fontStyle: "italic",
                color: "text.secondary",
                width: "100%",
                textAlign: "center",
              }}
            >
              powered by LegalEase
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const LoadingComponent = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <CircularProgress />
  </Box>
);

function App() {
  const resources = [
    {
      name: "dashboard",
      list: "/dashboard",
      meta: { label: "Dashboard", icon: <DashboardIcon /> },
    },
    {
      name: "organizations",
      list: "/organizations",
      create: "/organizations/create",
      edit: "/organizations/edit/:id",
      show: "/organizations/show/:id",
      meta: {
        label: "External Counsel",
        icon: <BusinessIcon />,
      },
    },
    {
      name: "matters",
      list: "/matters",
      show: "/matters/show/:id",
      create: "/matters/create",
      edit: "/matters/edit/:id",
      meta: { icon: <FolderIcon /> },
    },
    {
      name: "profiles",
      list: "/profiles",
      show: "/profiles/show/:id",
      create: "/profiles/create",
      meta: { icon: <PeopleIcon /> },
    },
    {
      name: "settings",
      list: "/settings",
      meta: { label: "Settings", icon: <SettingsIcon /> },
    },
  ];

  const CustomSider = () => {
    const theme = useTheme();

    return (
      <Box
        sx={{
          height: "100vh",
          bgcolor: theme.palette.background.paper,
          borderRight: `1px solid ${theme.palette.divider}`,
          display: "flex",
          flexDirection: "column",
          boxShadow: "1px 0 3px rgba(0, 0, 0, 0.05)",
          "& .RefineThemedLayoutV2Sider-container": {
            bgcolor: "transparent",
            padding: "16px 8px",
          },
          "& .RefineThemedLayoutV2Sider-title": {
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: "1.2rem",
            paddingLeft: 2,
            paddingBottom: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          "& .RefineThemedLayoutV2Sider-select": {
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: 1,
            "&:hover": {
              bgcolor: theme.palette.action.hover,
            },
          },
          "& .menu-item": {
            color: theme.palette.text.secondary,
            borderRadius: 1,
            margin: "4px 0",
            transition: "all 0.2s ease",
            "&.active": {
              color: theme.palette.primary.main,
              bgcolor: theme.palette.primary.light,
              fontWeight: 500,
              "& .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
              },
            },
            "&:hover": {
              bgcolor: theme.palette.action.hover,
              color: theme.palette.primary.main,
              "& .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
              },
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.secondary,
              transition: "color 0.2s ease",
            },
          },
        }}
      >
        <ThemedSiderV2 />
      </Box>
    );
  };

  return (
    <DevtoolsProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <ErrorBoundary>
              <RefineKbarProvider>
                <RefineSnackbarProvider>
                  <Refine
                    dataProvider={dataProvider(supabaseClient)}
                    liveProvider={liveProvider(supabaseClient)}
                    authProvider={authProvider}
                    notificationProvider={useNotificationProvider}
                    routerProvider={routerBindings}
                    resources={resources}
                    options={{
                      syncWithLocation: true,
                      warnWhenUnsavedChanges: true,
                      title: { text: "LegalEase" },
                    }}
                    accessControlProvider={accessControlProvider}
                  >
                    <Routes>
                      {/* Public Auth Routes */}
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Registration />} />
                      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                      <Route path="/auth/confirm" element={<ResetPasswordPage />} />

                      {/* Protected Routes */}
                      <Route
                        element={
                          <Authenticated
                            key="protected"
                            redirectOnFail="/login"
                            loading={<LoadingComponent />}
                          >
                            <ThemedLayoutV2 Header={Header} Sider={CustomSider}>
                              <Outlet />
                            </ThemedLayoutV2>
                          </Authenticated>
                        }
                      >
                        <Route
                          index
                          element={<NavigateToResource resource="dashboard" />}
                        />
                        <Route path="dashboard" element={<Dashboard />} />

                        {/* Nested Routes */}
                        <Route path="organizations">
                          <Route index element={<OrganizationList />} />
                          <Route
                            path="create"
                            element={<OrganizationCreate />}
                          />
                          <Route
                            path="edit/:id"
                            element={<OrganizationEdit />}
                          />
                          <Route
                            path="show/:id"
                            element={<OrganizationShow />}
                          />
                        </Route>

                        <Route path="matters">
                          <Route index element={<MatterList />} />
                          <Route path="show/:id" element={<MatterShow />} />
                          <Route path="create" element={<MatterCreate />} />
                          <Route path="edit/:id" element={<MatterEdit />} />
                        </Route>

                        <Route path="profiles">
                          <Route index element={<UserList />} />
                          <Route path="show/:id" element={<UserShow />} />
                          <Route path="create" element={<UserCreate />} />
                        </Route>

                        <Route path="settings" element={<SettingsPage />} />
                      </Route>

                      <Route
                        element={
                          <Authenticated key="error" fallback={<Outlet />}>
                            <Outlet />
                          </Authenticated>
                        }
                      >
                        <Route path="*" element={<NotFound />} />
                      </Route>
                      {/* Catch-All Route */}
                      <Route path="*" element={<NotFound />} />

                  
                    </Routes>
                  </Refine>
                  <DevtoolsPanel />
                </RefineSnackbarProvider>
              </RefineKbarProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </DevtoolsProvider>
  );
}

export default App;
