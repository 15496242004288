import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material";

export const MatterShowSkeleton: React.FC = () => {
  return (
    <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
      {/* Header Skeleton */}
      <Card elevation={3} sx={{ mb: 3, bgcolor: "background.paper" }}>
        <CardContent sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Skeleton variant="text" width="60%" height={40} />
              <Skeleton variant="text" width="80%" height={24} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Skeleton variant="rounded" width={100} height={32} />
                <Skeleton variant="rounded" width={100} height={32} />
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        {/* Matter Overview Skeleton */}
        <Grid item xs={12} lg={8}>
          <Card>
            <CardHeader title={<Skeleton variant="text" width={200} />} />
            <CardContent>
              <Grid container spacing={3}>
                {[...Array(6)].map((_, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Skeleton variant="text" width={100} sx={{ mb: 1 }} />
                    <Skeleton variant="text" width="90%" />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Team Members Skeleton */}
        <Grid item xs={12} sm={6} lg={4}>
          <Card>
            <CardHeader title={<Skeleton variant="text" width={150} />} />
            <CardContent>
              <Stack spacing={2}>
                {[...Array(4)].map((_, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Skeleton variant="circular" width={40} height={40} />
                    <Stack flex={1}>
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="40%" />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
