import React, { useState } from "react";
import { Create } from "@refinedev/mui";
import {
  Box,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Stack,
  Autocomplete,
  Typography,
  Card,
  alpha,
  useTheme,
  Collapse,
  Fade,
  Container,
  Chip,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { useGetIdentity, useCreate, useSelect, useNotification, HttpError } from "@refinedev/core";
import { Controller } from "react-hook-form";
import { MetadataEditor } from "../../components/metadata-editor";
import { Tables, InsertMatters, InsertMatterTeamMembers } from "../../types/schema";
import { MatterCreateSkeleton } from "./components/MatterCreateSkeleton";
import { AuthUser } from "../../types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const MATTER_TYPES = [
  { value: "litigation", label: "Litigation" },
  { value: "corporate", label: "Corporate" },
  { value: "ip", label: "Intellectual Property" },
  { value: "employment", label: "Employment" },
  { value: "real_estate", label: "Real Estate" },
  { value: "regulatory", label: "Regulatory" },
  { value: "other", label: "Other" },
] as const;

const STEPS = [
  {
    label: 'Matter Details',
    description: 'Basic information about the matter',
    hint: 'Give your matter a clear, descriptive title and reference number'
  },
  {
    label: 'Team',
    description: 'Assign team members',
    hint: 'Select the team members who will collaborate on this matter'
  },
  {
    label: 'Settings',
    description: 'Matter configuration',
    hint: 'Set the type, priority, and billing status'
  },
  {
    label: 'Additional Info',
    description: 'Extra details',
    hint: 'Add any other relevant information'
  }
] as const;

const VALIDATION_FIELDS = {
  0: ["title", "description"],
  1: ["team_member_ids"],
  2: ["matter_type", "open_date"],
  3: [],
} as const;

const matterSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title must be less than 100 characters"),
  matter_number: z.string().optional(),
  description: z.string().max(500, "Description must be less than 500 characters").optional(),
  team_member_ids: z.array(z.string()).min(1, "At least one team member is required"),
  matter_type: z.enum(MATTER_TYPES.map(t => t.value) as [string, ...string[]]),
  open_date: z.string().min(1, "Open date is required"),
  billable: z.boolean(),
  is_high_priority: z.boolean(),
  metadata: z.record(z.any()).optional(),
  client_org_id: z.string(),
  status: z.literal("intake"),
});

type MatterFormData = z.infer<typeof matterSchema>;

export const MatterCreate: React.FC = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: identity, isLoading: isIdentityLoading } = useGetIdentity<AuthUser>();
  const { open } = useNotification();

  const form = useForm<MatterFormData>({
    resolver: zodResolver(matterSchema),
    defaultValues: {
      status: "intake",
      billable: true,
      is_high_priority: false,
      metadata: {},
      team_member_ids: [],
      client_org_id: identity?.organizationId,
    },
  });

  const { saveButtonProps, register, control, formState: { errors }, trigger, getValues, setValue, watch } = form;

  const { mutateAsync: createMatter } = useCreate<InsertMatters>();
  const { mutateAsync: createTeamMember } = useCreate<InsertMatterTeamMembers>();
  const { options: teamOptions = [] } = useSelect({
    resource: "profiles",
    optionLabel: "full_name",
    optionValue: "id",
    filters: [{ field: "organization_id", operator: "eq", value: identity?.organizationId }],
    meta: { select: "id,full_name,role,avatar_url,initials" },
  });
  const handleNext = async () => {
    const isStepValid = await trigger(VALIDATION_FIELDS[activeStep as keyof typeof VALIDATION_FIELDS]);
    if (isStepValid) setActiveStep(prev => prev + 1);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsSubmitting(true);
      const { team_member_ids, ...matterData } = getValues();

      // Create the matter first
      const response = await createMatter({
        resource: "matters",
        values: matterData,
      });

      // If matter was created successfully and we have team members to add
      if (response.data?.id && team_member_ids?.length) {
        await Promise.all(
          team_member_ids.map((profileId: string) =>
            createTeamMember({
              resource: "matter_team_members",
              values: {
                matter_id: response.data.id,
                profile_id: profileId,
              },
            })
          )
        );
      }

      open?.({ type: "success", message: "Matter created successfully" });
    } catch (error: unknown) {
      console.error('Error creating matter:', error);
      const errorMessage = error instanceof Error 
        ? error.message 
        : "Error creating matter. Please try again.";
      open?.({ type: "error", message: errorMessage });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepContent = (step: number) => {
    const StepContent = () => {
      switch (step) {
        case 0:
          return (
            <Stack spacing={4} sx={{ width: '100%' }}>
              <TextField
                {...register("title")}
                error={!!errors.title}
                helperText={errors.title?.message as string}
                required
                fullWidth
                label="Matter Title"
                placeholder="e.g., Contract Review - ABC Corp"
                size="medium"
                InputProps={{
                  endAdornment: watch("title")?.length ? (
                    <Typography variant="caption" color="text.secondary">
                      {watch("title").length}/100
                    </Typography>
                  ) : null
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    fontSize: '1.1rem',
                    '& input': {
                      padding: '16px 14px',
                    },
                    '&:hover': {
                      '& > fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    '&.Mui-focused': {
                      '& > fieldset': {
                        borderWidth: 2,
                      },
                    },
                  },
                }}
              />
              <TextField
                {...register("matter_number")}
                fullWidth
                label="Matter Number"
                placeholder="e.g., MAT-2024-001"
                helperText="Optional reference number for tracking"
                size="medium"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    fontSize: '1.1rem',
                    '& input': {
                      padding: '16px 14px',
                    },
                    '&:hover': {
                      '& > fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  },
                }}
              />
              <TextField
                {...register("description")}
                error={!!errors.description}
                helperText={
                  <Stack direction="row" justifyContent="space-between" width="100%">
                    <span>{errors.description?.message as string}</span>
                    <span>{watch("description")?.length || 0}/500</span>
                  </Stack>
                }
                fullWidth
                label="Description"
                multiline
                rows={4}
                placeholder="Brief overview of the matter and its objectives"
                size="medium"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    fontSize: '1.1rem',
                    '& textarea': {
                      padding: '16px 14px',
                    },
                    '&:hover': {
                      '& > fieldset': {
                        borderColor: theme.palette.primary.main,
                      },
                    },
                    '&.Mui-focused': {
                      '& > fieldset': {
                        borderWidth: 2,
                      },
                    },
                  },
                }}
              />
            </Stack>
          );
        case 1:
          return (
            <Stack spacing={3}>
              <Autocomplete
                multiple
                options={teamOptions}
                value={teamOptions.filter(option => watch("team_member_ids")?.includes(option.value as string))}
                onChange={(_, newValue) => setValue("team_member_ids", newValue.map(item => item.value as string), { shouldValidate: true })}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Team Members"
                    error={!!errors.team_member_ids}
                    helperText={errors.team_member_ids?.message as string}
                    placeholder="Search team members"
                    size="medium"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        '&.Mui-focused': {
                          '& > fieldset': {
                            borderWidth: 2,
                          },
                        },
                      },
                    }}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={option.value}
                      label={option.label}
                      sx={{
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        '& .MuiChip-deleteIcon': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  ))
                }
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Selected team members will have access to this matter and receive notifications about updates.
              </Typography>
            </Stack>
          );
        case 2:
          return (
            <Stack spacing={3}>
              <TextField
                {...register("open_date")}
                type="date"
                required
                fullWidth
                label="Open Date"
                InputLabelProps={{ shrink: true }}
                error={!!errors.open_date}
                helperText={errors.open_date?.message as string}
                size="medium"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '&.Mui-focused': {
                      '& > fieldset': {
                        borderWidth: 2,
                      },
                    },
                  },
                }}
              />
              <TextField
                select
                {...register("matter_type")}
                required
                fullWidth
                label="Matter Type"
                error={!!errors.matter_type}
                helperText={errors.matter_type?.message as string}
                size="medium"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    '&.Mui-focused': {
                      '& > fieldset': {
                        borderWidth: 2,
                      },
                    },
                  },
                }}
              >
                {MATTER_TYPES.map(({ value, label }) => (
                  <MenuItem 
                    key={value} 
                    value={value}
                    sx={{
                      py: 1.5,
                      '&.Mui-selected': {
                        bgcolor: alpha(theme.palette.primary.main, 0.08),
                        '&:hover': {
                          bgcolor: alpha(theme.palette.primary.main, 0.12),
                        },
                      },
                    }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </TextField>
              <Card 
                elevation={0} 
                sx={{ 
                  p: 3,
                  bgcolor: alpha(theme.palette.grey[50], 0.6),
                  border: `1px solid ${theme.palette.grey[200]}`,
                  borderRadius: 2,
                }}
              >
                <Stack spacing={3}>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                    Matter Settings
                  </Typography>
                  <Stack direction="row" spacing={4}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="billable"
                          control={control}
                          render={({ field }) => (
                            <Switch 
                              {...field} 
                              checked={field.value}
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: theme.palette.primary.main,
                                  '&:hover': {
                                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                                  },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                  bgcolor: alpha(theme.palette.primary.main, 0.5),
                                },
                              }}
                            />
                          )}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          Billable Matter
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch 
                          {...register("is_high_priority")} 
                          checked={getValues("is_high_priority")}
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: theme.palette.error.main,
                              '&:hover': {
                                bgcolor: alpha(theme.palette.error.main, 0.08),
                              },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              bgcolor: alpha(theme.palette.error.main, 0.5),
                            },
                          }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          High Priority
                        </Typography>
                      }
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          );
        case 3:
          return (
            <Controller
              control={control}
              name="metadata"
              render={({ field }) => <MetadataEditor value={field.value} onChange={field.onChange} />}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Fade in={true} timeout={400}>
        <Box>
          <Typography variant="h6" sx={{ mb: 3, color: theme.palette.primary.main }}>
            {STEPS[step].label}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {STEPS[step].hint}
          </Typography>
          <StepContent />
        </Box>
      </Fade>
    );
  };

  if (isIdentityLoading) return <MatterCreateSkeleton />;

  return (
    <Create 
      saveButtonProps={{ ...saveButtonProps, sx: { display: 'none' } }}
      wrapperProps={{
        sx: {
          background: 'transparent',
          p: 0,
          m: 0,
          width: '100%',
          maxWidth: '100%'
        }
      }}
    >
      <Container 
        maxWidth="lg" 
        sx={{ 
          py: 4,
          px: { xs: 2, sm: 4 },
          mx: 'auto',
          width: '100%'
        }}
      >
        <Box 
          component="form" 
          sx={{ 
            display: "flex", 
            flexDirection: "column", 
            gap: 3,
            width: '100%',
            maxWidth: '100%'
          }}
        >
          <input type="hidden" {...register("client_org_id")} value={identity?.organizationId} />
          
          <Paper 
            elevation={0}
            sx={{ 
              p: { xs: 3, sm: 6 },
              borderRadius: 3,
              bgcolor: 'background.paper',
              boxShadow: theme.shadows[2],
              border: `1px solid ${alpha(theme.palette.grey[300], 0.2)}`,
              width: '100%',
            }}
          >
            <Stepper 
              activeStep={activeStep} 
              sx={{ 
                mb: 8,
                '& .MuiStepIcon-root': {
                  color: alpha(theme.palette.primary.main, 0.12),
                  width: 40,
                  height: 40,
                  '&.Mui-active': {
                    color: theme.palette.primary.main,
                    boxShadow: `0 0 0 8px ${alpha(theme.palette.primary.main, 0.08)}`,
                    borderRadius: '50%',
                  },
                  '&.Mui-completed': {
                    color: theme.palette.success.main,
                  },
                },
                '& .MuiStepLabel-label': {
                  typography: 'subtitle1',
                  transition: theme.transitions.create(['color', 'font-weight']),
                  '&.Mui-active': {
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  },
                },
                '& .MuiStepConnector-line': {
                  borderColor: alpha(theme.palette.primary.main, 0.12),
                  borderWidth: 2,
                },
              }}
            >
              {STEPS.map(({ label, description }) => (
                <Step key={label}>
                  <StepLabel>
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {label}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {description}
                      </Typography>
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ 
              minHeight: 400, 
              display: 'flex', 
              alignItems: 'flex-start',
              px: { xs: 0, sm: 4 },
              width: '100%',
            }}>
              {renderStepContent(activeStep)}
            </Box>

            <Stack 
              direction="row" 
              justifyContent="space-between" 
              sx={{ 
                mt: 8,
                pt: 4,
                borderTop: `1px solid ${alpha(theme.palette.grey[300], 0.2)}`,
              }}
            >
              <Button 
                variant="outlined" 
                onClick={() => setActiveStep(prev => prev - 1)} 
                disabled={activeStep === 0}
                size="large"
                sx={{ 
                  px: 6,
                  py: 1.5,
                  minWidth: 140,
                }}
              >
                Back
              </Button>
              {activeStep === STEPS.length - 1 ? (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting || saveButtonProps.disabled}
                  size="large"
                  sx={{ 
                    px: 6,
                    py: 1.5,
                    minWidth: 140,
                  }}
                >
                  {isSubmitting ? "Creating..." : "Create Matter"}
                </Button>
              ) : (
                <Button 
                  variant="contained" 
                  onClick={handleNext}
                  size="large"
                  sx={{ 
                    px: 6,
                    py: 1.5,
                    minWidth: 140,
                  }}
                >
                  Continue
                </Button>
              )}
            </Stack>
          </Paper>
        </Box>
      </Container>
    </Create>
  );
};

/// Add a better loading state: on google app
/// Ensure logo represented
