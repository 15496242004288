import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { useNavigation, useForgotPassword } from "@refinedev/core";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { supabaseClient } from "../../utility";

const forgotPasswordSchema = z.object({
  email: z.string().email("Invalid email address"),
});

type ForgotPasswordFormValues = z.infer<typeof forgotPasswordSchema>;

export const ForgotPasswordPage = () => {
  const { push } = useNavigation();
  const [error, setError] = useState<string | null>(null);
  
  const { mutate: forgotPassword, isLoading, isSuccess } = useForgotPassword<ForgotPasswordFormValues>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setError(null);
      
      // Get the site URL from environment or window.location
      const siteUrl = process.env.VITE_APP_URL || window.location.origin;
      
      // Ensure we have a full URL with protocol
      const redirectUrl = siteUrl.startsWith('http') 
        ? `${siteUrl}/auth/confirm`
        : `https://${siteUrl}/auth/confirm`;
      
      // Use Supabase's built-in password reset
      const { error: resetError } = await supabaseClient.auth.resetPasswordForEmail(
        data.email,
        {
          redirectTo: redirectUrl,
        }
      );

      if (resetError) {
        throw resetError;
      }

      // Call refine's hook for consistency
      forgotPassword(
        { email: data.email },
        {
          onError: (error) => {
            const errorMessage = error instanceof Error ? error.message : "An error occurred";
            console.error('Forgot password error:', error);
            setError(errorMessage);
          },
        }
      );
    } catch (err) {
      console.error('Forgot password error:', err);
      const message = err instanceof Error ? err.message : "Failed to send reset link";
      setError(message);
    }
  });

  if (isSuccess) {
    return (
      <Box
        component="div"
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.default",
        }}
      >
        <Card sx={{ minWidth: 400, maxWidth: "90%" }}>
          <CardContent sx={{ p: 4 }}>
            <Typography variant="h5" component="h1" gutterBottom align="center">
              Check Your Email
            </Typography>
            <Typography variant="body2" align="center" sx={{ mb: 2 }}>
              If an account exists with this email, you will receive a password reset link shortly.
              The link will expire in 60 minutes for security reasons.
            </Typography>
            <Typography variant="body2" align="center" color="text.secondary" sx={{ mb: 3 }}>
              Please check your spam folder if you don't receive it within a few minutes.
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={() => push("/login")}
            >
              Return to Login
            </Button>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      component="div"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.default",
      }}
    >
      <Card sx={{ minWidth: 400, maxWidth: "90%" }}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h5" component="h1" gutterBottom align="center">
            Reset Password
          </Typography>

          <Box component="form" onSubmit={onSubmit}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter your email address and we'll send you a link to reset your
              password.
            </Typography>

            <TextField
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              required
              autoFocus
              disabled={isLoading}
            />

            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: 1, display: "block" }}
            >
              For security reasons, we won't disclose if an email address is registered with us.
              If you have an account, you'll receive the reset instructions shortly.
            </Typography>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              loading={isLoading}
            >
              Send Reset Link
            </LoadingButton>

            <Button
              fullWidth
              sx={{ mt: 1 }}
              onClick={() => push("/login")}
              disabled={isLoading}
            >
              Back to Login
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}; 