import React, { useState } from "react";
import {
  useShow,
  useList,
  useOne,
  useCreate,
  useDelete,
  useNavigation,
  useModal,
} from "@refinedev/core";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { Show } from "@refinedev/mui";
import {
  Typography,
  Stack,
  Chip,
  Card,
  CardContent,
  Grid,
  IconButton,
  Avatar,
  Box,
  Tooltip,
  Badge,
  Skeleton,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  Assignment as TaskIcon,
  Comment as NoteIcon,
  OpenInNew as OpenInNewIcon,
  Schedule as ScheduleIcon,
  FolderOff as NoMattersIcon,
  History as HistoryIcon,
  Business as OrgIcon,
  Mail as MailIcon,
  Block as BlockIcon,
  Check as CheckIcon,
  Folder as FolderIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Tables, TablesInsert } from "../../types";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { CreateActivityModal } from "../../components/create-activity-modal";

type User = Tables<"profiles">;
type Matter = Tables<"matters"> & {
  matter_team_members: Tables<"matter_team_members">[];
};
type Activity = Tables<"activities"> & {
  activity_participants: Tables<"activity_participants">[];
};

const EmptyState: React.FC<{
  icon: React.ReactNode;
  title: string;
  message: string;
}> = ({ icon, title, message }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      py: 4,
      px: 2,
    }}
  >
    {icon}
    <Typography variant="h6" sx={{ mt: 2 }}>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
      {message}
    </Typography>
  </Box>
);

// Add these helper components near the top of the file
const MatterStatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "open":
        return "success";
      case "closed":
        return "default";
      case "pending":
        return "warning";
      case "archived":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Chip
      label={status.replace(/_/g, " ")}
      size="small"
      color={getStatusColor(status)}
      sx={{
        fontWeight: 500,
        textTransform: "capitalize",
      }}
    />
  );
};

// Add this new component for better stats visualization

const UserHeader = React.memo(({ user }: { user: User }) => {
  const { mutate: deleteUser } = useDelete();
  const { push } = useNavigation();
  const { show: showConfirmDialog, ConfirmDialog } = useConfirmDialog();

  const handleDeleteUser = () => {
    showConfirmDialog({
      title: "Delete User",
      content: (
        <Stack spacing={2}>
          <Typography>
            Are you sure you want to delete {user.full_name}?
          </Typography>
          <Typography variant="body2" color="error">
            This action cannot be undone. All associated data will be
            permanently removed.
          </Typography>
        </Stack>
      ),
      onClick: async () => {
        try {
          deleteUser({
            resource: "profiles",
            id: user.id,
            successNotification: {
              message: "User successfully deleted",
              type: "success",
            },
          });
          push("/profiles");
        } catch (error) {
          // Error handling is managed by refine
        }
      },
    });
  };

  return (
    <>
      <Card elevation={2} sx={{ mb: 3, p: 3 }}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar
            sx={{
              width: 100,
              height: 100,
              bgcolor: user?.avatar_url ? "transparent" : "primary.main",
              fontSize: "2.5rem",
              border: (theme) => `4px solid ${theme.palette.background.paper}`,
              boxShadow: 2,
            }}
            src={user?.avatar_url || undefined}
          >
            {user?.initials}
          </Avatar>

          <Stack flex={1} spacing={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h4" fontWeight="medium">
                {user?.full_name}
              </Typography>
              <Badge
                color={user?.is_active ? "success" : "error"}
                variant="dot"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Chip
                  label={user?.role?.replace(/_/g, " ")}
                  color="primary"
                  size="small"
                  sx={{ textTransform: "capitalize" }}
                />
              </Badge>
              {user?.out_of_office && (
                <Chip
                  icon={<ScheduleIcon />}
                  label="Out of Office"
                  color="warning"
                  size="small"
                />
              )}
            </Stack>

            <Stack direction="row" spacing={4}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <EmailIcon color="action" fontSize="small" />
                <Typography variant="body2">{user?.email}</Typography>
              </Stack>
              {user?.phone && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <PhoneIcon color="action" fontSize="small" />
                  <Typography variant="body2">{user?.phone}</Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Button
              startIcon={<MailIcon />}
              variant="contained"
              href={`mailto:${user?.email}`}
              sx={{
                "&:hover": {
                  transform: "translateY(-2px)",
                  transition: "transform 0.2s",
                },
              }}
            >
              Contact
            </Button>
            <IconButton
              color={user?.is_active ? "error" : "success"}
              sx={{ border: 1, borderColor: "divider" }}
              onClick={() => {
                // Handle active/inactive toggle
              }}
            >
              {user?.is_active ? <BlockIcon /> : <CheckIcon />}
            </IconButton>
            <IconButton
              color="error"
              sx={{ border: 1, borderColor: "divider" }}
              onClick={handleDeleteUser}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
      <ConfirmDialog />
    </>
  );
});

// Update the AssignMatterModal component
const AssignMatterModal: React.FC<{
  open: boolean;
  onClose: () => void;
  userId: string;
  onSuccess?: () => void;
}> = ({ open, onClose, userId, onSuccess }) => {
  const { mutate, isLoading: isSubmitting } = useCreate();
  const { data: availableMatters, isLoading: isLoadingMatters } = useList<
    Tables<"matters">
  >({
    resource: "matters",
    filters: [
      {
        field: "status",
        operator: "in",
        value: ["open", "intake", "discovery", "negotiation", "trial_prep"],
      },
    ],
    pagination: { mode: "server", current: 1, pageSize: 50 },
    sorters: [{ field: "title", order: "asc" }],
  });

  const [selectedMatter, setSelectedMatter] = useState<string>("");
  const [role, setRole] = useState<string>("team_member");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!selectedMatter) {
      setError("Please select a matter");
      return;
    }

    try {
      mutate({
        resource: "matter_team_members",
        values: {
          matter_id: selectedMatter,
          profile_id: userId,
          role: role,
          assigned_at: new Date().toISOString(),
          notes: `Assigned as ${role.replace(/_/g, " ")}`,
          billable_rate: null, // Optional field
        } satisfies TablesInsert<"matter_team_members">,
        successNotification: {
          message: "Successfully assigned to matter",
          type: "success",
        },
        errorNotification: {
          message: "Error assigning to matter",
          type: "error",
        },
      });
      onSuccess?.();
      onClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : "An error occurred");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { p: 2 },
      }}
    >
      <DialogTitle>Assign to Matter</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <FormControl fullWidth error={!!error}>
            <InputLabel>Matter</InputLabel>
            <Select
              value={selectedMatter}
              onChange={(e) => {
                setSelectedMatter(e.target.value);
                setError(null);
              }}
              label="Matter"
              disabled={isLoadingMatters || isSubmitting}
            >
              {isLoadingMatters ? (
                <MenuItem disabled>Loading matters...</MenuItem>
              ) : !availableMatters?.data?.length ? (
                <MenuItem disabled>No available matters</MenuItem>
              ) : (
                availableMatters?.data?.map((matter) => (
                  <MenuItem key={matter.id} value={matter.id}>
                    <Stack>
                      <Typography variant="body1">{matter.title}</Typography>
                      <Typography variant="caption" color="text.secondary">
                        {matter.matter_number} •{" "}
                        {matter.status?.replace(/_/g, " ")}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))
              )}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              label="Role"
              disabled={isSubmitting}
            >
              <MenuItem value="lead">Lead</MenuItem>
              <MenuItem value="team_member">Team Member</MenuItem>
              <MenuItem value="supervisor">Supervisor</MenuItem>
            </Select>
            <FormHelperText>
              Select member&apos;s role for this matter
            </FormHelperText>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!selectedMatter || isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : undefined}
        >
          {isSubmitting ? "Assigning..." : "Assign to Matter"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Update the QuickActions component
const QuickActions = React.memo(({ userId }: { userId: string }) => {
  const {
    show: showActivityModal,
    close: closeActivityModal,
    visible: activityModalVisible,
  } = useModal();
  const {
    show: showAssignModal,
    close: closeAssignModal,
    visible: assignModalVisible,
  } = useModal();

  const { refetch: refetchActivities } = useList({
    resource: "activities",
    queryOptions: { enabled: false },
  });

  const handleSuccess = async () => {
    await refetchActivities();
  };

  return (
    <>
      <Card elevation={2} sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Quick Actions
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => showAssignModal()}
            >
              Assign Matter
            </Button>
            <Button
              startIcon={<TaskIcon />}
              variant="outlined"
              size="small"
              fullWidth
              onClick={() => showActivityModal()}
            >
              Add Task
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <CreateActivityModal
        open={activityModalVisible}
        onClose={closeActivityModal}
        matterId=""
        onSuccess={handleSuccess}
      />

      <AssignMatterModal
        open={assignModalVisible}
        onClose={closeAssignModal}
        userId={userId}
        onSuccess={handleSuccess}
      />
    </>
  );
});

const MattersList = React.memo(
  ({ matters, isLoading }: { matters?: Matter[]; isLoading: boolean }) => (
    <Card elevation={2}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Active Matters</Typography>
          <Typography variant="caption" color="text.secondary">
            {matters?.length || 0} matters assigned
          </Typography>
        </Stack>

        {isLoading ? (
          <Stack spacing={2}>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={80}
                sx={{ borderRadius: 1 }}
              />
            ))}
          </Stack>
        ) : !matters?.length ? (
          <EmptyState
            icon={
              <NoMattersIcon sx={{ fontSize: 40, color: "text.secondary" }} />
            }
            title="No Active Matters"
            message="This user is not currently assigned to any matters"
          />
        ) : (
          <Stack spacing={2}>
            {matters.map((matter) => {
              // Get the user's role directly from the matter's team members
              const userRole = matter.matter_team_members[0]?.role;

              return (
                <Paper
                  key={matter.id}
                  elevation={0}
                  sx={{
                    p: 2,
                    border: 1,
                    borderColor: "divider",
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      transform: "translateX(8px)",
                      borderColor: "primary.main",
                      bgcolor: "action.hover",
                    },
                  }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: "primary.light",
                        width: 48,
                        height: 48,
                      }}
                    >
                      <FolderIcon />
                    </Avatar>

                    <Stack flex={1} spacing={0.5}>
                      <Typography variant="subtitle1" fontWeight="medium">
                        {matter.title}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {userRole && (
                          <Chip
                            label={userRole.replace(/_/g, " ")}
                            size="small"
                            variant="outlined"
                            sx={{ textTransform: "capitalize" }}
                          />
                        )}
                        <MatterStatusBadge status={matter.status || ""} />
                      </Stack>
                    </Stack>

                    <IconButton
                      size="small"
                      component={Link}
                      to={`/matters/show/${matter.id}`}
                      sx={{
                        transition: "all 0.2s",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: "primary.main",
                        },
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </Stack>
                </Paper>
              );
            })}
          </Stack>
        )}
      </CardContent>
    </Card>
  )
);

const ActivityTimeline = React.memo(
  ({
    activities,
    isLoading,
  }: {
    activities?: Activity[];
    isLoading: boolean;
  }) => (
    <Card elevation={2}>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h6">Recent Activities</Typography>
          <Button startIcon={<AddIcon />} size="small" variant="outlined">
            Log Activity
          </Button>
        </Stack>

        {isLoading ? (
          <Stack spacing={2}>
            {[1, 2, 3].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                height={60}
                sx={{ borderRadius: 1 }}
              />
            ))}
          </Stack>
        ) : !activities?.length ? (
          <EmptyState
            icon={
              <HistoryIcon sx={{ fontSize: 40, color: "text.secondary" }} />
            }
            title="No Recent Activities"
            message="No activities have been recorded for this user yet"
          />
        ) : (
          <Stack spacing={2}>
            {activities.slice(0, 10).map((activity) => (
              <Paper
                key={activity.id}
                elevation={0}
                sx={{
                  position: "relative",
                  p: 2,
                  pl: 4,
                  border: 1,
                  borderColor: "divider",
                  borderLeft: 4,
                  borderLeftColor: `${getActivityColor(
                    activity.activity_type
                  )}.main`,
                  transition: "all 0.2s",
                  "&:hover": {
                    bgcolor: "action.hover",
                    transform: "translateX(8px)",
                  },
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    sx={{
                      bgcolor: `${getActivityColor(
                        activity.activity_type
                      )}.light`,
                      width: 40,
                      height: 40,
                    }}
                  >
                    {getActivityIcon(activity.activity_type)}
                  </Avatar>

                  <Stack flex={1} spacing={0.5}>
                    <Typography variant="subtitle2">
                      {activity.title}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Chip
                        label={activity.activity_type?.replace(/_/g, " ")}
                        size="small"
                        color={getActivityColor(activity.activity_type)}
                        sx={{ textTransform: "capitalize" }}
                      />
                      <Typography variant="caption" color="text.secondary">
                        {formatDistanceToNow(
                          new Date(activity.created_at || ""),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Typography>
                    </Stack>
                  </Stack>

                  {activity.due_date && (
                    <Tooltip title="Due Date">
                      <Chip
                        size="small"
                        icon={<ScheduleIcon />}
                        label={new Date(activity.due_date).toLocaleDateString()}
                        color={
                          new Date(activity.due_date) < new Date()
                            ? "error"
                            : "default"
                        }
                      />
                    </Tooltip>
                  )}
                </Stack>
              </Paper>
            ))}

            {activities.length > 10 && (
              <Button
                variant="text"
                endIcon={<OpenInNewIcon />}
                sx={{ alignSelf: "center" }}
              >
                View All Activities
              </Button>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  )
);

const OrganizationCard: React.FC<{ organizationId?: string }> = ({
  organizationId,
}) => {
  const { data: organizationData, isLoading: isLoadingOrg } = useOne<
    Tables<"organizations">
  >({
    resource: "organizations",
    id: organizationId || "",
    queryOptions: {
      enabled: !!organizationId,
    },
  });

  return (
    <Card elevation={2}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Organization Details
        </Typography>
        {isLoadingOrg ? (
          <Skeleton variant="rectangular" height={100} />
        ) : organizationData?.data ? (
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ bgcolor: "primary.light", width: 56, height: 56 }}>
                {organizationData.data.name?.[0]}
              </Avatar>
              <Stack>
                <Typography variant="subtitle1" fontWeight="medium">
                  {organizationData.data.name}
                </Typography>
                <Chip
                  label={organizationData.data.org_type?.replace(/_/g, " ")}
                  size="small"
                  sx={{ textTransform: "capitalize" }}
                />
              </Stack>
            </Stack>
            {organizationData.data.address && (
              <Typography variant="body2" color="text.secondary">
                {organizationData.data.address}
              </Typography>
            )}
          </Stack>
        ) : (
          <EmptyState
            icon={<OrgIcon />}
            title="No Organization"
            message="User is not associated with any organization"
          />
        )}
      </CardContent>
    </Card>
  );
};

// Add these helper functions if not already present
const getActivityColor = (
  type: string | null
): "success" | "error" | "warning" | "info" | "default" => {
  switch (type) {
    case "task":
      return "info";
    case "note":
      return "success";
    case "document":
      return "warning";
    case "deadline":
      return "error";
    default:
      return "default";
  }
};

const getActivityIcon = (type: string | null) => {
  switch (type) {
    case "task":
      return <TaskIcon />;
    case "note":
      return <NoteIcon />;
    case "deadline":
      return <ScheduleIcon />;
    default:
      return <HistoryIcon />;
  }
};

export const UserShow: React.FC = () => {
  const { query } = useShow<User>();
  const { data, isLoading } = query;
  const record = data?.data;

  // Single query for matters with team memberships
  const { data: mattersData, isLoading: isMattersLoading } = useList<Matter>({
    resource: "matters",
    meta: {
      select: "*,matter_team_members!inner(*)",
      filters: [
        {
          field: "matter_team_members.profile_id",
          operator: "eq",
          value: record?.id,
        },
      ],
    },
    queryOptions: {
      enabled: !!record?.id,
    },
  });

  // Optional: If you need aggregated stats about the matters

  // Update activity data fetching in the main component
  const { data: activitiesData, isLoading: isLoadingActivities } =
    useList<Activity>({
      resource: "activities",
      meta: {
        select: "*,activity_participants!inner(*)",
        filters: [
          {
            operator: "or",
            value: [
              {
                field: "assigned_to",
                operator: "eq",
                value: record?.id,
              },
              {
                field: "activity_participants.profile_id",
                operator: "eq",
                value: record?.id,
              },
            ],
          },
        ],
      },
      pagination: {
        mode: "server",
        current: 1,
        pageSize: 10,
      },
      sorters: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      queryOptions: {
        enabled: !!record?.id,
      },
    });

  return (
    <Show isLoading={isLoading}>
      <Box>
        {record && <UserHeader user={record} />}
        {record && <QuickActions userId={record.id} />}

        <Grid container spacing={3}>
          {/* Left column */}
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <OrganizationCard organizationId={record?.organization_id} />
              {/* Optional: Show matter stats */}
            </Stack>
          </Grid>

          {/* Right column */}
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <MattersList
                matters={mattersData?.data}
                isLoading={isMattersLoading}
              />
              <ActivityTimeline
                activities={activitiesData?.data}
                isLoading={isLoadingActivities}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Show>
  );
};
