import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Stack,
  Box,
  Avatar,
} from "@mui/material";
import { EditButton } from "@refinedev/mui";
import { MatterStatus, Tables } from "../../../types/schema";
import { getStatusColor } from "../../../utils/statusColors";
import { format } from "date-fns";

interface MatterHeaderProps {
  matter: Tables<"matters">;
  clientOrg?: Tables<"organizations">;
  leadAttorney?: Tables<"profiles">;
}

export const MatterHeader: React.FC<MatterHeaderProps> = ({
  matter,
  clientOrg,
  leadAttorney,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        mb: 3,
        bgcolor: "background.paper",
        background:
          "linear-gradient(45deg, rgba(26, 54, 93, 0.02), rgba(26, 54, 93, 0.08))",
        borderBottom: "2px solid",
        borderColor: "primary.main",
        position: "relative",
        overflow: "visible",
      }}
    >
      <CardContent sx={{ py: 4 }}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <Stack spacing={2}>
              <Box>
                <Typography
                  variant="h4"
                  fontWeight="600"
                  color="primary.dark"
                  gutterBottom
                >
                  {matter.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ maxWidth: "md" }}
                >
                  {matter.description}
                </Typography>
              </Box>

              <Stack direction="row" spacing={4} alignItems="center">
                {clientOrg && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: "primary.light",
                        width: 32,
                        height: 32,
                        fontSize: "0.875rem",
                      }}
                    >
                      {clientOrg.name.charAt(0)}
                    </Avatar>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Relevant Organization
                      </Typography>
                      <Typography variant="body2" fontWeight="500">
                        {clientOrg.name}
                      </Typography>
                    </Box>
                  </Stack>
                )}

                {leadAttorney && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      src={leadAttorney.avatar_url || undefined}
                      sx={{
                        width: 32,
                        height: 32,
                        fontSize: "0.875rem",
                      }}
                    >
                      {leadAttorney.initials}
                    </Avatar>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Lead Attorney
                      </Typography>
                      <Typography variant="body2" fontWeight="500">
                        {leadAttorney.full_name}
                      </Typography>
                    </Box>
                  </Stack>
                )}

                {matter.open_date && (
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Opened
                    </Typography>
                    <Typography variant="body2" fontWeight="500">
                      {format(new Date(matter.open_date), "PP")}
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{
                flexWrap: { xs: "wrap", sm: "nowrap" },
                gap: { xs: 1, sm: 2 },
              }}
            >
              <Chip
                label={matter.status?.replace("_", " ") || ""}
                sx={{
                  color: getStatusColor(matter.status as MatterStatus),
                  minWidth: 100,
                  height: 32,
                  fontWeight: 500,
                }}
              />
              <EditButton
                resource="matters"
                recordItemId={matter?.id}
                accessControl={{ enabled: true }}
                sx={{
                  bgcolor: "background.paper",
                  "&:hover": {
                    bgcolor: "grey.100",
                  },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
